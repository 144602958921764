import React from 'react';
import {Popover} from 'antd';
import {Button} from 'reactstrap';

interface IProps {
  colorBottom?: 'link' | 'primary' | string;
  label: React.ReactNode;
  trigger: 'click' | 'focus' | 'hover';
  children: JSX.Element | JSX.Element[];
  title?: React.ReactNode;
  placement: 'bottom' | 'left' | 'topLeft' | 'top';
  buttonClassName?: string;
  id: string;
  zIndex?: number;
  position?: 'absolute' | 'fixed';
  buttonDisabled?: boolean;
  onVisibleChange?: (visible: boolean) => void;
}

export function responsiveContent(className: string) {
  let elem = document.getElementById('to-get-responsive');
  if (elem) {
    elem.setAttribute('class', className);
  }
  return elem?.clientWidth ?? 300;
}

const PopoverComponent = ({
                            label,
                            position,
                            children,
                            title,
                            trigger,
                            placement,
                            id,
                            buttonClassName,
                            colorBottom,
                            zIndex,
                            buttonDisabled,
                            onVisibleChange
                          }: IProps) => {

  return (
    <>
      <Popover style={position ? {position} : {}}
               zIndex={zIndex}
               onVisibleChange={onVisibleChange}
               placement={placement}
               title={title} content={children}
               trigger={trigger}>
        <Button type="button" style={position ? {position, left: '-80px'} : {}} disabled={buttonDisabled}
                color={colorBottom ?? 'link'}
                className={`${buttonClassName ?? 'ms-2 mb-2'}`} size="sm" id={id}>
          {label}
        </Button>
      </Popover>

    </>
  );
};


export default PopoverComponent;
