import * as React from "react";
import {LabelProps} from "./Models/LabelProps";


const LabelComponent: React.FC<LabelProps> = ({
                                                inputId,
                                                icon,
                                                children,
                                                information,
                                                className
                                              }) => {
  return <label className={className || ""} htmlFor={inputId}>
    {children}<span>{icon}</span>
  </label>
};

export default LabelComponent;
