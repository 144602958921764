import * as React from 'react';
import {DropDownSimple} from "../../LayoutComponenents/DropDownComponent";
import {useRouter} from "next/router";
import useTranslate from "../../../hooks/useTranslate";

const languages = [{lang: "en", flag: "US"}, {lang: "es", flag: "ES"}, {lang: "nl", flag: "NL"}]

const LanguageMenuComponent = () => {
  const router = useRouter();

  const {t} = useTranslate("common");

  function setLanguage(language: string) {
    window.location.href = `${location.origin}/${language + location.pathname.replace(`/${router.locale}`, "")}`;
  }

  return (
    <div className="header-dots">
      <DropDownSimple headerToggle={(
        <span className="icon-wrapper icon-wrapper-alt rounded-circle">
                  <span className="icon-wrapper-bg bg-focus"/>
                  <span
                    className={"language-icon opacity-8 flag large " + languages.find(lng => lng.lang === router.locale)?.flag.toUpperCase()}/>
                </span>
      )}>
        <>
          <div className="dropdown-menu-header">
            <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
              <div className="menu-header-image opacity-05"/>
              <div className="menu-header-content text-center text-white">
                <h6 className="menu-header-subtitle text-white mt-0"> Choose Language</h6>
              </div>
            </div>
          </div>
          <h6 tabIndex={-1} className="dropdown-header "> Popular Languages</h6>
          {languages.map(({flag, lang}) => {
            return (
              <button
                key={flag}
                onClick={() => setLanguage(lang)}
                type="button" tabIndex={0}
                className={"dropdown-item " + (router.locale === lang ? "active" : "")}>
                <span className={"me-3 opacity-8 flag large " + flag}/>
                {t(lang).toUpperCase() ?? lang}
              </button>
            )
          })}
        </>
      </DropDownSimple>
    </div>
  );
};

export default LanguageMenuComponent