import React, {FormEvent, useEffect, useState} from 'react';
import {TaskProcess} from '../../../../types/Task';
import {fields} from './config';
import CreateFieldPreview from '../CreateFieldModal/CreateFieldPreview';
import ModalComponent from '../ModalComponent/ModalComponent';
import {useDispatch, useSelector} from 'react-redux';
import {actionsModal, ModalTypes} from '../../../../store/Modal/Slice';
import useTranslate from '../../../../hooks/useTranslate';
import {Col, Row} from 'reactstrap';
import {ProcessModel} from '../../../../types/models/processModel/Process';
import {
  createOrUpdateProcessModelService,
  getModelProcessDataFields
} from '../../../../services/backend/ProcessService';
import {RootState} from '../../../../store/Reducers';
import {filterFields, splitDataArrayFromString, toast} from '../../../../utils';
import {FieldType} from '../../../../types/FieldType';
import IField from '../../../../types/FormBuilder/IField';
import {Attachment} from '../../../../types/FormBuilder/Attachment';
import AttachmentType from '../../../../types/Workflow/AttachmentType';
import {StringOption} from "../../../../types/Select";
import SuggestionRuleSearchComponent from "../SuggestionRuleSearchComponent";

export interface IProps {
  open: boolean;
  data: IPropsUpsertTaskModal;

}

export interface IPropsUpsertTaskModal {
  type: ModalTypes.UPSERT_TASK_MODAL;
  task: TaskProcess;
  currentProcessModel: ProcessModel;
  onFinish: (task: ProcessModel) => void;
}

const options: any = {
  task_type: [{label: 'USER', value: 'USER'}, {value: 'SCRIPT', label: 'SCRIPT'}, {value: 'SERVICE', label: 'SERVICE'}],
  // activity_type: [{label: 'TASK', value: 'TASK'}, {label: 'TASK_SCRIPT', value: 'TASK_SCRIPT'}],
  assignment_type: [
    {label: 'USER', value: 'USER'},
    {label: 'CURRENT_USER', value: 'CURRENT_USER'},
    {value: 'GROUP', label: 'GROUP'},
    {value: 'COMPLEX', label: 'COMPLEX'}
  ],
  finish_assignments: [{
    label: "First User finish the assignment",
    value: true
  }, {label: "All Users finish the assignment", value: false}]
}

const UpsertTaskModal = (props: IProps) => {

  const {
    FormReducer: {
      forms
    },
    PermissionReducer: {
      users,
      groups
    }
  } = useSelector((state: RootState) => state);
  const [task, setTask] = useState<any>({});
  const [processDataFields, setProcessDataFields] = useState<StringOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [focusField, setFocusField] = useState("");

  const dispatch = useDispatch();
  const {t} = useTranslate("common");
  const newOptions = {
    ...options,
    attachments: forms.map(form => ({label: `${form.id} ${form.name}`, value: form})),
    users: users.map(user => ({label: `${user.email}`, value: user})),
    groups: groups.map(group => ({label: `${group.name}`, value: group})),
  }

  const setDataFields = (tt: any) => {
    filterFields(fields, tt).forEach((field) => {
      const val = tt[field.name] ?? field.default_value;
      let default_value = val;
      if (field.field_type === FieldType.OPTIONS) {
        default_value = newOptions[field.name]?.find((opt: any) => {
          switch (field.name) {
            case "attachments":
              return opt.value.id === (val && val.length ? val[0].attachment_id : null);
            default:
              return opt.value === val;
          }
        }) ?? null;
      }


      setTask((e: any) => ({...e, [field.name]: default_value}));

    });


    let groups: any = null, users: any = null;
    if (tt.rule) {
      if (tt.rule.includes("users")) {
        users = newOptions.users.filter((u: any) => splitDataArrayFromString(tt.rule).some(i => i === u.value.id));
      }
      if (tt.rule.includes("groups")) {
        groups = newOptions.groups.filter((g: any) => splitDataArrayFromString(tt.rule).some(i => i === g.value.id));
      }
    }

    setTask((e: any) => ({
      ...e,
      users,
      groups,
      assignment_type: newOptions.assignment_type.find((opt: any) => opt.value === tt.assignment_type),
      rule: tt.rule
    }))
  };

  useEffect(() => {
    setDataFields(props.data.task);
  }, [props.data.task]);

  function getNewTks(tsk: any) {
    let newTsk: any = {...tsk, ...task};
    filterFields(fields, newTsk).forEach(field => {
      const value = task[field.name];
      const tt = typeof value;
      if (field.name === "attachments") {
        if (value?.value) {
          let attach: Attachment = {
            attachment_id: value.value.id,
            attachment_type: AttachmentType.FORM,
            domain_id: process.env.NEXT_PUBLIC_SBX_DOMAIN || '',
            target_type: task.activity_type?.value === "TASK_SCRIPT" ? "TASK" : task.activity_type?.value ?? "TASK",
          };

          if (tsk?.attachments) {
            const attachment = tsk?.attachments[0]

            if (attachment?.id) {
              attach = {...attach, id: attachment.id, target_type: attachment.target_type}
            }
          }


          newTsk[field.name] = [attach];
        }
      } else {
        newTsk[field.name] = (tt === "object" ? value.value : value) ?? "";

      }
    });

    // TODO: find a better way to do this @frank
    // This should fix any case when we are sending the label,value options as a value.
    Object.keys(newTsk).forEach(k => {
      if (typeof newTsk[k] === 'object' && newTsk[k]?.hasOwnProperty("label") && newTsk[k]?.hasOwnProperty("value")) {
        newTsk[k] = newTsk[k].value;
      }
    });

    delete newTsk.users;
    delete newTsk.groups;
    delete newTsk.type;
    return newTsk as TaskProcess;
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let newProcessModel = {...props.data.currentProcessModel};

    setLoading(true);
    if (props.data.task.id) {
      newProcessModel.tasks = newProcessModel.tasks.map(tsk => {
        if (tsk.id === props.data.task.id) {

          return getNewTks(tsk);
        }
        return tsk;
      });
    } else {
      let tsks = new Array(...newProcessModel.tasks ?? []);
      tsks.push(getNewTks({}));
      newProcessModel.tasks = tsks;
    }


    const res = await createOrUpdateProcessModelService(newProcessModel);

    if (res?.success) {
      props.data.onFinish(res.item);
      toggle();
    } else {
      toast({type: "error", message: "An error ocurred to save the process. Try again"})
      setLoading(false)
    }
  }

  const toggle = () => {
    dispatch(actionsModal.closeModal({type: ModalTypes.UPSERT_TASK_MODAL}));
  }

  function onChangeValues(field: IField, value: any) {

    let nTask = {
      ...task,
      [field.name]: value
    }
    const fieldFiltered = filterFields(fields, nTask);

    function findField(fieldName: string) {
      return fieldFiltered.find(f => f.name === fieldName);
    }

    const ds_rule = fieldFiltered.find(f => f.name === "rule");
    const ds_act = fieldFiltered.find(f => f.name === "activity_type");

    switch (field.name) {
      case "task_type":
        nTask = {
          ...nTask,
          activity_type: ds_act?.default_value ?? "",
          assignment_type: nTask.task_type?.value === "USER" ? nTask.assignment_type : "COMPLEX",
          rule: nTask.task_type?.value === "USER" ? nTask.rule : "",
        }
        break

      case "assignment_type":
        const assRule = nTask.assignment_type?.value === "CURRENT_USER" ? "" : (ds_rule?.default_value ?? "");
        nTask = {
          ...nTask,
          rule: assRule
        }
        break;
      case "users":
      case "groups":
        const nVal = value?.map((v: any) => v.value.id) ?? [];

        nTask = {
          ...nTask,
          rule: ds_rule?.default_value?.replace("[]", JSON.stringify(nVal)) ?? ""
        }
        break;
    }



    if (nTask.rule === ds_rule?.default_value) {
      if (findField("groups")) {
        const nVal = task.groups?.map((v: any) => v.value.id) ?? [];
        nTask = {
          ...nTask,
          rule: ds_rule?.default_value?.replace("[]", JSON.stringify(nVal)) ?? ""
        }
      } else if (findField("users")) {
        const nVal = task.users?.map((v: any) => v.value.id) ?? [];
        nTask = {
          ...nTask,
          rule: ds_rule?.default_value?.replace("[]", JSON.stringify(nVal)) ?? ""
        }
      }
    }

    
    setTask(nTask)
  }


  React.useEffect(() => {

    const getDataFields = async () => {
      if (props.data?.task?.process_id) {
        const response = await getModelProcessDataFields(props.data.task.process_id)
        if (response?.success) {
          const items = response.items?.map((item: { label: string, name: string }) => ({
            label: item.label,
            value: item.name
          }))
          setProcessDataFields(items)
        } else {
        }
      }
    }

    getDataFields()
  }, [props.data]);



  const getSuggestionList = (field: IField, id: number) => {
    if (field.name === "rule" && focusField === "rule") {
      return <SuggestionRuleSearchComponent setFocusField={setFocusField} setItem={setTask} id={id} field={field} processDataFields={processDataFields} item={task}/>
    }

    return null
  }

  return (
    <ModalComponent
      isLoading={loading}
      size="lg"
      title={props.data.task.id ? t("update") : t("new")}
      isOpen={props.open}
      type="submit"
      form="formUpsertModal"
      toggle={toggle}>
      <form id="formUpsertModal" onSubmit={onSubmit}>
        <Row>
          {filterFields(fields, task).map((field, id) => {
            return (
              <Col key={field.name} md={6} sm={12}>
                <CreateFieldPreview
                  options={newOptions[field.name]}
                  onChange={e => onChangeValues(field, e)}
                  value={task[field.name]}
                  onFocus={() => {
                    setFocusField(field.name)
                  }}
                  onBlur={() => {
                  }}
                  field={{...field, id}}/>

                {field.name === "rule" && getSuggestionList(field, id)}
              </Col>
            )
          })}
        </Row>
      </form>
    </ModalComponent>
  )
}

export default UpsertTaskModal;
