import React from 'react';
import MultiSelectComponent from '../FieldComponents/MultiSelectComponent';
import {State} from '../../../types/State';
import useTranslate from '../../../hooks/useTranslate';

type Props = {
  updateFetchModel: ({models, isDeepFetch}: {
    models: { label: string, value: any }[], isDeepFetch?: boolean, isReverseFetch?: boolean
  }) => void
  defaultFetch: string[]
  fetchModels: string[]
  reverseFetchModels: string[]
  isLoading: State | string
  deepModelsFetch: string[]
  model: string;

}

const QueryFetchComponent = ({
                               defaultFetch,
                               fetchModels,
                               updateFetchModel,
                               isLoading,
                               deepModelsFetch,
                               model,
                               reverseFetchModels
                             }: Props) => {

  const {t} = useTranslate('common');

  const getFetchValue = () => {
    return defaultFetch?.filter(item => !item.includes('.')).map(item => ({label: item, value: item})) ?? [];
  };

  const getDeepFetchValue = () => {
    return defaultFetch?.filter(item => item.includes('.')).map(item => ({label: item, value: item})) ?? [];
  };

  const getReverseFetchValue = () => {
    return defaultFetch?.filter(item => item.includes('.') && item.split('.')[1] === model).map(item => ({
      label: item,
      value: item
    })) ?? [];
  };

  return (
    <div className="row">
      <div className="d-flex flex-column col-xs-12 col-lg-6">
        <label>{t('fetch')}: </label>
        <MultiSelectComponent id={'fetch_models'}
                              value={getFetchValue()}
                              onChange={(models) => updateFetchModel({models})}
                              disabled={fetchModels.length === 0}
                              name={'fetch_models'}
                              options={fetchModels.map(model => ({
                                label: model,
                                value: model
                              })) ?? []}/>
      </div>

      {model && <div className="d-flex flex-column col-xs-12 col-lg-6">
        <label>{t('deep_fetch')}: </label>
        <MultiSelectComponent id={'deep_fetch_models'}
                              onChange={models => updateFetchModel({models, isDeepFetch: true})}
                              value={getDeepFetchValue()}
                              loading={isLoading === 'FETCH_REFERENCES_PENDING'}
                              disabled={fetchModels.length === 0 || isLoading === 'FETCH_REFERENCES_PENDING'}
                              name={'deep_fetch_models'}
                              options={deepModelsFetch.map(fetch => ({label: fetch, value: fetch}))}/>
      </div>}

      <div className="d-flex flex-column col-xs-12 col-lg-6">
        <label>{t('reverse_fetch')}: </label>
        <MultiSelectComponent id={'deep_fetch_models'}
                              onChange={models => updateFetchModel({isReverseFetch: true, models})}
                              value={getReverseFetchValue()}
                              loading={isLoading === State.PENDING}
                              disabled={reverseFetchModels.length === 0 || isLoading === State.PENDING || !model}
                              name={'reverse_fetch_models'}
                              options={reverseFetchModels.map(fetch => ({label: fetch, value: fetch}))}/>
      </div>
    </div>

  );
};

export default QueryFetchComponent;
