import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {State} from '../../../types/State';
import {useDispatch, useSelector} from 'react-redux';
import {authReducer} from '../../../store/Selectors';
import {AuthStates} from '../../../store/Auth/Types';
import useRouterLoadingEvents from '../../../hooks/useRouterLoadingEvents';
import {actionHistory} from '../../../store/SearchReducer';
import NavBarComponent from './NavBarComponent';
import SideBarContent from './SideBarContent';
import useResponsive from '../../../hooks/useReponsive';
import SpinnerComponent from "../../Shared/SpinnerComponent";


// const {Content} = Layout;


const AdminLayoutComponent = ({children}: { children: JSX.Element | JSX.Element[] }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loadingPage] = useRouterLoadingEvents();
  const {state} = useSelector(authReducer);
  const {isTabletOrMobile} = useResponsive();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (loadingPage === State.PENDING) {
      dispatch(actionHistory.changeState(true));
    }
  }, [loadingPage]);
  
  const router = useRouter();
  
  
  return (
    <>
      {(state !== AuthStates.AUTHENTICATED && router.route === '/') || state !== AuthStates.AUTHENTICATED ? (
        <div style={{top: 0, left: 0, zIndex: 100}}
             className="position-fixed bg-white vh-100 w-100 d-flex justify-content-center align-items-center">
          <SpinnerComponent type={"ball-scale-ripple"}/>
        </div>
      ) : (
        // app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar-mobile closed-sidebar
        <div
          className={`app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar
            ${!sidebarOpen ? `closed-sidebar ${isTabletOrMobile ? 'closed-sidebar-mobile' : ''}`
            : `${isTabletOrMobile ? 'sidebar-mobile-open' : ''}`}`}>
          <NavBarComponent setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}/>
          <SideBarContent sidebarOpen={sidebarOpen}>
            {children}
          </SideBarContent>
        </div>
      )}
    </>
  );
};

export default AdminLayoutComponent;
