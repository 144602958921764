import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {State} from "../../types/State";
import {ConfigData} from "../../types/ConfigData";

export interface BusinessDay {month_index: string, year: number, days: string}

const initialState: { state: State, config?: ConfigData, businessDays:BusinessDay[] } = {
  state: State.IDLE,
  businessDays: []
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    saveConfig(state, action: PayloadAction<ConfigData>) {
      state.state = State.PENDING;
    },
    setConfig(state, action: PayloadAction<ConfigData>) {
      state.config = action.payload;
      state.state = State.RESOLVED;
    },
    loadConfig(state) {
      state.state = State.PENDING;
    },
    getBusinessDays(state){
      state.state = State.PENDING;
    },
    setBusinessDays(state, {payload}: PayloadAction<BusinessDay[]>){
      state.state = State.RESOLVED;
      state.businessDays = payload
    },
    changeState(state, action: PayloadAction<State>) {
      state.state = action.payload;
    }
  }
});

export const actionsConfig = slice.actions;
export default slice.reducer;
