import * as React from "react";

interface props {
  type?: "ball-grid-pulse" | "ball-scale-ripple" | "ball-triangle-path"
}

const SpinnerComponent = ({type = "ball-triangle-path"}: props) => {

  switch (type) {
    case "ball-grid-pulse":
      return (
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <div className="loader">
            <div className="ball-grid-pulse">
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
            </div>
          </div>
        </div>
      )
    case "ball-scale-ripple":
      return (
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <div className="loader">
            <div className="ball-scale-ripple">
              <div/>
            </div>
          </div>
        </div>
      )

    case "ball-triangle-path":
      return (
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <div className="loader">
            <div className="ball-triangle-path">
              <div/>
              <div/>
              <div/>
            </div>
          </div>
        </div>
      )
  }
};

export default SpinnerComponent;
