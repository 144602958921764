import RuleGenerator, {IPropsRule} from "../../RuleGenerator/RuleGenerator";
import {actionsModal, ModalTypes} from "../../../store/Modal/Slice";
import ModalComponent from "./ModalComponent/ModalComponent";
import {useDispatch} from "react-redux";
import React from "react";
import useTranslate from "../../../hooks/useTranslate";
import {capitalize} from "../../../utils";

interface IProps {
  data: IPropsRuleModal;
  open: boolean;
}

export interface IPropsRuleModal extends IPropsRule {
  type: ModalTypes.RULE_CREATOR;
}

const RuleCreatorModal = ({open, data}: IProps) => {
  
  const {t} = useTranslate("common");
  
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(actionsModal.closeModal({type: ModalTypes.RULE_CREATOR}))
  }
  
  return (
    <ModalComponent
      noFooter
      title={capitalize(t("visible_when"))}
      toggle={toggle}
      isOpen={open}
      size="xl">
      <RuleGenerator {...data}/>
    </ModalComponent>
  )
}

export default RuleCreatorModal;
