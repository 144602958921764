import React from 'react';
import FileComponent from "../../TaskComponent/FormRenderInput/FileComponent";
import {Field} from "../../../types/Field";

export interface PropsValueFile {
  label: string;
  file: File
}

interface IProps {
  field: Field;
  getKey: () => string;
  onChange: (val: PropsValueFile) => void;
}

const SingleFileComponent: React.FC<IProps> = (props: IProps) => {

  return (
    <FileComponent
      getValue={props.getKey}
      setValueField={props.onChange}
      field={props.field}/>
  )
};

export default SingleFileComponent;
