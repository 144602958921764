import ModalComponent from "../ModalComponent/ModalComponent";
import {actionsModal, ModalTypes} from "../../../../store/Modal/Slice";
import {useDispatch} from "react-redux";
import FormPreview from "../../../FormBuilder/FormPreview";
import {useEffect} from "react";
import {actionsForm} from "../../../../store/Form/Slice";

interface IProps {
  open: boolean;
  data: IPropsFormPreviewModal
}

export interface IPropsFormPreviewModal {
  type: ModalTypes.FORM_PREVIEW_MODAL;
  formId: number;
}

const FormPreviewModal = (props: IProps) => {

  const dispatch = useDispatch();
  const toggle = () => dispatch(actionsModal.closeModal({type: ModalTypes.FORM_PREVIEW_MODAL}));

  useEffect(() => {
    dispatch(actionsForm.getFormById(props.data.formId));
  }, [props.data.formId])
  return (
    <ModalComponent
      title="Preview"
      isOpen={props.open}
      toggle={toggle}
      noFooter>
      <FormPreview/>
    </ModalComponent>
  )
}


export default FormPreviewModal;
