import ModalComponent from "./ModalComponent/ModalComponent";
import React, {FormEvent, useReducer} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {actionsModal, ModalTypes} from "../../../store/Modal/Slice";
import {useDispatch, useSelector} from "react-redux";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import {Col, FormGroup, Label, Row} from "reactstrap";
import {ModuleType, permArray, PermissionType} from "../../../types/Permissions";
import {capitalize} from "../../../utils";
import {actionsPermissions} from "../../../store/Permission/Slice";
import {RootState} from "../../../store/Reducers";
import {PermissionStates} from "../../../store/Permission/Types";
import useTranslate from "../../../hooks/useTranslate";
import {Switch} from "antd";
import ValidateFieldRequired from "../ValidateFieldRequired";

interface IProps {
  open: boolean;
  data: IPropsPermissionModal;
}

export interface IPropsPermissionModal {
  type: ModalTypes.UPSERT_PERMISSION;
  module?: ModuleType;
}

interface InitStateType {
  moduleName: string;
  module: ModuleType;
}

const initialState: InitStateType = {
  moduleName: "",
  module: {module_name: "", permissions: []}
}

type types =
  { type: "CHANGE_STATE", payload: InitStateType } |
  { type: "CHANGE_MODULE_NAME", payload: string } |
  { type: "CHANGE_PERMISSION", payload: PermissionType }

const reducer = (state: InitStateType, action: types) => {
  let module;
  switch (action.type) {
    case "CHANGE_STATE":
      return {...state, ...action.payload}

    case "CHANGE_MODULE_NAME":
      module = {
        module_name: action.payload,
        permissions: state.module.permissions.map(e => ({...e, module_name: action.payload}))
      }
      return {...state, module, moduleName: action.payload}

    case "CHANGE_PERMISSION":
      module = Object.assign({}, state.module);
      const permissions = new Array(...module.permissions);
      const exist = module.permissions.find(p => p.permission === action.payload);
      if (exist) {
        module.permissions = permissions.filter(p => p.permission !== action.payload);
      } else {
        permissions.push({module_name: state.moduleName, permission: action.payload})
        module.permissions = permissions;
      }
      return {...state, module}
    default:
      return state;
  }
}

const AddOrEditPermissionModal = ({data: {module}, open}: IProps) => {
  const {t} = useTranslate("common");
  const [localState, dispatchEvent] = useReducer(reducer, initialState);

  const {state} = useSelector((state: RootState) => state.PermissionReducer);
  const dispatch = useDispatch();
  const toggle = () => dispatch(actionsModal.closeModal({type: ModalTypes.UPSERT_PERMISSION}));


  //use onInit hook to initials functions
  useAsyncEffect(() => {
    //On this part we need split to each module name with the permissions
    if (module) {
      dispatchEvent({type: "CHANGE_STATE", payload: {module, moduleName: module.module_name}})
    }
  }, [module])


  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (module) {
      const toDelete: any[] = module.permissions
        .filter(p => !localState.module.permissions
          .find(p2 => p.permission === p2.permission)).map(p => p.id);

      const toSave = localState.module.permissions
        .filter(p => !module.permissions
          .find(p2 => p2.permission === p.permission));

      //------------------------
      dispatch(actionsPermissions.editModulePermission({toSave, toDelete}));
    } else {
      dispatch(actionsPermissions.addModulePermissions(localState.module));
    }
  }

  return (
    <ModalComponent
      isLoading={state === PermissionStates.PERMISSION_PENDING}
      size="md"
      type="submit"
      form="permissionForm"
      isOpen={open}
      toggle={toggle}
      title={(
        <>
          <FontAwesomeIcon icon={module ? faEdit : faPlusCircle}/>{" " + t(module ? "edit" : "add")}
        </>
      )}>
      <form id="permissionForm" onSubmit={onSubmit}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label htmlFor="moduleNameId">{t("permissions-manager:module-name")}</Label>
              <input
                disabled={!!module}
                value={localState.moduleName}
                onChange={e => dispatchEvent({
                  type: "CHANGE_MODULE_NAME",
                  payload: e.target.value.trim().toUpperCase()
                })}
                style={{textTransform: "uppercase"}}
                type="text" className="form-control"
                required/>
            </FormGroup>
          </Col>
          <Col md={12}>
            {permArray.map((perm, index) => {
              const isChecked = !!localState.module.permissions.find(p => p.permission === perm);
              const isRequired = !localState.module.permissions.length;
              return (
                <div key={index} className="d-flex justify-content-between bg-white  shadow-sm p-3">
                  <label htmlFor="">{capitalize(perm)}</label><br/>
                  <ValidateFieldRequired required={isRequired} value={isChecked ? "value" : ""}>
                    <Switch
                      onChange={() => dispatchEvent({type: "CHANGE_PERMISSION", payload: perm})}
                      defaultChecked={isChecked}
                    />
                  </ValidateFieldRequired>
                </div>
              )
            })}
          </Col>
        </Row>
      </form>
    </ModalComponent>
  )
}

export default AddOrEditPermissionModal;
