import {Account} from "../../types/Account";

export const accountTypeSlice = {
  GET_ACCOUNTS: "account/getAccounts",
  FIND_BY_LINK: "account/findByLike",
  FIND_BY_KEY: "account/getAccountByKey",
}

export enum AccountStates {
  ACCOUNT_PENDING = "ACCOUNT_PENDING",
  ACCOUNT_RESOLVED = "ACCOUNT_RESOLVED",
  ACCOUNT_REJECTED = "ACCOUNT_REJECTED",
  IDLE = "IDLE"
}


export interface AccountState {
  state:AccountStates;
  accounts: Account[];
  total_accounts: number;
  currentAccount?:Account;
}
