import React from 'react';
import {MenuItem} from './types/SideBar';
import Link from 'next/link';
import routes from '../../routes/routes';
import {useRouter} from 'next/router';
import SubMenuIitemsComponent from "./SubMenuItemsComponent";

type Props = {
  menu: MenuItem
  menuActive: string;
  setMenuActive: (menuName: string) => void;
  subMenu: string
  setSubMenu: (menuName: string) => void;

}

const MenuItemsComponent = ({menu, menuActive, setMenuActive, setSubMenu, subMenu}: Props) => {
  const history = useRouter();
  React.useEffect(() => {
    const current = routes.find(r => {
      if (r.views) {
        r.views.find(v => {
          const path = `${v.root}${v.path !== '/' ? v.path : ''}`;
          return history.pathname.split('?').shift() === path;
        })
      } else {
        return history.pathname.split('?').shift() === r.path;
      }
    });
    setMenuActive(current?.name ?? routes[0].name);
  }, []);

  const isMenu = !!menu?.subItems.length;


  return (
    <div>
      <li key={menu.label}
          className={(menuActive === menu.name) ? 'mm-show mm-active' : ''}>
        {!isMenu && (
          <Link href={menu.path ?? ""}>
            <a className={menu.active ? 'mm-active' : ''}>
              <i className={"metismenu-icon " + menu.icon}/>
              {menu.label}
            </a>
          </Link>
        )}
        {isMenu && (
          <a onClick={() => setMenuActive((menu.name !== menuActive) ? menu.name : '')}>
            <i className={'metismenu-icon ' + menu.icon}/>
            {menu.label}
            <i className="metismenu-state-icon pe-7s-angle-down caret-left"/>
          </a>
        )}
        {isMenu && (
          <ul className={`collapse-list ${(menuActive === menu.name) ? 'mm-show menu-item-active' : ''}`}
              style={{maxHeight: (menuActive === menu.name) ? "500px" : 0}}>
            {menu.subItems.map((subItem) => {
              if (subItem.subItems?.length) {
                return (
                  <SubMenuIitemsComponent key={subItem.name} menu={subItem} menuActive={subMenu}
                                          setMenuActive={setSubMenu}/>
                )
              }

              return (
                <li key={subItem.label} onClick={() => setMenuActive(menu.name)}>
                  <Link href={subItem.path}>
                    <a className={subItem.active ? 'mm-active' : ''}>
                      <i className="metismenu-icon"/>
                      {subItem.label}
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </li>
    </div>
  );
};

export default MenuItemsComponent;
