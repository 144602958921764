import React from 'react';
import TextProps from "./Models/TextProps";
import {AnyData} from "../../../types/AnyData";


interface Traditional extends TextProps {
  value: string,
  onChange?: (value: string) => void
}

interface Ref extends TextProps, AnyData {
  register: any;
}

type IProps = Traditional | Ref;

const EmailComponent = (props: IProps) => {

  if (!(props as any).register) {
    const {
      id,
      name,
      value,
      required,
      onChange,
      className,
      style,
      invalid,
      placeholder,
      disabled,
      defaultValue
    } = props;


    return <input id={id}
                  required={required}
                  name={name}
                  disabled={disabled}
                  value={value}
                  type="email"
                  style={style}
                  defaultValue={defaultValue}
                  className={`form-control ${className || ''} ${invalid ? 'invalid' : ''}`}
                  placeholder={placeholder}
                  onChange={e => onChange ? onChange(e.target.value) : null}/>;
  } else {
    return <input {...props}
                  type="email"
                  defaultValue={props.defaultValue}
                  className={`form-control ${props.className || ''} ${props.invalid ? 'invalid' : ''}`}
                  {...(props as any).register}
    />
  }
}
export default EmailComponent;
