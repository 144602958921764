import {FieldType, SubType} from './FieldType';
import {ListProvider} from './Task';

export class Field {
  id!: number;
  label!: string;
  name!: string;
  hint!: null | string;
  required!: boolean;
  single_value!: boolean;
  default_value!: string;
  form_id!: number;
  size!: number;
  read_only!: boolean;
  max_length!: number;
  linked_to!: null | string;
  placeholder!: null | string;
  detail_form_id!: null | number;
  visible_when!: null | string;
  format_rules_definition!: {
    columns_labels: ColumnsLabels[],
    no_allow_new_rows_on_edit: boolean,
    max_row: number,
    hide: true,
    render_type?: 'field' | 'radio_button',
    sub_type?: string
    dependencies?: string[]
    default_value?: string,
    indicative?: string
    hide_indicative?: boolean
    condition_order_by?: string[]
    default?: string | number
    date_since_now?: boolean
    blocked_from_date?: string
  };
  style_rules_definition!: null | string | StyleRulesDefinition;
  list_provider!: null | ListProvider;
  field_type!: FieldType;
  sub_type?: SubType;
  list_provider_id!: null | number;
  sort_index!: number;
  parent!: string;
}
export interface StyleRulesDefinition {
  alignment: "h" | "v"
}

export interface ColumnsLabels {
  name: string;
  label: string;
  type?: string
  compound_name?: string;
  value: string[];
  required?: boolean
  key_from_value: boolean
}

export interface FormatRules {
  format_rules?: { columns_labels: ColumnsLabels[], dependencies: string[], default_value?: string, default_value_conditions?: string[], condition_order_by?: string[] }
}
