import React from "react";
import useRequired from "../../../hooks/useRequired";

import TextProps from "./Models/TextProps";

interface IProps extends TextProps {
  value: string;
  onChange?: (value: string) => void;
}

const LargeTextComponent: React.FC<IProps> = ({
                                                id,
                                                name,
                                                value,
                                                invalid,
                                                required,
                                                readonly,
                                                disabled,
                                                style,
                                                placeholder,
                                                className,
                                                onChange,
  onFocus,
  onBlur,
  defaultValue
                                              }) => {

  const error = useRequired(value, Boolean(required));

  return (
    <textarea id={id}
              name={name}
              value={value}
              required={required}
              readOnly={readonly}
              disabled={disabled}
              style={style}
              onFocus={onFocus}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onBlur={onBlur}
              className={`form-control ${className || ''} ${invalid || error ? 'invalid' : ''}`}
              onChange={e => onChange ? onChange(e.target.value) : null}/>
  );
}

export default LargeTextComponent;
