import React, {useEffect, useState} from 'react';
import useTranslate from '../../hooks/useTranslate';
import {Button} from 'reactstrap';
import {SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import {State} from '../../types/State';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash, faInfoCircle, faSpinner, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import {authReducer} from '../../store/Selectors';
import {changePassword, getConfigChangePassword, logout} from '../../services/backend/AuthService';
import {toast} from '../../utils';
import PopoverComponent from '../../components/Shared/PopoverComponent';
import {actionsModal, ModalTypes} from '../../store/Modal/Slice';
import useResponsive from "../../hooks/useReponsive";
import Link from "next/link";


type FormData = {
  username: string, password: string, current_password: string
}

let rules = [
  '1 Número',
  '1 Símbolo',
  '1 Letra Mayúscula',
  '1 Letra Minúscula'
];

interface Iprops {
  modal?: boolean;
  noStyles?: boolean;
  email?: string;
  proposal?: boolean;
  onLoading?: (l: boolean) => void;
}

const UpdatePassword = ({modal, email, proposal, onLoading, noStyles}: Iprops) => {
  const dispatch = useDispatch();
  const {user} = useSelector(authReducer);
  const {isTabletOrMobile} = useResponsive();
  const [patterns, setPatterns] = useState(
    {
      'minLength': 4,
      'pattern': '^(?=.*[a-z])(?=.*[A-Z])',
      // 'pattern': '^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@$!+%*-?&])',
      'rules': [
        // '1 Número',
        // '1 Signo',
        '1 Mayúscula',
        '1 Minúscula'
      ]
    }
  );
  
  const [patternRules, setPatterRule] = useState(rules);
  
  const {register, handleSubmit} = useForm<FormData>();
  const [loading, setLoading] = useState<State>(State.IDLE);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const {t} = useTranslate('common');
  
  
  useEffect(() => {
    setDefaultValue(!email ? (user?.email ?? '') : (email));
    const getKeyRules = async () => {
      //starting
      setLoading(State.PENDING)
      const result = await getConfigChangePassword().then(res => res);
      if (result.item) {
        setPatterns(result.item);
      }
      setLoading(State.RESOLVED)
    }
    getKeyRules();
    
  }, []);
  React.useEffect(() => {
    if (patterns?.rules) {
      setPatterRule(patterns.rules);
    }
  }, [patterns]);
  
  const patterContainer = <div className="d-flex flex-column">
    <span>{t('custom-message:pattern')}</span>
    <ul>
      {patternRules.map(rule => (
        <li key={rule}>{rule}</li>
      ))}
    </ul>
  </div>;
  
  useEffect(() => onLoading && onLoading(loading === State.PENDING), [loading])
  
  const messageErrors: { [key: string]: { [key: string]: string } } = {
    password: {
      minLength: `${t('custom-message:min-length')} ${patterns?.minLength ?? 4} ${t('characters')}`,
      pattern: "Invalid pattern!",
      required: t("custom-message:field_required")
    }
  };
  
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setLoading(State.PENDING);
    const res = await changePassword(data);
    if (res?.success) {
      toast({message: t('custom-message:update-password')});
      !modal ? (logout()) : dispatch(actionsModal.closeModal({type: ModalTypes.CHANGE_PASSWORD}));
      setLoading(State.RESOLVED);
    } else {
      toast({
        type: 'error',
        message: res?.error ?? t((res.message === 'User Not Found' || res.message === 'Invalid credentials') ? 'custom-message:update-password-errorData' : 'custom-message:update-password-error')
      });
      setLoading(State.REJECTED);
    }
  };
  
  
  const onError: SubmitErrorHandler<FormData> = (error) => {
    if (error.password) {
      toast({message: messageErrors['password'][error.password.type] ?? '', type: "error"});
    }
  };
  const loadingData = loading === State.PENDING;
  
  return (
    <div className={`${(isTabletOrMobile || modal) ? "" : "mx-auto"}`}>
      
      <div>
        <h1 className="text-primary">{t("change_password")}</h1>
        <div className="d-flex flex-column px-5 pt-3">
          <span>{t("custom-message:change-password-rules")}</span>
          
          
          {patterContainer}
        </div>
        
        <form id="change_password" onSubmit={handleSubmit(onSubmit, onError)} className="d-flex flex-column gap-2 m-3">
          <div className="d-flex flex-column col-12">
            <label>{t('email')}/{t('user')}</label>
            <input type="email" defaultValue={defaultValue}
                   className="form-control" {...register('username', {required: true})}/>
          </div>
          {!modal && <div className="d-flex flex-column col-12">
            <div className="d-flex align-items-center">
              <label>{t('current_password')} </label>
              
              {patterns?.rules && <PopoverComponent
                id="password_info"
                label={<FontAwesomeIcon icon={faInfoCircle}/>}
                trigger="click" title=""
                placement="top">
                {patterContainer}
              </PopoverComponent>}
            </div>
            <div className="d-flex align-items-center">
              <input type={showCurrentPassword ? 'text' : 'password'}
                     className="form-control" {...register('current_password', {
                required: true,
                //minLength: patterns?.minLength ?? 4,
                // pattern: patterns?.pattern ? new RegExp(patterns.pattern, '') : undefined
              })}/>
              <span className="input-group-text pointer"
                    onClick={() => setShowCurrentPassword(prevState => !prevState)}><FontAwesomeIcon
                icon={showCurrentPassword ? faEyeSlash : faEye}/></span>
            </div>
          </div>}
          <div className="d-flex flex-column col-12">
            <label>{t('password')}</label>
            <div className="d-flex align-items-center">
              <input type={visiblePassword ? 'text' : 'password'} className="form-control" {...register('password', {
                required: true,
                minLength: patterns?.minLength ?? 4,
                pattern: patterns?.pattern ? new RegExp(patterns.pattern, '') : undefined
              })}/>
              <span className="input-group-text pointer"
                    onClick={() => setVisiblePassword(prevState => !prevState)}><FontAwesomeIcon
                icon={visiblePassword ? faEyeSlash : faEye}/></span>
            </div>
          </div>
          
          
          {!proposal && <div className={"d-flex justify-content-end"}>
            {!modal && <Link href="/auth/login">
              <a href="#" className="btn-lg btn btn-link">
                Go back to login
              </a>
            </Link>}
            <Button color="primary" disabled={loadingData}>
              <FontAwesomeIcon className="me-1" icon={loadingData ? faSpinner : faSyncAlt} spin={loadingData}/>
              {t('change_password')}
            </Button>
          </div>}
        </form>
      </div>
    </div>
  
  );
};


export default UpdatePassword;
