import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getCookieData, saveCookieData} from '../utils';

const slice = createSlice({
  name: "search",
  initialState: {text: "", state: true, page: 1},
  reducers: {
    changeText: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
      state.state = false;
      saveCookieData(action.payload, "historySearch");
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      state.state = false;
      saveCookieData(action.payload.toString(), "historyPage");
    },
    validateData: (state) => {
      state.text = getCookieData("historySearch");
      state.page = parseInt(getCookieData("historyPage") || "1");
      state.state = false;
    },
    changeState(state, action: PayloadAction<boolean>) {
      state.state = action.payload;
    }
  }
});

export const actionHistory = slice.actions;
export default slice.reducer;
