import React from "react";

import * as Sentry from "@sentry/nextjs";

let interval: any = null;

class ErrorBoundary extends React.Component<{ children: JSX.Element | JSX.Element[], redirect: () => void }> {
  state = {hasError: false, redirecting_time: 5};
  
  constructor(props: any) {
    super(props)
    
    // Define a state variable to track whether is an error or not
    this.state = {hasError: false, redirecting_time: 5}
  }
  
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    Sentry.captureException(error);
    console.log(error);
    return {hasError: true}
  }
  
  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    console.log({error, errorInfo});
    if (interval) {
      clearInterval(interval);
    }
    let count = 5;
    interval = setInterval(() => {
      this.setState({redirecting_time: this.state.redirecting_time - 1});
      count--;
      if (!count) {
        clearInterval(interval);
        this.props.redirect();
        setTimeout(() => {
          this.setState({redirecting_time: 5, hasError: false});
        }, 1000)
      }
    }, 1000);
  }
  
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          <div>
            <div style={{width: 50, height: 50}}
                 className="rounded-circle p-2 d-flex justify-content-center align-items-center border border-dark mx-auto">
              {this.state.redirecting_time}
            </div>
            <b className="d-flex justify-content-center">Redirecting...</b>
            <br/>
            
            <h2>Oops, there is an error!</h2>
            <br/>
            <button
              className="btn-primary btn btn-sm "
              type="button"
              onClick={() => {
                this.setState({hasError: false, redirecting_time: 5});
              }}
            >
              Try again?
            
            </button>
          </div>
        </div>
      )
    }
    
    // Return children components in case of no error
    
    return (
      <>
        {this.props.children}
      </>
    )
  }
}

export default ErrorBoundary