import {deleteReq, get, post, put} from '../../network';
import {Response} from '../../types/Response';
import IField from '../../types/FormBuilder/IField';
import Form from '../../types/FormType';
import {ListProvider} from '../../types/Task';
import {getProviderByIdWidthOptions} from "./DataProviderService";

export function getFormList(): Promise<Response> {
  return get('/api/v2/wf/form');
}

export function getFormById(id: number): Promise<Response> {
  return get(`/api/v2/wf/form/${id}`);
}

export function updateFormById(id: number, name: string): Promise<Response> {
  return put(`/api/v2/wf/form/${id}`, {name});
}

export function deleteFormById(id: number): Promise<Response> {
  return deleteReq(`/api/v2/wf/form/${id}`);
}

export function saveForm(body: { name: string }): Promise<Response> {
  return post('/api/v2/wf/form', body);
}

export function saveField(formId: number, field: IField) {
  return post(`/api/v2/wf/form/${formId}/field`, field);
}

export function updateField(formId: number, field: IField) {
  return put(`/api/v2/wf/form/${formId}/field/${field.id}`, field);
}

export function deleteFieldId(fieldId: number, formId: number) {
  return deleteReq(`/api/v2/wf/form/${formId}/field/${fieldId}`);
}


export function getAllListProvider() {
  return get('/api/v2/wf/dl');
}

export function getProviderById(id: number): Promise<Response<ListProvider>> {
  return get('/api/v2/wf/dl/' + id);
}

export async function addProviderDataToFields(form: Form): Promise<Response<Form>> {
  for await (let field of form.fields) {
    let index = form.fields.indexOf(field);
    let fieldData: any = form.fields[index];
    if (fieldData.list_provider_id) {
      const providerRes = await getProviderByIdWidthOptions(fieldData.list_provider_id);
      fieldData.list_provider = providerRes.item;
      form.fields[index] = fieldData;
    }
  }
  return Promise.resolve({success: true, item: form});
}

export async function cloneForm(form: Form) {
  const newForm = {name: form.name};
  const currentForm = await getFormById(form.id);
  const resForm = await saveForm(newForm);
  if (resForm.success) {
    const formId = resForm.item.id;
    try {
      for await (let field of currentForm.item.fields) {
        let nField: any = Object.assign({}, field);
        delete nField.id;
        delete nField.form_id;
        await saveField(formId, nField);
      }
      return resForm;
    } catch (e) {
      await deleteFormById(formId);
      return {success: false};
    }

  }
}


export async function updateFields(fields: IField[]): Promise<Response> {
  try {
    for await (let field of fields) {
      await updateField(field.form_id, field);
    }
    return {success: true};
  } catch (e) {
    return {success: false};
  }
}
