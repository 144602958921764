import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {PermissionStates, permissionTypeSlice} from "./Types";
import {success, toast} from '../../utils';
import {actionsPermissions} from "./Slice";
import {
  assignPermissionOrGroupToUser,
  assignPermissionToGroup, createMembership, createUser,
  deletePermission,
  getAllGroups,
  getAllUsers,
  getGroupById,
  getPermissions,
  getUserById,
  removeGroup,
  removeMembership,
  removePermissionFromUser,
  saveGroup,
  savePermission,
  updateAssignment,
  updateGroup
} from '../../services/backend/PermissionsService';
import {actionsModal, ModalTypes} from "../Modal/Slice";
import {PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../Reducers";
import {Response} from "../../types/Response";
import {TrackEventMonitor} from '../../classes/TrackEvent';

function error() {
  toast({type: "error", message: ""});
}


function* getPermissionsSaga() {
  try {
    const res: Response = yield call(getPermissions);
    if (res.success && res.items) {
      yield put(actionsPermissions.setPermissions(res.items));
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.PERMISSIONS_REJECTED));
  }
}


function* saveModulePermissionsSaga({payload}: ReturnType<typeof actionsPermissions.addModulePermissions>) {
  try {
    const res: Response = yield call(savePermission, payload.permissions);
    if (res.success) {
      yield put(actionsPermissions.changeState(PermissionStates.PERMISSION_RESOLVED));
      yield put(actionsModal.closeModal({type: ModalTypes.UPSERT_PERMISSION}));
      yield put(actionsPermissions.getPermissions());
      success();
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.PERMISSION_REJECTED));
  }
}

function* deleteModulePermissionsSaga({payload}: ReturnType<typeof actionsPermissions.deleteModulePermissions>) {
  try {
    const res: Response = yield call(deletePermission, payload);
    if (res.success) {
      yield put(actionsPermissions.changeState(PermissionStates.PERMISSION_DELETED));
      yield put(actionsModal.closeModal({type: ModalTypes.CONFIRM}));
      yield put(actionsModal.closeModal({type: ModalTypes.UPSERT_PERMISSION}));
      yield put(actionsPermissions.getPermissions());
      success();
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.PERMISSION_REJECTED));
  }
}

function* updateModulePermissionsSaga({payload}: ReturnType<typeof actionsPermissions.editModulePermission>): any {
  try {
    const resDelete = yield call(deletePermission, payload.toDelete);
    const resSave = yield call(savePermission, payload.toSave);
    if (resDelete.success && resSave.success) {
      yield put(actionsPermissions.changeState(PermissionStates.PERMISSION_RESOLVED));
      yield put(actionsModal.closeModal({type: ModalTypes.UPSERT_PERMISSION}));
      yield put(actionsPermissions.getPermissions());
      success();
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.PERMISSION_REJECTED));
  }
}


function* getUsersSaga() {
  try {
    const res: Response = yield call(getAllUsers);
    if (res.success && res.items) {
      yield put(actionsPermissions.setUsers(res.items));
      success();
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.USERS_REJECTED));
  }
}

function* getGroupsSaga() {
  try {
    const res: Response = yield call(getAllGroups);
    if (res.success && res.items) {
      yield put(actionsPermissions.setGroups(res.items));
      // success();
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.USERS_REJECTED));
  }
}

function* getUserByIdSaga({payload}: PayloadAction<number>) {
  try {
    const res: Response = yield call(getUserById, payload);
    if (res.success) {
      yield put(actionsPermissions.setUser(res.item));
      success("User loaded.");
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.USER_REJECTED));
  }
}

function* assignPermissionToUserSaga({payload}: ReturnType<typeof actionsPermissions.assignPermissionOrGroupToUser>) {
  try {
    const res: Response = yield call(assignPermissionOrGroupToUser, payload);
    if (res.success) {
      TrackEventMonitor.newEvent({
        props: {
          permission_user_id: payload.userId,
          permission_id: payload.permissionId,
          metadata: payload.metadata,
        },
        metadata: {
          name: "sbx_crm_assign_user_permission@sbx"
        }
      })
      yield put(actionsPermissions.getUserById(payload.userId ?? 0));
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.USER_REJECTED));
  }
}

function* updatePermissionToUserSaga({payload}: ReturnType<typeof actionsPermissions.assignPermissionOrGroupToUser>) {
  try {
    const res: Response = yield call(updateAssignment, {
      userId: payload.userId,
      permissionId: payload.permissionId,
      groupId: payload.groupId,
      metadata: payload.metadata
    });
    if (res.success) {
      TrackEventMonitor.newEvent({
        props: {
          permission_user_id: payload.userId,
          permission_id: payload.permissionId,
          permission_group_id: payload.groupId,
          metadata: payload.metadata,
          type: payload.type
        },
        metadata: {
          name: "sbx_crm_update_user_permission@sbx"
        }
      })
      switch (payload.type) {
        case "user":
          yield put(actionsPermissions.getUserById(payload.userId ?? 0));
          break;
        case "group":
          yield put(actionsPermissions.getGroupById(payload.groupId ?? 0));
          break;
      }
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.USER_REJECTED));
  }
}


function* assignPermissionToGroupSaga({payload}: ReturnType<typeof actionsPermissions.assignPermissionToGroup>) {
  try {
    const res: Response = yield call(assignPermissionToGroup, payload.groupId, payload.permissionId);
    if (res.success) {
      TrackEventMonitor.newEvent({
        props: {
          permission_group_id: payload.groupId,
          permission_id: payload.permissionId,
        },
        metadata: {
          name: "sbx_crm_assign_group_permission@sbx"
        }
      })
      yield put(actionsPermissions.getGroupById(payload.groupId));
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.USER_REJECTED));
  }
}

function* removePermissionFromUserOrGroupSaga({payload}: PayloadAction<number>): any {
  try {
    const userId = yield select((state: RootState) => state.PermissionReducer.user?.id);
    const groupId = yield select((state: RootState) => state.PermissionReducer.group?.id);
    const res: Response = yield call(removePermissionFromUser, payload);
    if (res.success) {

      if (userId) {
        yield put(actionsPermissions.getUserById(userId));
      }
      if (groupId) {
        yield put(actionsPermissions.getGroupById(groupId));
      }
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.USER_REJECTED));
  }
}

function* getGroupByIdSaga({payload}: PayloadAction<number>) {
  try {
    const res: Response = yield call(getGroupById, payload);
    if (res.success) {
      yield put(actionsPermissions.setGroup(res.item));
      success("Group loaded");
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.GROUP_REJECTED));
  }
}

function* createGroupSaga({payload}: ReturnType<typeof actionsPermissions.saveGroup>) {
  try {
    const res: Response = yield call(saveGroup, payload);
    if (res.success) {
      yield put(actionsPermissions.changeState(PermissionStates.GROUP_RESOLVED));
      yield put(actionsPermissions.getAllGroups());
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.GROUP_REJECTED));
  }
}
function* createUserSaga({payload}: ReturnType<typeof actionsPermissions.createUser>) {
  try {
    const res: Response = yield call(createUser, payload.data,payload.recoverPassword );
    if (res.success) {
      yield put(actionsModal.closeModal({type: ModalTypes.CREATE_USER}));
      yield put(actionsPermissions.changeState(PermissionStates.USER_RESOLVED));
      yield put(actionsPermissions.getAllUsers());
    } else {
      yield put(actionsPermissions.changeState(PermissionStates.PERMISSION_REJECTED));
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.GROUP_REJECTED));
  }
}

function* updateGroupSaga({payload}: ReturnType<typeof actionsPermissions.updateGroup>) {
  try {
    const res: Response = yield call(updateGroup, payload);
    if (res.success) {
      yield put(actionsPermissions.changeState(PermissionStates.GROUP_RESOLVED));
      yield put(actionsPermissions.getAllGroups());
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.GROUP_REJECTED));
  }
}

function* deleteGroupSaga({payload}: ReturnType<typeof actionsPermissions.removeGroup>) {
  try {
    const res: Response = yield call(removeGroup, payload);
    if (res.success) {
      yield put(actionsPermissions.changeState(PermissionStates.GROUP_DELETED));
      yield put(actionsPermissions.getAllGroups());
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.GROUP_REJECTED));
  }
}

function* createMembershipSaga({payload}: ReturnType<typeof actionsPermissions.createMembership>) {
  try {
    const res: Response = yield call(createMembership, payload.groupId,payload.userId);
    if (res.success) {
      // yield put(actionsPermissions.changeState(PermissionStates.GROUP_RESOLVED));
      yield put(actionsPermissions.getUserById(payload.userId));
      // yield put(actionsPermissions.getAllUsers());
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.GROUP_REJECTED));
  }
}

function* removeMembershipSaga({payload}: ReturnType<typeof actionsPermissions.removeMembership>) {
  try {
    const res: Response = yield call(removeMembership, payload.groupId, payload.userId);
    if (res.success) {
      yield put(actionsPermissions.getUserById(payload.userId));
    } else {
      throw Error();
    }
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.GROUP_REJECTED));
  }
}

function* MembershipArraySaga({payload}: ReturnType<typeof actionsPermissions.arrayMembership>) {
  try {

    // console.log("payload saga", payload)

    const resAllUnCheck:Array<Response>= yield all(payload.userUnCheck.map((i)=> call(removeMembership, payload.groupId, i)));
    // console.log("service uncheck", resAllUnCheck)
    const resAllCheck:Array<Response> = yield all(payload.userCheck.map((i)=> call(createMembership, payload.groupId, i)));
    // console.log("service check", resAllCheck)
    const res=resAllCheck.filter(res=>!res.success)
    const res1=resAllUnCheck.filter(res=> !res.success)
    if ((!res1.length && resAllUnCheck.length)|| (!res.length && resAllCheck.length)) {
      toast({message: "Permissions updated."});
    } else {
      res1.length&&toast({type:'error',message: JSON.stringify(res1.map(i=>i.message)).replace(/[\[\]]/g,'')});
      res.length&&toast({type:'error',message: JSON.stringify(res.map(i=>i.message)).replace(/[\[\]]/g,'')});
    }

    yield put(actionsPermissions.getGroupById(payload.groupId));
  } catch (e) {
    error();
    yield put(actionsPermissions.changeState(PermissionStates.GROUP_REJECTED));
  }

}

export default function* permissionSaga(): any {
  return yield all([
    takeEvery(permissionTypeSlice.GET_PERMISSIONS, getPermissionsSaga),
    takeEvery(permissionTypeSlice.ADD_PERMISSIONS, saveModulePermissionsSaga),
    takeEvery(permissionTypeSlice.DELETE_PERMISSIONS, deleteModulePermissionsSaga),
    takeEvery(permissionTypeSlice.UPDATE_MODULE_PERMISSIONS, updateModulePermissionsSaga),
    takeEvery(permissionTypeSlice.GET_USERS, getUsersSaga),
    takeEvery(permissionTypeSlice.GET_GROUPS, getGroupsSaga),
    takeEvery(permissionTypeSlice.GET_USER, getUserByIdSaga),
    takeEvery(permissionTypeSlice.SET_PERMISSION_OR_GROUP_TO_USER, assignPermissionToUserSaga),
    takeEvery(permissionTypeSlice.SET_PERMISSION_TO_GROUP, assignPermissionToGroupSaga),
    takeEvery(permissionTypeSlice.REMOVE_PERMISSION_FROM_USER, removePermissionFromUserOrGroupSaga),
    takeEvery(permissionTypeSlice.UPDATE_MEMBERSHIP_FROM_USER_OR_GROUP, updatePermissionToUserSaga),
    takeEvery(permissionTypeSlice.GET_GROUP_BY_ID, getGroupByIdSaga),
    takeEvery(permissionTypeSlice.CREATE_GROUP, createGroupSaga),
    takeEvery(permissionTypeSlice.CREATE_USER, createUserSaga),
    takeEvery(permissionTypeSlice.UPDATE_GROUP, updateGroupSaga),
    takeEvery(permissionTypeSlice.DELETE_GROUP_BY_ID, deleteGroupSaga),
    takeEvery(permissionTypeSlice.REMOVE_MEMBERSHIP, removeMembershipSaga),
    takeEvery(permissionTypeSlice.ARRAY_MEMBERSHIP, MembershipArraySaga),
    takeEvery(permissionTypeSlice.CREATE_MEMBERSHIP, createMembershipSaga),
  ])
}

