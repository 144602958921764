import {actionsModal, ModalTypes} from "../../../../store/Modal/Slice";
import ModalComponent, {ModalProps} from "../ModalComponent/ModalComponent";
import {useDispatch, useSelector} from "react-redux";
import React from "react";

interface IProps {
  open: boolean;
  data: IPropsDynamicComponentModal;
}


export interface IPropsDynamicComponentModal extends Omit<ModalProps, "type" | "isOpen" | "toggle" | "isLoading"> {
  type: ModalTypes.DYNAMIC_COMPONENT_MODAL;
  component: React.ReactNode;
  typeForm?: "button" | "submit" | "reset";
  state?: string;
  toggle?: () => void;
}

const DynamicComponentModal = ({
                                 data: {
                                   component,
                                   size,
                                   footer,
                                   title,
                                   typeForm,
                                   noFooter,
                                   onSuccess,
                                   labelCancel,
                                   labelSuccess,
                                   form,
                                   zIndex,
                                   header,
                                   disabled,
                                   state,
                                   toggle: onToggle
                                 }, open
                               }: IProps) => {
  
  const dispatch = useDispatch();
  const stateReducers: any = useSelector(state => state);
  
  function toggle() {
    dispatch(actionsModal.closeModal({type: ModalTypes.DYNAMIC_COMPONENT_MODAL}));
  }
  
  const loading = Object.keys(stateReducers).some(key => {
    return stateReducers[key]?.state === state || stateReducers[key]?.mfaStates === state || stateReducers[key]?.linkStates === state;
  });
  
  return (
    <ModalComponent
      size={size}
      type={typeForm}
      title={title}
      footer={footer}
      isOpen={open}
      noFooter={noFooter}
      onSuccess={onSuccess}
      labelCancel={labelCancel}
      labelSuccess={labelSuccess}
      disabled={disabled}
      form={form}
      zIndex={zIndex}
      isLoading={loading}
      header={header}
      toggle={onToggle ?? toggle}>
      {component}
    </ModalComponent>
  )
}

export default DynamicComponentModal;