import {Field} from "../../../../types/Field";
import {FieldType, SubType} from "../../../../types/FieldType";
import {plainToClass} from "class-transformer";
import {uuidV4} from "../../../../utils";
import {Column} from "../../CustomTableComponent";

export const columns: Column[] = [
  {name: "id", type: "String", header: "id"},
  {name: "name", type: "String", header: "Name"},
  {name: "data_store", type: "String", header: "Data Store"},
  {name: "event_type", type: "String", header: "Event type"},
]

export const fields: Field[] = [
  plainToClass(Field, {
    "label": "Name",
    "name": "name",
    "required": false,
    "single_value": true,
    "default_value": `name ${uuidV4().slice(0, 5)}`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
  }),
  plainToClass(Field, {
    "label": "Form",
    "name": "attachments",
    placeholder: "Select...",
    "required": false,
    "single_value": true,
    "default_value": null,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "Event Type",
    "name": "event_type",
    "required": true,
    "single_value": true,
    "default_value": null,
    placeholder: "Select...",
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "Data Store",
    "name": "data_store_id",
    "required": false,
    "single_value": true,
    "default_value": null,
    placeholder: "Select...",
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  })
]
