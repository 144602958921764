import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {getInitialLetter} from '../../../utils';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Image} from 'antd';

type Props = {
  name?: string
  showName?: boolean
  image?: string;
  className?: string;
  nameClassName?: string;
  avatarSmall?: boolean;
  size?: 'sm' | 'xs' | 'lg' | 'xl' | any;
  info?: React.ReactNode;
  bodyAction?: React.ReactNode;
  loading?: boolean
  imageWidth?: number
}

const UserComponent = ({
                         name,
                         showName,
                         info,
                         image,
                         size,
                         bodyAction,
                         className = '',
                         nameClassName = '',
                         avatarSmall,
                         loading,
                         imageWidth = 200
                       }: Props) => {

  const [isError, setIsError] = useState(false);

  return (
    <div className={`${className}`}>
      <div className="d-flex align-items-center">
        <div
          className={'border border-3 avatar rounded-circle me-2 position-relative ' + `${size ? ('avatar-' + size)
            : (avatarSmall ? 'avatar-xs' : 'avatar-sm')} ${(!image || isError) ? `name-${getInitialLetter(name || '').toUpperCase()}` : ''} `}>
          {(image && !isError ) ? <Image
            preview={{maskClassName: 'rounded-circle'}}
            className={'rounded-circle'}
            onError={() => {
              setIsError(true)
            }}
            width={imageWidth}
            src={image}
          /> : <span className={size ? ('text-' + size) : (avatarSmall ? 'text-xs' : 'text-sm')}>
          {loading ? <FontAwesomeIcon icon={faSpinner} size={size} spin/> : getInitialLetter(name || '')}
        </span>}
          <div className="position-absolute" style={{bottom: -4}}>
            {bodyAction}
          </div>
        </div>
        {showName && <span className={`${avatarSmall ? 'font-sm' : ''} ${nameClassName}`}>{name}</span>}
      </div>
      {info}
    </div>

  );
};

export default UserComponent;
