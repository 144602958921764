import React, {useState,} from 'react';
import ModalComponent from './ModalComponent/ModalComponent';
import {actionsModal, ModalTypes} from '../../../store/Modal/Slice';
import {useDispatch, useSelector} from 'react-redux';
import {FormGroup, Label} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {RootState} from '../../../store/Reducers';
import {PermissionStates} from '../../../store/Permission/Types';
import {actionsPermissions} from '../../../store/Permission/Slice';
import useTranslate from '../../../hooks/useTranslate';
import {SubmitHandler, useForm} from 'react-hook-form';


interface IProps {
  open: boolean;
  data: IPropsUserModal
}

export interface Data {
  username: string,
  password: string,
  first_name: string,
  last_name: string,
  email: string,
  enabled: boolean;
  password_expiration_at?: Date;
}

export interface IPropsUserModal {
  type: ModalTypes.CREATE_USER;
  id?: number;
  onFinish?: () => void;
  key?: string;
}

const UserModal = (props: IProps) => {
  const {open} = props;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
  } = useForm<Data>();
  const {state} = useSelector((state: RootState) => state.PermissionReducer);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const {t} = useTranslate("permissions-manager");
  const [recoverPassword, setRecoverPassword] = useState(false);
  
  function toggle() {
    dispatch(actionsModal.closeModal({type: ModalTypes.CREATE_USER}));
  }
  
  const onSubmit: SubmitHandler<Data> = (data: Data) => {
    dispatch(actionsPermissions.changeState(PermissionStates.USER_PENDING));
    dispatch(actionsPermissions.createUser({data, recoverPassword}));
  };
  return (
    <ModalComponent
      isLoading={state === PermissionStates.USER_PENDING}
      title={<><FontAwesomeIcon icon={faPlusCircle}/>{" " + t("modal-create-user")}</>}
      isOpen={open}
      form={"new_group_form"}
      type="submit"
      size={'lg'}
      toggle={() => toggle()}>
      <form onSubmit={handleSubmit(onSubmit)} id="new_group_form">
        <div className="row">
          <div className="col-6">
            <FormGroup>
              <Label>
                {t("common:user-name")}
              </Label>
              <input required
                     type="text"
                     placeholder="Ej: Jack"
                     className="form-control"
                     {...register("username", {required: true})}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                {t("common:first-name")}
              </Label>
              <input required
                     type="text"
                     placeholder="Ej: Williams"
                     className="form-control"
                     {...register("first_name", {required: true})}
              
              />
            </FormGroup>
            <FormGroup>
              <Label>
                {
                  t("common:enable-user")
                }
              </Label>
              <div className="form-check form-switch w-25  ">
                <input
                  style={{minWidth: "44px", minHeight: "22px"}}
                  type="checkbox"
                  className="form-check-input  "
                  {...register("enabled")}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label>
                {
                  t("common:force_to_recover_password")
                }
              </Label>
              <div className="form-check form-switch w-25  ">
                <input
                  style={{minWidth: "44px", minHeight: "22px"}}
                  type="checkbox"
                  className="form-check-input"
                  checked={recoverPassword}
                  onChange={e => setRecoverPassword(e.target.checked)}
                />
              </div>
            </FormGroup>
          
          </div>
          <div className="col-6">
            <FormGroup>
              <Label>
                {t("common:last-name")}
              </Label>
              <input required
                     type="text"
                     placeholder="Ej: Williams"
                     className="form-control"
                     {...register("last_name", {required: true})}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                {t("common:email")}
              </Label>
              <input type="text"
                     required
                     placeholder="Ej: Admin@gmail.com"
                     className="form-control"
                     {...register("email", {required: true})}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                {t("common:password")}
              </Label>
              <div className="d-flex align-items-center">
                <input type={showCurrentPassword ? 'text' : 'password'}
                       required
                       className="form-control"
                       {...register("password", {required: true})}
                />
                
                <span className="input-group-text pointer"
                      onClick={() => setShowCurrentPassword(prevState => !prevState)}><FontAwesomeIcon
                  icon={showCurrentPassword ? faEyeSlash : faEye}/></span>
              </div>
            </FormGroup>
          </div>
        </div>
      </form>
    </ModalComponent>
  )
}

export default UserModal;
