import {Field} from "../../../../types/Field";
import {FieldType, SubType} from "../../../../types/FieldType";
import {plainToClass} from "class-transformer";
import {uuidV4} from "../../../../utils";


export const fields: Field[] = [
  plainToClass(Field, {
    "label": "Duration",
    "name": "duration",
    "required": false,
    "single_value": true,
    "default_value": 0,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
    "sub_type": SubType.NUMBER,
  }),
  plainToClass(Field, {
    "label": "Task type",
    "name": "task_type",
    "required": true,
    "single_value": true,
    "default_value": null,
    placeholder: "Select...",
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "Name",
    "name": "name",
    "required": false,
    "single_value": true,
    "default_value": `name ${uuidV4().slice(0, 5)}`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
  }),
  plainToClass(Field, {
    "label": "Activity type",
    "name": "activity_type",
    "required": true,
    "single_value": true,
    "default_value": "",
    placeholder: "Select task type",
    visible_when: `task_type === ""`,
    "size": 1,
    "disabled": true,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
  }),

  plainToClass(Field, {
    "label": "Activity type",
    "name": "activity_type",
    "required": true,
    "single_value": true,
    "default_value": "TASK",
    placeholder: "Select task type",
    visible_when: `task_type === "SERVICE"`,
    "size": 1,
    "disabled": true,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
  }),
  plainToClass(Field, {
    "label": "Activity type",
    "name": "activity_type",
    "required": true,
    "single_value": true,
    "default_value": "TASK_SCRIPT",
    "size": 1,
    visible_when: `task_type === "SCRIPT"`,
    "disabled": true,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
  }),
  plainToClass(Field, {
    "label": "Activity type",
    "name": "activity_type",
    "required": true,
    "single_value": true,
    "default_value": "TASK",
    "size": 1,
    visible_when: `task_type === "USER"`,
    "disabled": true,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
  }),
  plainToClass(Field, {
    "label": "Label",
    "name": `label`,
    "required": false,
    "single_value": true,
    "default_value": `label ${uuidV4().slice(0, 5)}`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
  }),
  plainToClass(Field, {
    "label": "Form",
    "name": "attachments",
    placeholder: "Select...",
    "required": false,
    "single_value": true,
    "default_value": null,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "Description",
    "name": "description",
    "required": false,
    "single_value": true,
    "default_value": "",
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.LARGE_TEXT,
  }),
  plainToClass(Field, {
    "label": "Assigment type",
    "name": "assignment_type",
    "required": true,
    "single_value": true,
    "default_value": null,
    placeholder: "Select...",
    visible_when: `task_type === "USER"`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),plainToClass(Field, {
    "label": "Assigment type",
    "name": "assignment_type",
    "required": true,
    "single_value": true,
    "default_value": null,
    placeholder: "Select...",
    visible_when: `task_type === "SERVICE"`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "Rule",
    "name": "rule",
    "required": false,
    "single_value": true,
    visible_when: `assignment_type === "COMPLEX" || task_type === "SCRIPT"`,
    "default_value": `process["__result"] = {}`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.LARGE_TEXT,
  }),
  plainToClass(Field, {
    "label": "Rule",
    "name": "rule",
    "required": false,
    "single_value": true,
    visible_when: `assignment_type === "GROUP"`,
    "default_value": `process["__result"] = {'groups':[]}`,
    "size": 1,
    "disabled": true,
    "max_length": 128,
    "field_type": FieldType.LARGE_TEXT,
  }),
  plainToClass(Field, {
    "label": "Rule",
    "name": "rule",
    "required": false,
    "single_value": true,
    visible_when: `assignment_type === "USER" `,
    "default_value": `process["__result"] = {'users':[]}`,
    "size": 1,
    "disabled": true,
    "max_length": 128,
    "field_type": FieldType.LARGE_TEXT,
  }),
  plainToClass(Field, {
    "label": "Users",
    "name": "users",
    "required": false,
    "single_value": false,
    "default_value": null,
    placeholder: "Select...",
    visible_when: `assignment_type === "USER"`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "Groups",
    "name": "groups",
    "required": false,
    "single_value": false,
    "default_value": null,
    placeholder: "Select...",
    visible_when: `assignment_type === "GROUP"`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "Finish assignments",
    "name": "finish_assignments",
    "required": false,
    "single_value": true,
    "default_value": false,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.TOGGLE,
  })
]
