import React, {useRef, useState} from "react";
import {Account} from "../../types/Account";
import {findByLike} from "../../services/backend/AccountService";
import AutoSuggest from 'react-autosuggest';
import {useRouter} from "next/router";
import {capitalize} from "../../utils";
import useTranslate from "../../hooks/useTranslate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {authReducer} from "../../store/Selectors";

const AutoSuggestAccounts = () => {
  const [search, setSearch] = useState("");
  const [items, setItems] = useState<Account[]>([]);
  const router = useRouter();
  const state = useRef(false);
  const {current: loading} = state;
  const {t} = useTranslate("common");
  const {user: {config}} = useSelector(authReducer)

  const loadSuggestions = async (value: string) => {
    state.current = true;
    const res = await findByLike({search: value, size: 20});
    if (res.success && res.items) {
      state.current = false;
      setItems(res.items);
    }
    state.current = false;
  };
  const onChange = (event: any, {newValue}: any) => {
    setSearch(newValue);
  };

  const onSuggestionsFetchRequested = async ({value}: any) => {
    await loadSuggestions(value);
  };
  const onSuggestionsClearRequested = () => {
    setItems([]);
  };

  const getSuggestionValue = (suggestion: any) => {
    return suggestion.name ?? "";
  };

  const onSuggestionSelected = async (
    event: any,
    {suggestion}: { suggestion: Account },
  ) => {

    await router.push(`/home/accounts/detail/${suggestion._KEY}`);
  };

  return (
    <div className="position-relative">
      {loading && <FontAwesomeIcon
        style={{
          position: "absolute",
          right: 50,
          zIndex: 10000,
          top: 12
        }}
        icon={faSpinner} spin/>}
      <AutoSuggest
        suggestions={items}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={(suggestion: Account) => (
          <span>
            <b>{capitalize(`${capitalize(suggestion.company.company_name)}`)}</b><br/>
            <small>{capitalize(`${suggestion.contact?.first_name ?? ""} ${suggestion.contact?.last_name ?? ""}`)}</small><br/>
            {suggestion.company.account_id && <small className="text-dark">{`${config?.sbx_crm?.accounts?.id_label ?? "Id"}: ${suggestion.company.account_id}`}</small>}
        </span>
        )}
        inputProps={{
          placeholder: t("search"),
          value: search,
          onChange: onChange,
          className: `search-input`,
        }}
      />
    </div>
  )
}


export default AutoSuggestAccounts;
