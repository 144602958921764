import Form from "../../types/FormType";
import IField from "../../types/FormBuilder/IField";
import DataList from "../../types/FormBuilder/DataList";

export const formTypeSlice = {
  GET_FORMS: "form/getForms",
  GET_FORM_BY_ID: "form/getFormById",
  DELETE_FORM_BY_ID: "form/deleteFormById",
  UPSERT_FORM: "form/upsertForm",
  UPSERT_FIELD: "form/upsertField",
  DELETE_FIELD_BY_ID: "form/deleteFieldById",
  GET_LIST_PROVIDERS: "form/getListProver",
  UPDATE_FIELDS: "form/updateFields",
  CLONE_FORM: "form/cloneForm"
}


export enum FormStates {
  FORMS_PENDING = "FORMS_PENDING",
  FORMS_REJECTED = "FORMS_REJECTED",
  FORMS_RESOLVED = "FORMS_RESOLVED",
  FORM_DELETED = "FORM_DELETED",
  FORM_PENDING = "FORM_PENDING",
  FORM_RESOLVED = "FORM_RESOLVED",
  FORM_REJECTED = "FORM_REJECTED",
  FIELD_PENDING = "FIELD_PENDING",
  FIELD_REJECTED = "FIELD_REJECTED",
  FIELD_RESOLVED = "FIELD_RESOLVED",
  FIELD_DELETED = "FIELD_DELETED",
  LIST_PROVIDER_PENDING = "LIST_PROVIDER_PENDING",
  LIST_PROVIDER_RESOLVED = "LIST_PROVIDER_RESOLVED",
  LIST_PROVIDER_REJECTED = "LIST_PROVIDER_REJECTED",
  CLONING_FORM = "CLONING_FORM",
  FORM_CLONED = "FORM_CLONED",
  CLONE_FAILED = "CLONE_FAILED",
  IDLE = "IDLE"
}

export interface FormState {
  forms: Form[];
  currentForm: Form,
  state: FormStates,
  currentField?: IField | null;
  parentField?: IField
  fields: IField[];
  parentFormId: number | string | null;
  listProviders: DataList[]
}
