import React from 'react';
import MultiFilesInputComponent from "../../TaskComponent/FormRenderInput/MultiFilesInputComponent";
import {Field} from "../../../types/Field";
import {PropsValueFile} from "./SingleFileComponent";

interface IProps {
  field: Field;
  getFiles: () => any;
  onChange: (val: PropsValueFile[]) => void
}

const MultiFileComponent: React.FC<IProps> = (props: IProps) => {

  return (
    <MultiFilesInputComponent field={props.field} getFiles={props.getFiles} setValueField={props.onChange}/>
  )
};

export default MultiFileComponent;
