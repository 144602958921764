import React, {Fragment, useState} from 'react';
import {Menu} from './types/SideBar';
import MenuItemsComponent from './MenuItemsComponent';
import {useSelector} from 'react-redux';
import {authReducer} from '../../store/Selectors';

interface IProps {
  menu: Menu[];
  sidebarOpen: boolean
}

const SideBar = ({menu, sidebarOpen}: IProps) => {
  const {user} = useSelector(authReducer)
  const [subMenu, setSubMenu] = useState('');
  const [menuActive, setMenuActive] = useState('');
  
  const version = (process.env.NEXT_PUBLIC_APP_VERSION || user?.config?.sbx_crm?.crm_version)
  
  React.useEffect(() => {
    setMenuActive('');
  }, [sidebarOpen]);
  
  const menuContainer = (menus: Menu[]) => {
    return menus.map((m) => (
        <Fragment key={m.label}>
          <li className="app-sidebar__heading">{m.label}</li>
          {m.items.map(item => <MenuItemsComponent
            setSubMenu={setSubMenu}
            subMenu={subMenu}
            key={item.label}
            menuActive={menuActive}
            setMenuActive={setMenuActive}
            menu={item}/>)}
        </Fragment>
      )
    );
  };
  
  return (
    <div className="app-sidebar sidebar-shadow">
      <div className="app-header__logo">
        <div className="logo-src"/>
        <div className="header__pane ms-auto">
          <div>
            <button
              type="button"
              className="hamburger close-sidebar-btn hamburger--elastic"
              data-class="closed-sidebar">
              <span className="hamburger-box">
                <span className="hamburger-inner"/>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button
            type="button"
            className="hamburger hamburger--elastic mobile-toggle-nav">
            <span className="hamburger-box">
              <span className="hamburger-inner"/>
            </span>
          </button>
        </div>
      </div>
      <div className="app-header__menu">
        <span>
          <button
            type="button"
            className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
            <span className="btn-icon-wrapper">
              <i className="fa fa-ellipsis-v fa-w-6"/>
            </span>
          </button>
        </span>
      </div>
      <div className="scrollbar-sidebar d-flex flex-column justify-content-between">
        <div className="app-sidebar__inner">
          <ul className="vertical-nav-menu">
            {menuContainer(menu)}
          </ul>
        </div>
        
        {version && <span className="text-center text-gray">v{version}</span>}
      </div>
    </div>
  
  );
};

export default SideBar;
