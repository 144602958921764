import {useSelector} from "react-redux";
import {RootState} from "../../store/Reducers";
import {FormStates} from "../../store/Form/Types";
import SpinnerComponent from "../Shared/SpinnerComponent";
import React from "react";
import CreateFieldPreview from "../Shared/Modal/CreateFieldModal/CreateFieldPreview";
import {FieldType} from "../../types/FieldType";

const FormPreview = () => {

  const {currentForm: {fields}, state} = useSelector((state: RootState) => state.FormReducer);
  const loading = state === FormStates.FORM_PENDING;

  return (
    <div className="card">
      <div className="card-body">
        {loading && (
          <div className="d-flex justify-content-center align-items-center vh-50">
            <SpinnerComponent/>
          </div>
        )}
        {!fields.length && !loading && <div className="d-flex justify-content-center"><h5>No fields</h5></div>}
        <div className="d-flex flex-column">
          <div className="task-detail-form mt-2 mb-2 mb-lg-0">
            {fields.map((field, i) => {
              return (
                <div key={i} className={field.field_type === FieldType.LABEL ? "grid-full-column" : ""}>
                  <CreateFieldPreview getOptions field={field} valueNewRow={null}/>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}


export default FormPreview;
