import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {FormStates, formTypeSlice} from './Types';
import {
  addProviderDataToFields,
  cloneForm,
  deleteFieldId,
  deleteFormById,
  getAllListProvider,
  getFormById,
  getFormList,
  saveField,
  saveForm,
  updateField,
  updateFields,
  updateFormById
} from '../../services/backend/FormService';
import {actionsForm} from './Slice';
import {PayloadAction} from '@reduxjs/toolkit';
import IField from '../../types/FormBuilder/IField';
import {setNameToField, toast} from '../../utils';
import {RootState} from '../Reducers';
import Router from 'next/router';
import Form from '../../types/FormType';
import {actionsModal, ModalTypes} from '../Modal/Slice';
import {Response} from '../../types/Response';
import {TrackEventMonitor} from '../../classes/TrackEvent';

function error(message: string="") {
  toast({type: "error", message})
}

function* getFormsSaga() {
  try {
    const res: Response = yield getFormList();
    if (res.success && res.items) {
      yield put(actionsForm.setForms(res.items));
    } else {
      throw new Error();
    }
  } catch (e: any) {
    error();
    yield put(actionsForm.changeState(FormStates.FORMS_REJECTED));
  }
}

function* upsertFormSaga({payload}: PayloadAction<{ id?: number, name: string }>) {
  try {
    let res: Response;
    if (payload.id) {
      res = yield updateFormById(payload.id, payload.name);
    } else {
      res = yield saveForm({name: payload.name});
      if (res.success && res.items) {
        Router.push("/admin/form-manager/form/" + res.item.id);
      }
    }
    if (res.success && res.item) {
      TrackEventMonitor.newEvent({
        props: {
          form_name: payload.name,
          form_id: res.item.id,
          form_label: res.item.label
        },
        metadata: {
          name: payload.id ? "sbx_crm_update_form@sbx" : "sbx_crm_create_form@sbx"
        }
      })
      yield put(actionsForm.changeState((FormStates.FORM_RESOLVED)));
      yield put(actionsForm.getForms());
    } else {
      throw new Error();
    }
  } catch (e: any) {
    error();
    yield put(actionsForm.changeState(FormStates.FORM_REJECTED));
  }
}

function* cloneFormSaga({payload}: PayloadAction<Form>) {
  try {
    const res: Response = yield cloneForm(payload);
    if (res.success && res.item) {
      TrackEventMonitor.newEvent({
        props: {
          form_name: payload.name,
          form_original_id: payload.id,
          form_clone_id: res.item.id,
          form_label: res.item.label
        },
        metadata: {
          name: "sbx_crm_clone_form@sbx"
        }
      })
      yield put(actionsModal.closeModal({type: ModalTypes.CONFIRM}))
      Router.push("/forms/form-list/form/" + res.item.id);
    } else {
      throw new Error();
    }
  } catch (e: any) {
    error();
    yield put(actionsForm.changeState(FormStates.CLONE_FAILED));
  }
}

function* getFormByIdSaga({payload}: PayloadAction<number>) {
  try {
    const res: Response = yield getFormById(payload);
    if (res.success && res.item) {
      let updateRes: Response<Form> = yield addProviderDataToFields(res.item);
      if (updateRes.success && updateRes.item) {
        yield put(actionsForm.setCurrentForm(updateRes.item));
      }
    } else {
      throw new Error();
    }
  } catch (e: any) {
    error();
    yield put(actionsForm.changeState(FormStates.FORM_REJECTED));
  }
}

function* deleteFormByIdSaga({payload}: PayloadAction<number>) {
  try {
    const res: Response = yield deleteFormById(payload);
    if (res.success && res.items) {
      TrackEventMonitor.newEvent({
        props: {
          form_id: payload,
        },
        metadata: {
          name: "sbx_crm_delete_form@sbx"
        }
      })
      yield put(actionsForm.changeState(FormStates.FORM_DELETED));
      yield put(actionsForm.setForms(res.items));
    } else {
      throw new Error();
    }
  } catch (e: any) {
    error();
    yield put(actionsForm.changeState(FormStates.FORM_REJECTED));
  }
}

function* upsertFieldSaga({payload}: PayloadAction<{ field: IField, formId: number }>) {
  try {
    let res: Response;
    if (payload.field.id) {
      res = yield updateField(payload.formId, payload.field);
    } else {
      res = yield saveField(payload.formId, payload.field);
      let fieldCreated = res.item.fields.find((f: IField) => payload.field.name === f.name);
      if (fieldCreated) {
        fieldCreated = setNameToField(fieldCreated);
        yield call(updateField, payload.formId, fieldCreated);
      }
    }
    if (res.success && res.item) {
      yield put(actionsForm.changeState(FormStates.FIELD_RESOLVED));
      yield put(actionsForm.getFormById(payload.formId));
      yield put(actionsForm.setCurrentField(null));
      toast({type: "success", message: ""})
    } else {
      throw new Error();
    }
  } catch (e: any) {
    error();
    yield put(actionsForm.changeState(FormStates.FIELD_REJECTED));
  }
}

function* addFieldByiDSaga({payload}: PayloadAction<{ fieldId: number, formId: number }>) {
  try {
    const res: Response = yield deleteFieldId(payload.fieldId, payload.formId);
    if (res.success && res.item) {
      yield put(actionsForm.changeState(FormStates.FIELD_DELETED));
      yield put(actionsForm.setCurrentForm(res.item));
      toast({type: "success", message: ""});
    } else {
      throw new Error();
    }
  } catch (e: any) {
    error();
    yield put(actionsForm.changeState(FormStates.FIELD_REJECTED));
  }
}

function* getListProvidersSaga() {
  try {
    const res: Response = yield getAllListProvider();
    if (res.success && res.items) {
      yield put(actionsForm.setListProvider(res.items));
      toast({type: "success", message: ""});
    } else {
      throw new Error();
    }
  } catch (e: any) {
    error();
    yield put(actionsForm.changeState(FormStates.LIST_PROVIDER_REJECTED));
  }
}

function* updateFieldsSaga() {
  try {

    const fields: IField[] = yield select((state: RootState) => {
      return state.FormReducer.currentForm.fields;
    });
    const res: Response = yield call(updateFields, fields);
    if (res.success) {
      toast({type: "success", message: ""});
    } else {
      throw new Error();
    }
  } catch (e: any) {
    console.log(e.error)
    error();
  }
}


export default function* allSagas() {
  yield all([
    takeEvery(formTypeSlice.GET_FORMS, getFormsSaga),
    takeEvery(formTypeSlice.GET_FORM_BY_ID, getFormByIdSaga),
    takeEvery(formTypeSlice.DELETE_FORM_BY_ID, deleteFormByIdSaga),
    takeEvery(formTypeSlice.UPSERT_FORM, upsertFormSaga),
    takeEvery(formTypeSlice.UPSERT_FIELD, upsertFieldSaga),
    takeEvery(formTypeSlice.DELETE_FIELD_BY_ID, addFieldByiDSaga),
    takeEvery(formTypeSlice.GET_LIST_PROVIDERS, getListProvidersSaga),
    takeEvery(formTypeSlice.UPDATE_FIELDS, updateFieldsSaga),
    takeEvery(formTypeSlice.CLONE_FORM, cloneFormSaga),
  ])
}
