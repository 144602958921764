import {DataProvider} from "../../types/DataProvider";

export const dataProviderTypeSlice = {
  GET_DATA_PROVIDERS: "dataProvider/getDataProviders",
  GET_DATA_PROVIDER: "dataProvider/getDataProviderByIdAndType",
  ADD_DATA_PROVIDER: "dataProvider/addDataProvider",
  UPDATE_DATA_PROVIDER: "dataProvider/updateDataProvider",
  ADD_OPTION_TO_PROVIDER: "dataProvider/addOptionToProvider",
  DELETE_OPTION_TO_PROVIDER: "dataProvider/deleteOptionFromDataProviderById",
  DELETE_DATA_PROVIDER: "dataProvider/deleteDataProvider",
}


export enum DataProviderStates {
  IDLE = "IDLE",
  DATA_PENDING = "DATA_PENDING",
  DATA_RESOLVED = "DATA_RESOLVED",
  DATA_REJECTED = "DATA_REJECTED",
  OPTION_PENDING = "OPTION_PENDING",
  OPTION_RESOLVED = "OPTION_RESOLVED",
  OPTION_REJECTED = "OPTION_REJECTED",
  OPTION_DELETED = "OPTION_DELETED",
  PROVIDER_PENDING = "PROVIDER_PENDING",
  PROVIDER_RESOLVED = "PROVIDER_RESOLVED",
  PROVIDER_REJECTED = "PROVIDER_REJECTED",
  PROVIDER_DELETED = "PROVIDER_DELETED",
}

export interface DataProviderState {
  dataProviders: DataProvider[];
  state: DataProviderStates;
  dataProvider?: DataProvider;
}
