import {get, post} from '../network';
import Axios from "axios";
import {downloadFileUtil} from "../utils";

export const getParseToken = (token: string) => {
  return !token?.toLowerCase().startsWith("bearer") ? `Bearer ${token}` : token;
}

export function getFileData(key: string) {
  return get(`/api/content/v1/folder${key ? "/"+key : ""}`);
}

export function getFile(key: string) {
  return get(`/api/content/v1/download/${key}`).then(res => ({...res, file_key: key}));
}

export function deleteSbxFileService(key: string) {
  return post(`/api/content/v1/delete/${key}`);
}
// /api/content/v1/delete?key=b9bc5bf3-a7c8-4e59-905e-7cda72224630
export function downloadFileService(url: string, name: string) {
  return Axios.get(url, {responseType: "blob"}).then(file => downloadFileUtil(file.data, name));
}
