import UAParser from 'ua-parser-js';
import publicIp from 'public-ip';
import {createEventService} from '../services/backend/AnalyticsService';



type AppData = { name: string; module: string };

class MetadataTrackCode {
  constructor(
    public app: AppData,
    public ip?: string,
    public user_agent?: string,
    public user_agent_info?: string
  ) {}
}

export type EventMeta = { name?: string, extra?: { trackCode: MetadataTrackCode, pathname?: string, }, user?: number, created?: Date };

export class TrackEvent {
  public props: any;
  public meta: EventMeta = {};
  public extra: any;

  constructor(app: AppData) {
    this.meta = {...this.meta, extra: {trackCode: {app}}};
  }

  public newEvent({props, extra, metadata}: { props: any, extra?: any, metadata: { name: string } }) {
    this.props = props;
    this.extra = extra ?? {};
    this.meta.name = metadata.name;
    if (this.meta.extra) {
      this.meta.extra.pathname = window.location.href;
    }
    this.meta.created = new Date();
    this.publish();
  }

  public publish() {
    createEventService({
      props: this.props,
      extra: this.extra,
      meta: this.meta
    }).then(res => res);
  }

  public setUserAgent(data: any) {
    if (this.meta.extra?.trackCode) {
      this.meta.extra.trackCode.user_agent = data;
    }
  }

  public setUserAgentInfo(data: any) {
    if (this.meta.extra?.trackCode) {
      this.meta.extra.trackCode.user_agent_info = data;
    }
  }

  public setUser({user_id}: { user_id: number }) {
    this.meta.user = user_id;
  }

  public setMetadataExtra(data: any) {
    if (this.meta.extra) {
      this.meta.extra = {...this.meta.extra, ...data};
    }
  }

  public setUserIp(data: string) {
    if (this.meta.extra?.trackCode) {
      this.meta.extra.trackCode.ip = data;
    }
  }
}

export const TrackEventMonitor = new TrackEvent({ name: 'crm_web', module: 'sbx-crm' });

(async () => {
  try {
    TrackEventMonitor.setUserIp(await publicIp.v4());
  } catch (e) {
    TrackEventMonitor.setUserIp('');
  }
})();

const parser = new UAParser();
const agent = {
  browser: parser.getBrowser(),
  device: parser.getDevice(),
  os: parser.getOS(),
  engine: parser.getEngine()
};

TrackEventMonitor.setUserAgent(parser.getUA());
TrackEventMonitor.setUserAgentInfo(agent);
