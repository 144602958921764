import React, {useReducer} from 'react';
import {useSelector} from 'react-redux';
import {authReducer} from '../../store/Selectors';
import {State} from '../../types/State';
import {SbxCrmDataColumn, SbxCrmDataInfo} from '../../types/User';
import SelectComponent from '../Shared/FieldComponents/SelectComponent';
import {capitalize, getCompoundName, getObjValueInDeep} from '../../utils';
import {TabsNames} from "../Layouts/AdminLayout/SideBarContent";

type Props = {
  model: string;
  modelData: any[];
  process?: SbxCrmDataInfo
  handleData: (data: any[]) => void;
  configName: TabsNames;
}

enum Types {
  SET_STATE,
  SET_MULTI_STATE,
  SET_FILTER
}

interface InitialReducerState {
  isLoading: State
  dataColumns: { [key: string]: string }
  dataFilters: SbxCrmDataColumn[]
  filterOptions: { [key: string]: { label: string, value: string }[] }
  filters: { [key: string]: string }
  filterDependencies: { [key: string]: string[] }
}

const initialState: InitialReducerState = {
  isLoading: State.IDLE,
  dataColumns: {},
  dataFilters: [],
  filterOptions: {},
  filters: {},
  filterDependencies: {}
};


function reducer(state: InitialReducerState, {
  type,
  payload
}: { type: Types, payload: { name: string, value?: any } | { name: string, value: any }[] }) {
  switch (type) {
    case Types.SET_STATE:

      return {
        ...state,
        [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
      };
    case Types.SET_MULTI_STATE:
      (payload as { name: keyof InitialReducerState, value: any }[]).forEach(data => {
        state[data.name] = data.value;
      });
      return {...state};
    case Types.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
        }
      };
    default:
      throw new Error();
  }
}

const DataFilterComponent = ({model, modelData, handleData, process, configName}: Props) => {

  const {user} = useSelector(authReducer);
  const [localState, dispatchLocal] = useReducer(reducer, initialState);

  const dispatchForm = ({name, value}: { name: keyof InitialReducerState, value: any }) => {
    dispatchLocal({type: Types.SET_STATE, payload: {value, name}});
  };

  const dispatchFilter = ({name, value}: { name: string, value: any }) => {
    dispatchLocal({type: Types.SET_FILTER, payload: {value, name}});
  };

  const dispatchMultiForm = (forms: { name: keyof InitialReducerState, value: any }[]) => {
    dispatchLocal({type: Types.SET_MULTI_STATE, payload: forms});
  };

  React.useEffect(() => {
    if (user?.config?.sbx_crm?.data[model]?.columns || process) {
      const filterColumns = process?.columns?.filter(column => column.filter) ?? user?.config?.sbx_crm[configName][model].columns.filter(column => column.filter);

      dispatchMultiForm([
        {name: 'dataFilters', value: filterColumns}
      ]);
    }
  }, [user.config, process]);

  React.useEffect(() => {
    if (modelData.length > 0 && localState.dataFilters.length > 0) {


      const {objOptions, objDependencies} = handleArrayFilterOptions();

      if (Object.keys(localState.filters).length > 0 && Object.keys(objDependencies).length > 0) {
        handleFilterOptions({
          options: objOptions,
          dataFilters: localState.dataFilters,
          filters: localState.filters,
          dependencies: objDependencies
        });
      }

      let newFormState: { value: any, name: keyof InitialReducerState }[] = [
        {
          name: 'filterDependencies',
          value: objDependencies
        }
      ]

      if (Object.keys(objDependencies).length === 0) {
        newFormState = [...newFormState, {name: "filterOptions", value: objOptions}]
      }

      // dispatchForm({name: '', value: obj});
      dispatchMultiForm(newFormState);
    }
  }, [localState.dataFilters, modelData]);

  const handleArrayFilterOptions = (clear = false) => {
    const objDependencies: { [filter: string]: string[] } = {};
    const objOptions: { [filter: string]: { label: string, value: string }[] } = {};
    localState.dataFilters.forEach(filter => {
      modelData.forEach(item => {
        if (item[filter.column]) {
          if (!objOptions[filter.column]) {
            objOptions[filter.column] = [];
          }

          if (filter.type === 'reference') {

            let label = ""

            if(filter.column_reference.compound_name){
              label = getCompoundName({item: item[filter.column], custom_compound_name: filter.column_reference.compound_name})
            }else{
              label = getObjValueInDeep(item[filter.column], filter.column_reference.column);
            }
            const value = item[filter.column]._KEY;
            if (label && value) {
              if (!objOptions[filter.column].some(objItem => objItem.value === value)) {
                objOptions[filter.column].push({label, value});
              }
            }
          } else {
            if (!objOptions[filter.column].some(objItem => objItem.value === item[filter.column])) {
              objOptions[filter.column].push({label: capitalize(item[filter.column]), value: item[filter.column]});
            }
          }
        }
      });

      if (objOptions[filter.column]?.length > 0) {
        objOptions[filter.column] = objOptions[filter.column].sort((a, b) => a?.label?.localeCompare(b?.label ?? ''));


        if (!clear) {
          if (filter?.filter?.dependencies) {
            objDependencies[filter.column] = filter.filter.dependencies;
          } else {
            if (filter.filter?.default) {
              dispatchFilter({
                name: filter.column,
                value: objOptions[filter.column][0].value
              });
            }
          }
        }

      }
    });

    return {objOptions, objDependencies};
  };

  const handleFilterOptions = (
    {
      options,
      dependencies,
      dataFilters,
      filters,
    }: {
      filters: { [key: string]: string },
      options: { [filter: string]: { label: string, value: string }[] },
      dependencies: { [filter: string]: string[] },
      dataFilters: SbxCrmDataColumn[],
    }) => {


    if (Object.keys(options).length > 0 && Object.keys(dependencies).length > 0) {
      Object.keys(filters).forEach(key => {
        Object.keys(dependencies).forEach(filter => {
          if (dependencies[filter].includes(key)) {
            const columnFilter = dataFilters.find(dataFilter => dataFilter.column === filter);
            if (columnFilter) {
              let newOptions = modelData.filter(data => data[key] ? data[key].hasOwnProperty('_KEY') ?
                data[key]['_KEY'] === filters[key]
                : data[key] === filters[key] : false);
              newOptions = newOptions.reduce((arr, item) => {
                if (columnFilter.type === 'reference') {
                  const label = getObjValueInDeep(item[columnFilter.column], columnFilter.column_reference.column);
                  if (item[columnFilter.column] && item[columnFilter.column]._KEY) {
                    const value = item[columnFilter.column]._KEY;
                    if (label && value) {
                      arr.push({label, value});
                    }
                  }

                } else {
                  arr.push({label: capitalize(item[columnFilter.column]), value: item[columnFilter.column]});
                }

                return arr;
              }, []).sort((a: { label: string }, b: { label: string; }) => (a ?? '').label.localeCompare(b.label));

              dispatchFilter({
                name: columnFilter.column,
                value: newOptions[0].value
              });

              dispatchForm({
                name: 'filterOptions',
                value: {...options, [columnFilter.column]: newOptions}
              });
            }
          }
        });
      });
    }
  };

  const cleanFilters = () => {
    const {objOptions} = handleArrayFilterOptions(true);
    dispatchMultiForm([
      {name: 'filterOptions', value: objOptions},
      {name: 'filters', value: {}}
    ]);
  };

  React.useEffect(() => {
    if (Object.keys(localState.filters).length > 0) {
      const newData = modelData.reduce((arr, item) => {
        const isFilterItem = Object.keys(localState.filters).every(filter => {

          if (typeof item[filter] === 'object' && item[filter] && item[filter]['_KEY']) {
            return localState.filters[filter] === item[filter]['_KEY'];
          } else {
            return localState.filters[filter] === item[filter];
          }
        });
        if (isFilterItem) {
          arr.push(item);
        }
        return arr;
      }, []);
      handleData(newData);
    } else {
      handleData(modelData);
    }
  }, [localState.filters, modelData]);

  return (

    <>
      {localState.dataFilters.length > 0 &&
        <div className="d-flex flex-column my-3">
          {Object.keys(localState.filters).length > 0 && <div className="text-end px-3">
            <u className="pointer text-primary" onClick={cleanFilters}>limpiar</u>
          </div>}


          <div className="d-flex align-items-center flex-wrap gap-2">
            {localState.dataFilters.length > 0 && localState.dataFilters.map(filter => {

              let value = null;
              if (localState.filters[filter.column] && localState.filterOptions[filter.column]) {
                value = {
                  label: localState.filterOptions[filter.column].find((item) => item.value === localState.filters[filter.column])?.label ?? '',
                  value: localState.filters[filter.column]
                };
              }


              return <div className="col-12 col-md-4" key={filter.column}>
                <SelectComponent id={filter.column} name={filter.column} value={value}
                                 onChange={evt => {
                                   dispatchFilter({
                                     name: filter.column,
                                     value: evt.value
                                   });

                                   handleFilterOptions({
                                     options: localState.filterOptions,
                                     dataFilters: localState.dataFilters,
                                     dependencies: localState.filterDependencies,
                                     filters: {...localState.filters, [filter.column]: evt.value}
                                   });
                                 }}
                                 placeholder={`${filter.label}...`}
                                 options={localState.filterOptions[filter.column] ?? []}/>
              </div>;

            })}

          </div>
        </div>
      }
    </>
  );
}

export default DataFilterComponent;
