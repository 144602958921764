import React, {CSSProperties} from 'react';

interface IProps {
  id: string,
  name: string,
  value: string,
  invalid?: boolean,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  style?: CSSProperties,
  placeholder?: string,
  className?: string,
  onChange?: (value: string) => void
}

const PasswordComponent = (props: IProps) => {

  const {
    id,
    name,
    value,
    required,
    onChange,
    className,
    style,
    invalid,
    placeholder,
    disabled
  } = props;

  return <input id={id}
                required={required}
                disabled={disabled}
                name={name}
                value={value}
                type="password"
                style={style}
                className={`form-control ${className || ''} ${invalid ? 'invalid' : ''}`}
                placeholder={placeholder}
                onChange={e => onChange ? onChange(e.target.value) : null}/>;
};

export default PasswordComponent;
