export enum FieldType {
    LABEL= "LABEL",
    SMALL_TEXT = "SMALL_TEXT",
    LARGE_TEXT = "LARGE_TEXT",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    OPTIONS = "OPTIONS",
    DATE_RANGE = "DATE_RANGE",
    LIST = "LIST",
    TIME = "TIME",
    FILE = "FILE",
    IMAGE = "IMAGE",
    TABLE = "TABLE",
    FORM_GROUP = "FORM_GROUP"
}


export enum SubType {
    EMAIL= "EMAIL",
    PHONE ="PHONE",
    SSN ="SSN",
    ZIPCODE = "ZIPCODE",
    BUTTON = "BUTTON",
    RADIO_BUTTON = "RADIO_BUTTON",
    TOGGLE = "TOGGLE",
    SELECT = "SELECT",
    USER = "USER",
    DOCUMENT = "DOCUMENT",
    NUMBER = "NUMBER",
    PASSWORD = "PASSWORD",
    TIME = "TIME",
    CURRENCY = "CURRENCY",
    LIST = "LIST"
}
