export enum Permissions {
  NO_VALIDATE = "NO_VALIDATE",
  FORM_BUILDER = "FORM_BUILDER",
  FORM_BUILDER_READ = "FORM_BUILDER_READ",
  FORM_BUILDER_EDIT = "FORM_BUILDER_EDIT",
  FORM_BUILDER_CREATE = "FORM_BUILDER_CREATE",
  FORM_BUILDER_DELETE = "FORM_BUILDER_DELETE",
  FORM_BUILDER_FIELD = "FORM_BUILDER_FIELD",
  FORM_BUILDER_FIELD_READ = "FORM_BUILDER_FIELD_READ",
  FORM_BUILDER_FIELD_EDIT = "FORM_BUILDER_FIELD_EDIT",
  FORM_BUILDER_FIELD_CREATE = "FORM_BUILDER_FIELD_CREATE",
  FORM_BUILDER_FIELD_DELETE = "FORM_BUILDER_FIELD_DELETE",
  PROCESS_MODEL = "PROCESS_MODEL",
  PROCESS_MODEL_READ = "PROCESS_MODEL_READ",
  PROCESS_MODEL_EDIT = "PROCESS_MODEL_EDIT",
  PROCESS_MODEL_CREATE = "PROCESS_MODEL_CREATE",
  PROCESS_MODEL_DELETE = "PROCESS_MODEL_DELETE",
  PROCESS_MODEL_EXECUTE = "PROCESS_MODEL_EXECUTE",
  EMAIL_TEMPLATE = "EMAIL_TEMPLATE",
  EMAIL_TEMPLATE_CREATE = "EMAIL_TEMPLATE_CREATE",
  EMAIL_TEMPLATE_READ = "EMAIL_TEMPLATE_READ",
  EMAIL_TEMPLATE_EDIT = "EMAIL_TEMPLATE_EDIT",
  EMAIL_TEMPLATE_DELETE = "EMAIL_TEMPLATE_DELETE",
  PERMISSION_MANAGER = "PERMISSION_MANAGER",
  PERMISSION_MANAGER_READ = "PERMISSION_MANAGER_READ",
  PERMISSION_MANAGER_CREATE = "PERMISSION_MANAGER_CREATE",
  PERMISSION_MANAGER_DELETE = "PERMISSION_MANAGER_DELETE",
  PERMISSION_MANAGER_EDIT = "PERMISSION_MANAGER_EDIT",
  ACCOUNT = "ACCOUNT",
  ACCOUNT_READ = "ACCOUNT_READ",
  ACCOUNT_CREATE = "ACCOUNT_CREATE",
  ACCOUNT_DELETE = "ACCOUNT_DELETE",
  ACCOUNT_EDIT = "ACCOUNT_EDIT",
  TASKS = "TASKS",
  TASKS_CREATE = "TASKS_CREATE",
  PERMISSION_ASSIGNMENT = "PERMISSION_ASSIGNMENT",
  PERMISSION_ASSIGNMENT_READ = "PERMISSION_ASSIGNMENT_READ",
  PERMISSION_ASSIGNMENT_CREATE = "PERMISSION_ASSIGNMENT_CREATE",
  PERMISSION_ASSIGNMENT_DELETE = "PERMISSION_ASSIGNMENT_DELETE",
  PERMISSION_ASSIGNMENT_EDIT = "PERMISSION_ASSIGNMENT_EDIT",
  SALES_GOAL = "SALES_GOAL",
  SALES_GOAL_EDIT = "SALES_GOAL_EDIT",
  SALES_GOAL_READ = "SALES_GOAL_READ",
  CALENDAR = "CALENDAR",
  CALENDAR_READ = "CALENDAR_READ",
  CALENDAR_CREATE = "CALENDAR_CREATE",
  CALENDAR_DELETE = "CALENDAR_DELETE",
  CALENDAR_EDIT = "CALENDAR_EDIT",
  GROUP = "GROUP",
  USER = "USER",
  GROUP_DELETE = "GROUP_DELETE",
  GROUP_CREATE = "GROUP_CREATE",
  GROUP_EDIT = "GROUP_EDIT",
  GROUP_READ = "GROUP_READ",
  USER_DELETE = "USER_DELETE",
  USER_CREATE = "USER_CREATE",
  USER_EDIT = "USER_EDIT",
  USER_READ = "USER_READ",
  REPORT = "REPORT",
  REPORT_CREATE = "REPORT_CREATE",
  REPORT_EDIT = "REPORT_EDIT",
  REPORT_DELETE = "REPORT_DELETE",
  REPORT_EXECUTE = "REPORT_EXECUTE",
  REPORT_READ = "REPORT_READ",
  DATA_PROVIDER = "DATA_PROVIDER",
  DATA_PROVIDER_CREATE = "DATA_PROVIDER_CREATE",
  DATA_PROVIDER_EDIT = "DATA_PROVIDER_EDIT",
  DATA_PROVIDER_DELETE = "DATA_PROVIDER_DELETE",
  DATA_PROVIDER_EXECUTE = "DATA_PROVIDER_EXECUTE",
  DATA_PROVIDER_READ = "DATA_PROVIDER_READ",
  DATA_MANAGER = "DATA_MANAGER",
  DATA_MANAGER_CREATE = "DATA_MANAGER_CREATE",
  DATA_MANAGER_EDIT = "DATA_MANAGER_EDIT",
  DATA_MANAGER_DELETE = "DATA_MANAGER_DELETE",
  DATA_MANAGER_EXECUTE = "DATA_MANAGER_EXECUTE",
  DATA_MANAGER_READ = "DATA_MANAGER_READ",
  APP_CONFIG = "APP_CONFIG",
  APP_CONFIG_READ = "APP_CONFIG_READ",
  APP_CONFIG_CREATE = "APP_CONFIG_CREATE",
  APP_CONFIG_DELETE = "APP_CONFIG_DELETE",
  APP_CONFIG_EDIT = "APP_CONFIG_EDIT",
}

export interface PermissionsData {
  id?: number;
  permission: string;
  users?: any;
  module_name: string;
  metadata?: string[];
}

export enum PermissionType {
  READ = "READ",
  CREATE = "CREATE",
  DELETE = "DELETE",
  EDIT = "EDIT",
  EXECUTE = "EXECUTE"
}

export const permArray: PermissionType[] = Object.keys(PermissionType).map(e => {
  // @ts-ignore
  return PermissionType[e];
});


export interface ModuleType {
  module_name: string;
  permissions: PermissionsData[]
}
