

export interface ReportMetadata {
  x_axis: string;
  y_axis: string;
  split_by: string;
  total: boolean;
}

export interface Report {
  _KEY?: string
  name: string;
  filter: string;
  query: string;
  custom: boolean
  active: boolean;
  sort: string;
  parent_group:  string;
  group: boolean;
  order_group: number;
  ncolumn: number;
  columns_to_summarize: string;
  metadata: string | ReportMetadata;
  visible_for: string;
  remove_empty_columns: boolean;
  handleQuery: boolean;
  custom_column: string;
  type: 'table' | 'null' | 'barchart' | 'linechart';
  sort_list?: number;
  truncate?: string | TruncateReport

}

export interface TruncateReport {
  type: "date",
  model: string,
  field: string,
  range: number,
  format: string
}



// export type SourceFrom = "sbx-event" | "sbx-data" | "sbx-workflow" | "sbx-crm-user"
export enum SourceFrom {
  SBX_EVENT='sbx-event',
  SBX_DATA='sbx-data',
  SBX_WORKFLOW='sbx-workflow',
  SBX_CRM_USER='sbx-crm-user'
}

type merge_type = "inner" | "left" | "outer" | "right"

export interface Source {
  action?: "merge" | "get";
  from: SourceFrom;
  in?: number;
  merge_type: merge_type;
  with: string;
  override_auth?: string;
  index_column?: string;
  main_column?: string;
  pagination?: boolean;
  sources?: Source[];
  filters?: SourceFilter[];
  fetch?: string[];
  temporal_id?: string;
}

export type FilterOperator = "=" | "<" | ">" | 'is null' | 'is not null' | '>=' | "<=" | string
export const filterMathOperator = ["=", "<", ">", '+', '*', '>=', "<=", "-", "/"]

export interface SourceFilter {
  logic_operator: "and" | "or";
  filter_operator: FilterOperator;
  field: string;
  value: string | number | null;
}

// export enum ActionFilterOperator  = FilterOperator | '!=' | '=='
export enum ActionFilterOperator {
  EQUAL_TO = '==',
  DIFFERENT_OF = '!=',
  GREATER_THAN = '>',
  GREATER_OR_EQUAL_THAN = '>=',
  SMALLER_OR_EQUAL_THAN = '<=',
  SMALLER_THAN = '<',
  EXIST = 'is not null',
  NOT_EXIST = 'is null',
}



export type ActionType = "group_by" | "rename" | 'select' | 'limit' | 'sort' | 'transform' | 'filter' | 'default_values' | 'merge' | 'ml'

export interface AnalyticBusinessDay {
  source: Source;
  year_column: string;
  month_column: string;
  business_day_column: string;
  agg: string;
  multiply_by: string;
  temporal_id?: string;
}

export interface AggAnalytic  {[key: string]: (string | AnalyticBusinessDay)[]}

export interface AnalyticQueryAction {
  type: ActionType;
  agg?: AggAnalytic;
  columns?: string[] | {[key: string]: any};
  renamed_columns?: {[key: string]: string};
  ascending?: boolean;
  top?: number;
  temporal_id?: string;
  dependency_action_id?: string;
  filter?: string;
  transformation?: string | number;
  name?: string;
  index?: number;
  source?: Source;
  actions?: AnalyticQueryAction[];
  main_column?: string;
  index_column?: string;
  merge_type?: merge_type
  subtype?: 'forecast' | string;
  x?: string;
  y?: string;
  seasonal?: number;
  forecast_from?: string; //string desde donde
  forecast_to?: string;  // string hasta donde
}

export interface AnalyticQuery {
  actions: AnalyticQueryAction[];
  source: Source;
  truncate?: TruncateReport
}


