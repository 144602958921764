import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import React, {useState} from "react";

interface IProps {
  headerToggle: React.ReactNode;
  color?: "link" | "primary" | "secondary" | string
  children: JSX.Element | JSX.Element[]
}

export const DropDownSimple = (props: IProps) => {

  const [isOpen, setOpen] = useState(false);

  return (
    <Dropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
      <DropdownToggle color={props.color || "link"} id="dropdown-basic">
        {props.headerToggle}
      </DropdownToggle>
      <DropdownMenu>
        {props.children}
      </DropdownMenu>
    </Dropdown>
  )
}
