import '../styles/index.scss';
import type {AppProps} from 'next/app'

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import {Provider} from "react-redux";
import store from "../store";
import AuthValidation from "../components/AuthValidation";
import ModalWrapper from "../components/Shared/Modal/ModalWrapper";
import React from "react";
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/es';
import 'react-dates/initialize';
import AdminLayoutComponent from "../components/Layouts/AdminLayout/AdminLayoutComponent";
import Head from 'next/head'
import LoadingLayout from "../components/Layouts/LoadingLayout";
import 'react-quill/dist/quill.snow.css';
import ErrorBoundary from "../components/ErrorBoundary";
import {useRouter} from "next/router";
import {START_PAGE_ON} from "./index";

function MyApp({Component, pageProps}: AppProps) {
  const {layout: Layout = AdminLayoutComponent} = Component as any;
  const router = useRouter();
  
  return (
    <Provider store={store}>
      <Head>
        <title>SBX CRM+</title>
      </Head>
      <AuthValidation>
        <ModalWrapper/>
        <Layout>
          <LoadingLayout/>
          <ErrorBoundary redirect={() => {
            if (window.location.hostname !== 'localhost'){
              router.push(START_PAGE_ON)
            }
          }}>
            <Component {...pageProps}/>
          </ErrorBoundary>
        </Layout>
      </AuthValidation>
    </Provider>
  )
}

export default MyApp;
