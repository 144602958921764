import {all, call, put, takeEvery} from "redux-saga/effects";
import {GET_BUSINESS_DAYS, GET_CONFIG, SAVE_CONFIG} from './Types';
import {actionsConfig, BusinessDay} from './Slice';
import {State} from "../../types/State";
import {toast} from "../../utils";
import {loadConfigService, saveConfigService} from "../../services/backend/configService";
import {Response} from "../../types/Response";
import {ConfigData} from "../../types/ConfigData";
import {SbxConditionType, SbxResponse} from '../../types/Sbx';
import {findAllByModel} from '../../services/backend/SbxService';
import {TrackEventMonitor} from '../../classes/TrackEvent';

function* getConfigSaga() {
  try {
    const res: Response<ConfigData> = yield call(loadConfigService);
    if (res.success && res.item) {
      yield put(actionsConfig.setConfig(res.item))
    } else throw Error;
  } catch (e) {
    toast({type: "error", message: "Failed to load config"})
    yield put(actionsConfig.changeState(State.REJECTED));
  }
}

function* saveConfigSaga({payload}: ReturnType<typeof actionsConfig.saveConfig>) {
  try {
    const res: Response<ConfigData> = yield call(saveConfigService, payload);
    if (res.success) {

      TrackEventMonitor.newEvent({
        props: {
          domain: payload.domain,
          app: payload.app,
          config: payload.config
        },
        metadata: {
          name: "sbx_crm_update_config@sbx"
        }
      })
      yield put(actionsConfig.changeState(State.RESOLVED));
    } else throw Error;
  } catch (e) {
    toast({type: "error", message: "Failed to save config"})
    yield put(actionsConfig.changeState(State.REJECTED));
  }
}

function* getBusinessDaysSaga({payload}: ReturnType<typeof actionsConfig.getBusinessDays>) {
  try {

    const res: SbxResponse<BusinessDay> = yield call(findAllByModel, {row_model: "sbx_crm_holidays", where: [
        {ANDOR: "AND", GROUP: [{ANDOR: "AND", FIELD: 'year', OP: SbxConditionType.GREATER_THAN, VAL: new Date().getFullYear() - 1 }]}
      ]});
    if (res?.success && res.items) {
      yield put(actionsConfig.setBusinessDays(res.items))
    } else {
      yield put(actionsConfig.changeState(State.REJECTED));
      throw Error;
    }
  } catch (e) {
    // toast({type: "error", message: "Failed to get business days"})
    yield put(actionsConfig.changeState(State.REJECTED));
  }
}


export default function* configSagas(): any {
  return yield all(
    [
      takeEvery(GET_CONFIG, getConfigSaga),
      takeEvery(SAVE_CONFIG, saveConfigSaga),
      takeEvery(GET_BUSINESS_DAYS, getBusinessDaysSaga),
    ]
  );
}
