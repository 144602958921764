import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import IField from "../../../types/FormBuilder/IField";
import {TaskProcess} from "../../../types/Task";
import {useState} from "react";
import {StringOption} from "../../../types/Select";

type Props = {
  setItem: (task: TaskProcess) => void
  item: any
  processDataFields: StringOption[]
  id: number
  setFocusField: (field: string) => void
  field: IField
};

const SuggestionRuleSearchComponent = ({setItem, item, processDataFields, id, field, setFocusField}: Props) => {
  const [suggestSearch, setSuggestSearch] = useState("");

  const setSuggestionValue = (field: IField, value: string, id: number) => {
    if (id) {
      const element: any = document.getElementById(id.toString())
      let taskValue = (item[field.name] ?? "")

      if (element) {
        const [start, end] = [element.selectionStart, element.selectionEnd];
        // taskValue = taskValue.split(" ").filter((_: string, index: number) => index !== taskValue.split(" ").length - 1).join(" ")
        setItem(
          {...item, [field.name]: taskValue.slice(0, start ?? 1) + ' ' + (value ?? '') + taskValue.slice(end ?? 1)}
        )
      }
    }
  }

  return (
    <div
      className="d-flex flex-column bg-white position-absolute overflow-auto shadow border"
      style={{maxHeight: '200px', maxWidth: '400px', minWidth: '300px', zIndex: 100}}>

      <div className="d-flex flex-column">
        <small className="fw-bold pointer text-end text-link mx-2" style={{textDecoration: "underline"}} onClick={() => setFocusField("")}>Cerrar</small>

        <div className="input-group my-2">
          <input type="text" value={suggestSearch} className="border-0 border-bottom border-end-0 border-start-0 form-control" onChange={event => {
            setSuggestSearch((event.currentTarget.value as string) ?? "")
          }} placeholder={"search"}/>
          <span className="bg-white border-0 border-bottom input-group-text pointer" onClick={() => setSuggestSearch("")} id="basic-addon2">
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
        </div>
      </div>

      <ul className="list-group list-group-flush">
        {processDataFields.filter(pField =>
          suggestSearch ? pField.value.toLowerCase().includes(suggestSearch.toLowerCase()) : (pField.value.toLowerCase().includes((item[field.name] ?? "").toLowerCase().replace(/[\])}[{(]/g, '').split(" ").at(-1)))).map(dataField => (
          <li key={dataField.value} onClick={() => setSuggestionValue(field, dataField.value, id)}
              className="list-group-item pointer shading">{dataField.value}</li>
        ))}
      </ul>
    </div>
  );
};

export default SuggestionRuleSearchComponent