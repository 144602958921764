import React, {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import {Switch} from 'antd';
import {State} from '../../../types/State';
import {fetchModelByReferences, findAllModels, findByModel} from '../../../services/backend/SbxService';


import {Andor, Condition, Model, ModelsResponse, SbxConditionType, SbxModelField} from '../../../types/Sbx';
import {
  capitalize,
  convertDateToDDMMMYYYY,
  convertTableRowsToCSVString,
  DEFAULT_SIZE,
  downloadTextToFile,
  IsJsonString,
  toast
} from '../../../utils';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFileCsv,
  faPlusCircle,
  faPollH,
  faSearch,
  faSpinner,
  faTimesCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import CustomTableComponent, {Column} from '../CustomTableComponent';
import useTranslate from '../../../hooks/useTranslate';
import SpinnerComponent from '../SpinnerComponent';
import LabelComponent from '../FieldComponents/LabelComponent';
import SelectComponent from '../FieldComponents/SelectComponent';
import FieldInput from './FieldInput';
import reducer, {actions, initialState, StateLocal} from './Reducer';
import {
  getColumnFormat,
  getConditions,
  getEndResult,
  getFetch,
  getOptionsByField,
  getValueConditions,
  resultMapper,
  validateQueryValues
} from './Utils';
import {plainToClass} from 'class-transformer';
import useAsyncEffect from '../../../hooks/useAsyncEffect';
import useIgnoreFirstEffect from '../../../hooks/useIgnoreFirstEffect';
import TabContents from '../TabContents';
import EditorComponent from '../EditorComponent/EditorComponent';
import {Source, SourceFrom} from '../../../types/Analytic';
import {getSchema} from '../../../services/backend/AnalyticsService';
import QueryFetchComponent from './QueryFetchComponent';

export function validateQuery(value: string, callback: (query: any) => void) {
  if (IsJsonString(value)) {
    return callback(JSON.parse(value) as any);
  }
}


export interface Query {
  where: Condition[];
  row_model: string;
  fetch?: string[];
}

interface IProps {
  getResult?: (columns: Column[], rows: any[]) => void;
  showTable?: boolean;
  getSizeColumns?: (size: number) => void;
  query?: Query;
  getQuery?: (query: Query) => void;
  dataType?: Source
  showFetch?: boolean
  setStateLoading?: (state: string) => void
}


const QueryComponent = ({
                          getResult,
                          showTable = true,
                          getSizeColumns,
                          query,
                          getQuery,
                          dataType,
                          showFetch,
                          setStateLoading
                        }: IProps) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [includeColumns, setIncludeColumn] = useState<Column[]>([]);
  const [fetchedModels, setFetchedModels] = useState<string[]>([]);
  const [defaultFetchedModels, setDefaultFetchedModels] = useState<string[]>([]);
  const [reverseFetchedModels, setReverseFetchedModels] = useState<string[]>([]);
  const [deepFetchedModels, setDeepFetchedModels] = useState<string[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [columnsTable, setColumnsTable] = useState<Column[]>([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [mapper, setMapper] = useState<any[]>([]);
  const [currentTap, setCurrentTap] = useState("0");
  const {model, rows, modelFetched, conditions, totalItems} = state;
  const {t} = useTranslate('common');
  const starting = useRef<'starting' | 'started'>('starting');


  const getReverseFetch = (data: ModelsResponse[], model_name?: string) => {
    let possibleFetch: { [key: string]: string[] } = {};
    let possibleReverseFetch: { [key: string]: string[] } = {};
    let byCode = data.reduce((obj: any, it: any) => {
      obj[it.id + ''] = it;
      return obj;
    }, {});

    data.forEach((model: ModelsResponse) => {

      model['properties'].forEach((field: Model) => {

        if (field['type'] == SbxModelField.REFERENCE) {
          if (!possibleFetch[model['name']]) {
            possibleFetch[model['name']] = [];
          }
          possibleFetch[model['name']].push(field['name']);
          if (byCode[field['reference_type'] + '']) {
            let model_name = byCode[field['reference_type'] + '']['name'];
            if (!possibleReverseFetch[model_name]) {
              possibleReverseFetch[model_name] = [];
            }
            possibleReverseFetch[model_name].push(field['name'] + '.' + model['name']);
          }
        }
      });
    });


    const row_model = model_name ?? query?.row_model ?? model?.value.name ?? ""

    setReverseFetchedModels(Object.values(possibleReverseFetch).flat().filter(fetch => row_model ? fetch.split(".")[1] === (row_model) : true))
  }

  const getModels = useCallback(async () => {
    dispatch(actions.changeState(State.PENDING));
    if (!dataType) {
      const res = await findAllModels();
      if (res.success) {
        dispatch(actions.setModels(res.items ?? []));
        dispatch(actions.changeState(State.RESOLVED));
      } else {
        dispatch(actions.changeState(State.REJECTED));
      }
      return res;
    } else {
      const res: { [key: string]: ModelsResponse[] } = await getSchema([dataType]);
      if (res) {
        if (res[dataType['from']]) {
          if (dataType['from'] === SourceFrom.SBX_DATA) {
            getReverseFetch(res[dataType['from']])
          }

          dispatch(actions.setModels(res[dataType["from"]] ?? []));
          dispatch(actions.changeState(State.RESOLVED));
        } else {
          dispatch(actions.changeState(State.REJECTED));
        }
      } else {
        dispatch(actions.changeState(State.REJECTED));
      }

    }


  }, [dispatch]);

  const getPropertiesByReferences = useCallback(async () => {
    if (model) {

      // modelId -> object to save original name of column table
      const modelId: { [id: string]: string } = {};

      const ids = model.value.properties.reduce((obj: number[], c) => {
        if (c.type === SbxModelField.REFERENCE) {
          if (c.reference_type) {
            modelId[c.reference_type] = c.name;
            obj.push(c.reference_type);
          }
        }
        return obj;
      }, []);


      dispatch(actions.changeState(StateLocal.FETCH_REFERENCES_PENDING));
      const modelFetched = await fetchModelByReferences(ids);
      if (Object.keys(modelFetched).length > 0) {
        const deepFetch: { [fetch: string]: string[] } = {};

        Object.keys(modelFetched).forEach(id => {
          const properties = modelFetched[id].properties.filter(property => property.type === SbxModelField.REFERENCE);
          if (properties.length > 0) {
            deepFetch[modelId[id]] = properties.map(property => property.name);
          }
        });

        const fetch = Object.keys(deepFetch).reduce((arr: string[], mainModel) => {
          deepFetch[mainModel].forEach(fetchModel => {
            arr.push(`${mainModel}.${fetchModel}`);
          });
          return arr;
        }, []);

        setDeepFetchedModels(fetch)
      } else {
        setDeepFetchedModels([])
      }


      // setModelsFetched(fetchedModels);

      dispatch(actions.changeFetchReferences(modelFetched));
      return modelFetched;
    }
  }, [model]);

  useEffect(() => {
    const eC = getColumns();
    setColumns(eC);
    if (getSizeColumns) {
      getSizeColumns(eC.length);
    }
    if (model) {
      setFetchedModels(getFetch(model))
      //
    }
  }, [model, includeColumns]);


  useAsyncEffect(async () => {
    setIncludeColumn([]);
    setColumns([]);
    setColumnsTable([]);
    await getPropertiesByReferences();
  }, [getPropertiesByReferences]);

  useAsyncEffect(async () => {
    await getModels();
  }, []);

  useEffect(() => {
    if (query && starting.current && !model) {
      const {row_model} = query;
      const m = rows.map(m => ({
        label: capitalize(m.name),
        value: m
      })).find(mdl => mdl.value.name === row_model);

      if (m) {
        dispatch(actions.changeModel(m));
      }
      setDefaultFetchedModels(query.fetch ?? [])

    }
  }, [query, rows]);


  useAsyncEffect(async () => {
    if (query && Object.values(query).length && starting.current === 'starting') {
      if (model && query.where.length && state.state === StateLocal.FETCH_REFERENCES_RESOLVED) {
        const con = getConditions(model.value, query, state, t);
        await onResultQuery({size, condition: getValueConditions(con)});
        dispatch(actions.setConditions(con));
        starting.current = 'started';
      } else if (model && !query.where.length) {
        starting.current = 'started';
        await onResultQuery({size});
      }
    }

    if (!query && starting.current === 'starting') {
      starting.current = 'started';
    }

    if (setStateLoading) {
      setStateLoading(state.state);
    }
  }, [query, model, modelFetched, state.state]);

  useIgnoreFirstEffect(() => {
    if (starting.current === 'started') {
      const fetch = query?.fetch ?? getFetch(model)
      const queryD = {
        where: getValueConditions(conditions),
        row_model: model?.value.name ?? '',
        fetch
      }
      if (getQuery) getQuery(queryD);

      if (!query?.fetch) {
        setDefaultFetchedModels(fetch)
      }

      dispatch(actions.changeQuery(JSON.stringify(queryD, null, "\t")));
    }
  }, [model, conditions]);


  const updateFetchQuery = ({
                              isDeepFetch,
                              models,
                              isReverseFetch
                            }: { models: { label: string, value: any }[], isDeepFetch?: boolean, isReverseFetch?: boolean }) => {

    const fetch = query?.fetch ?? [];

    let fetchItems: string[] = []

    // Check what array of elements is changing, delete from current array and add the new state for it
    if (isDeepFetch) {
      fetchItems = fetch.filter(item => !item.includes('.'))
    } else if (isReverseFetch) {
      fetchItems = fetch.filter(item => item.includes('.') && item.split(".")[1] === query?.row_model)
    } else {
      fetchItems = fetch.filter(item => item.includes('.'))
    }


    if (models.length > 0) {
      fetchItems = [...fetchItems, ...models.map(item => item.value)];
    }


    const queryD = {
      where: getValueConditions(conditions),
      row_model: model?.value.name ?? '',
      fetch: fetchItems
    }
    if (getQuery) getQuery(queryD);
    setDefaultFetchedModels(fetchItems)
    dispatch(actions.changeQuery(JSON.stringify(queryD, null, "\t")));
  };

  const onResultQuery = async (params: { page?: number, size?: number, condition?: Condition[] }) => {
    const {page = 1, size = DEFAULT_SIZE, condition} = params;
    dispatch(actions.changeState(StateLocal.SEARCHING));
    const fetchArray: string[] = getFetch(model);
    let parameters = {
      where: validateQueryValues(condition),
      row_model: model?.value.name ?? '',
      fetch: fetchArray,
      page,
      size
    }
    if (currentTap === "1") {
      try {
        parameters = {
          ...JSON.parse(state.queryTab ?? ""),
          page,
          size
        }
      } catch (e) {
        toast({type: "error", message: "Errors in the query, please verify json data"})
      }
    }
    const res = await findByModel(parameters);
    if (res.success) {
      dispatch(actions.changeResult({
        results: res.items,
        fetched_map: res.fetched_results,
        model: res.model ?? [],
        totalItems: res.total_items
      }));
      setSize(size);
    } else {
      dispatch(actions.changeState(State.REJECTED));
    }
  };

  const onFinish = async () => {
    if (model?.value && getResult && query) {
      dispatch(actions.changeState(StateLocal.GETTING_RESULT));
      // const rows = await getEndResult(query, state, columns, includeColumns);
      dispatch(actions.changeState(StateLocal.RESOLVED_RESULT));
      getResult(columnsTable, []);
    }
  };

  async function onGetData(params?: { size?: number, page?: number }) {
    await onResultQuery({...params, condition: getValueConditions(conditions)});
  }

  const getColumns = () => {
    const c = (model?.value.properties
      .map(m => ({
        name: m.name,
        header: capitalize(m.name),
        type: m.type
      })) ?? []);
    const eColumns = [...c, ...includeColumns];
    return eColumns.sort((a, b) => a.name.localeCompare(b.name));
  };

  function addColumn(field: Model, fetchName: string) {
    let array = new Array(...includeColumns);
    const identify = getColumnFormat(fetchName, field.name);
    array.push({
      type: field.type,
      name: identify,
      header: identify
    });
    setIncludeColumn(array);
  }

  function removeColumn(field: Model, fetchName: string) {
    const identify = getColumnFormat(fetchName, field.name);
    let array = new Array(...includeColumns).filter(c => c.name !== identify);
    setIncludeColumn(array);
  }

  const loadingModels = state.state === State.PENDING;
  const isSearching = state.state === StateLocal.SEARCHING;
  const isGetting = state.state === StateLocal.GETTING_RESULT;

  async function onExportData() {
    if (!query) return;
    dispatch(actions.changeState(StateLocal.SEARCHING));
    const rows = await getEndResult(query, state, columns, includeColumns);
    const text = convertTableRowsToCSVString(columnsTable, rows);
    downloadTextToFile(text, 'csv', `File ${convertDateToDDMMMYYYY(new Date())}`);
    dispatch(actions.changeState(State.RESOLVED));
  }

  useEffect(() => {
    setMapper(resultMapper(columns, state, removeColumn, addColumn, includeColumns, t));
  }, [columns, state.results]);

  return (
    <>
      {loadingModels ? (
        <div className="p-3 d-flex justify-content-center">
          <SpinnerComponent/>
        </div>
      ) : (
        <>
          <TabContents
            direction="end"
            getCurrentTab={setCurrentTap}
            tabs={[
              {
                label: t("query_builder"),
                component: (
                  <div className="d-flex flex-column gap-3">
                    {showFetch &&
                        <QueryFetchComponent reverseFetchModels={reverseFetchedModels}
                                             updateFetchModel={updateFetchQuery}
                                             defaultFetch={defaultFetchedModels ?? []}
                                             fetchModels={fetchedModels} isLoading={state.state}
                                             deepModelsFetch={deepFetchedModels} model={query?.row_model ?? ''}/>}
                    <Card>
                      <CardBody>
                        <Row>
                          {/*Model selection*/}
                          <Col sm={12} lg={4}>
                            <LabelComponent>{t('select_model')}</LabelComponent>
                            <SelectComponent
                              id="models"
                              name="models"
                              value={model}
                              onChange={opt => {
                                dispatch(actions.changeModel(opt))
                                setDefaultFetchedModels(getFetch(opt))
                                getReverseFetch(state.rows, opt.value.name)
                              }}
                              options={state.rows.map(m => ({label: capitalize(m.name), value: m}))}/>
                          </Col>

                          <Col sm={12} lg={8} className="text-end pt-2">
                            <LabelComponent>&nbsp;</LabelComponent><br/>
                            {/*Add conditions*/}
                            <Button
                              onClick={() => dispatch(actions.addGroup())}
                              disabled={!model}
                              color="primary"
                              size="sm">
                              <FontAwesomeIcon icon={faPlusCircle}/> {t('add_group')}
                            </Button>
                            {/*clear conditions*/}
                            {!!conditions.length &&
                                <Button
                                    onClick={() => dispatch(actions.removeAllGroups())}
                                    color="light"
                                    size="sm"
                                    className="ms-2">
                                    <FontAwesomeIcon icon={faTimesCircle}/> {t('clear')}
                                </Button>}
                          </Col>
                        </Row>
                        {/*Conditions*/}
                        <div>
                          {starting.current === 'starting' ? (
                            <div className="d-flex justify-content-center">
                              <SpinnerComponent/>
                            </div>
                          ) : (
                            <>
                              {!conditions.length && (
                                <div className="text-center text-gray mx-2 px-2 py-4">
                                  ¡{t('no_groups')}!
                                </div>
                              )}
                              {conditions.map((group, indexG) => {
                                const isFirstItem = indexG === 0;
                                return <div key={indexG} className="border bg-white shadow-sm border-light my-2 py-3">
                                  <div className={`d-flex justify-content-${isFirstItem ? 'end' : 'between'} p-2`}>
                                    {!isFirstItem && (
                                      <Switch
                                        checked={group.ANDOR === Andor.AND}
                                        unCheckedChildren={'OR'}
                                        checkedChildren={t('And')}
                                        onChange={checked => dispatch(actions.changeAndorGroup({
                                          andor: checked ? Andor.AND : Andor.OR,
                                          index: indexG
                                        }))}/>
                                    )}
                                    <div>
                                      <Button
                                        onClick={() => dispatch(actions.addCondition(indexG))}
                                        disabled={!model}
                                        color="primary"
                                        size="sm">
                                        <FontAwesomeIcon icon={faPlusCircle}/> {t('add_condition')}
                                      </Button>
                                      {/*clear conditions*/}
                                      {!!conditions.length &&
                                          <Button
                                              onClick={() => dispatch(actions.clearConditions(indexG))}
                                              color="light"
                                              size="sm"
                                              className="ms-2">
                                              <FontAwesomeIcon icon={faTimesCircle}/> {t('delete')}
                                          </Button>}
                                    </div>
                                  </div>

                                  {!group.GROUP.length && (
                                    <div className="text-center text-gray mx-2 px-2 py-4">
                                      ¡{t('no_conditions')}!
                                    </div>
                                  )}

                                  {group.GROUP.map((con, indexC) => {
                                    const fieldKey = 'field_' + indexC;
                                    const isDisable = con.OP === null || (
                                      con.OP?.value.condition === SbxConditionType.EXIST ||
                                      con.OP?.value.condition === SbxConditionType.NO_EXIST
                                    );

                                    const isReference = con.FIELD?.value.type === SbxModelField.REFERENCE && con.FIELD?.value.reference_type;
                                    const fieldOptions = (model?.value.properties
                                      .map(m => ({label: capitalize(m.name), value: m})) ?? []);

                                    let subFieldOptions = modelFetched[`${con.FIELD?.value.reference_type}`]?.properties
                                      .map(m => ({label: capitalize(m.name), value: m})) ?? [];

                                    subFieldOptions = [{
                                      label: 'key',
                                      value: plainToClass(Model, {name: '_KEY'})
                                    }, ...subFieldOptions];

                                    const loadingReferences = state.state === StateLocal.FETCH_REFERENCES_PENDING;

                                    const conDisabled = con.FIELD?.value.type === SbxModelField.REFERENCE ? (!con.FIELD || !con.SUB_FIELD) : !con.FIELD;
                                    return (
                                      <Row key={con.key} className="px-2 py-2 pb-1 justify-content-center">
                                        <Col sm={12} lg={1}>
                                          {!!indexC && (
                                            <div className="text-center mt-2">
                                              <LabelComponent>&nbsp;</LabelComponent><br/>
                                              <Switch
                                                unCheckedChildren={'Or'}
                                                checkedChildren={t('And')}
                                                onChange={checked => dispatch(actions.changeCondition(
                                                  {
                                                    conditionIndex: indexC,
                                                    groupIndex: indexG,
                                                    values: {ANDOR: checked ? Andor.AND : Andor.OR}
                                                  }))}
                                                checked={con.ANDOR === Andor.AND}/>
                                            </div>
                                          )}
                                        </Col>
                                        <Col sm={12} lg={isReference ? 2 : 4}>
                                          <LabelComponent>{t('field')}</LabelComponent>
                                          <SelectComponent
                                            loading={loadingReferences}
                                            id={fieldKey}
                                            name={fieldKey}
                                            value={con.FIELD}
                                            onChange={(FIELD: { label: string, value: Model }) => {
                                              let SUB_FIELD = null;
                                              if (FIELD.value.type === SbxModelField.REFERENCE && FIELD.value.reference_type) {
                                                SUB_FIELD = subFieldOptions.find(o => o.value.name === '_KEY') ?? null;
                                              }
                                              dispatch(actions.changeCondition({
                                                values: {FIELD, OP: null, VAL: null, SUB_FIELD},
                                                groupIndex: indexG,
                                                conditionIndex: indexC
                                              }));
                                            }}
                                            options={fieldOptions}/>
                                        </Col>
                                        {isReference && (
                                          <Col sm={12} lg={2}>
                                            <LabelComponent>{t('field')}</LabelComponent>
                                            <SelectComponent
                                              disabled={loadingReferences}
                                              loading={loadingReferences}
                                              id={fieldKey}
                                              name={fieldKey}
                                              onChange={SUB_FIELD => dispatch(actions.changeCondition({
                                                values: {
                                                  SUB_FIELD,
                                                  OP: null,
                                                  VAL: null
                                                },
                                                conditionIndex: indexC,
                                                groupIndex: indexG
                                              }))}
                                              value={con.SUB_FIELD}
                                              options={subFieldOptions}/>
                                          </Col>
                                        )}
                                        <Col sm={12} lg={2}>
                                          <LabelComponent>{t('condition')}</LabelComponent>
                                          <SelectComponent
                                            disabled={conDisabled}
                                            id={fieldKey}
                                            name={fieldKey}
                                            value={con.OP}
                                            onChange={OP => dispatch(actions.changeCondition({
                                              conditionIndex: indexC,
                                              groupIndex: indexG,
                                              values: {OP}
                                            }))}
                                            options={getOptionsByField(t, con.SUB_FIELD?.value.type ?? con.FIELD?.value.type)}/>

                                        </Col>
                                        <Col sm={12} lg={3}>
                                          <LabelComponent>{t('value')}</LabelComponent>
                                          <FieldInput
                                            disabled={isDisable}
                                            id={indexG.toString() + "_" + indexC.toString()}
                                            value={con.VAL}
                                            onChange={VAL => dispatch(actions.changeCondition({
                                              values: {VAL},
                                              groupIndex: indexG,
                                              conditionIndex: indexC
                                            }))}
                                            type={con.SUB_FIELD?.value.type ?? con.FIELD?.value.type}/>
                                        </Col>
                                        <Col sm={12} lg={1} className="text-center pt-2">
                                          <LabelComponent>&nbsp;</LabelComponent><br/>
                                          <Button
                                            size="sm"
                                            color="danger"
                                            onClick={() => dispatch(actions.removeCondition({
                                              conditionIndex: indexC,
                                              groupIndex: indexG
                                            }))}><FontAwesomeIcon
                                            icon={faTrash}/></Button>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>;
                              })}
                            </>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                )
              },
              {
                label: t("query_editor"),
                component: (
                  <EditorComponent
                    width="100%"
                    enableSnippets
                    value={state.queryTab}
                    onChange={value => {
                      validateQuery(value ?? "", (query) => {
                        if (getQuery) {
                          getQuery(query);
                        }
                      })
                      dispatch(actions.changeQuery(value ?? ""));
                    }}
                  />
                )
              }
            ]}/>

          {showTable &&
              <>
                  <div className="d-flex justify-content-end my-3">
                      <button
                          disabled={isSearching || !model}
                          onClick={() => onGetData()}
                          className="btn btn-primary btn-sm">
                          <FontAwesomeIcon spin={isSearching} icon={isSearching ? faSpinner : faSearch}
                                           className="me-1"/> {t('search')}
                      </button>

                      <button
                          disabled={isSearching || !model}
                          onClick={onExportData}
                          className="btn btn-success btn-sm ms-2">
                          <FontAwesomeIcon spin={isSearching} icon={isSearching ? faSpinner : faFileCsv}
                                           className="me-1"/> {t('export')}
                      </button>
                    {getResult && (
                      <button
                        disabled={isSearching || !state.results.length || isGetting}
                        onClick={() => onFinish()}
                        className="btn btn-primary btn-sm ms-2">
                        <FontAwesomeIcon icon={faPollH}
                                         className="me-1"/> {t('get_results')}
                      </button>
                    )}
                  </div>
              </>}
          {model && showTable && (
            <CustomTableComponent
              totalData={totalItems}
              onShowSizeChange={(page, size) => onGetData({size})}
              onChangePage={(page, size) => onGetData({page, size})}
              columnsSetting
              getColumns={setColumnsTable}
              loading={isSearching}
              columns={columns}
              data={mapper}/>
          )}
        </>
      )}
    </>
  );
};

export default QueryComponent;
