import type {NextPage} from 'next'
import {useEffect} from "react";
import {useRouter} from "next/router";
import {useSelector} from "react-redux";
import {authReducer} from "../store/Selectors";
import {AuthStates} from "../store/Auth/Types";

export const START_PAGE_ON = "/inbox";
export const START_PAGE_PASSWORD_EXPIRATION = "/auth/update-password";
export const START_PAGE_LOGIN = "/auth/login";

console.info("version -> 16-05-22");

const Home: NextPage = () => {
  const {state, user} = useSelector(authReducer);
  const router = useRouter();
  useEffect(() => {
    if (state === AuthStates.AUTHENTICATED) {
      router.push(user.config?.sbx_crm.startPage ? user.config?.sbx_crm.startPage : START_PAGE_ON);
    }
  }, [state])

  return <div className="vh-35 bg-white shadow-lg"/>

}

export default Home
