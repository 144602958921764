import React from "react";
import {Col, FormGroup, Row} from "reactstrap";

import SmallTextComponent from "./SmallTextComponent";
import {getFieldByField} from "./index";
import LabelComponent from "./LabelComponent";
import IField from "../../../types/FormBuilder/IField";

interface IProps {
  fields: IField[];
  value: { [key: string]: any };
  onChange?: (value: any) => void;
  count?: number;
  className?: string;
}

const FormGroupComponent: React.FC<IProps> = (props) => {
  const {value, fields, onChange, count = 1, className} = props;
  const tam = (fields.length % 2) ? fields.length - 1 : fields.length;
  return (
    <>
      <Row className={className}>
        {fields.slice(0, tam).slice(0, (count) * 2).map(f => {
          const component = getFieldByField(f);
          if (!component.field) {
            component.field = SmallTextComponent;
          }
          return (
            <Col key={f.id} lg={6}>
              <FormGroup>
                <LabelComponent {...f.labelProps} >{f.label}</LabelComponent>
                <component.field
                  {...f}
                  id={f.id}
                  value={value[f.id] || component.defaultValue}
                  selected={value[f.id] || component.defaultValue}
                  onChange={(e: any) => onChange && onChange({...value, [f.id]: e})}/>
              </FormGroup>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default FormGroupComponent;
