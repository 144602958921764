import {
  handleTreeViewCheckChange,
  processTreeViewItems,
  TreeView,
  TreeViewCheckChangeEvent,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent
} from "@progress/kendo-react-treeview";
import React, {useEffect} from "react";
import {separator} from "../EditConfig/utils";
import {capitalize} from "../../utils";

export interface TreeViewDataItem {
  text: string;
  id: string;
  expanded?: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
  data?: any;
}

interface IProps {
  tree: TreeViewDataItem[];
  onItemClick: (data: TreeViewDataItem) => void;
  filter: string;
  onSelections: (srt: string) => void;
}

export const TreeViewComponent = ({tree, onItemClick: clickItem, filter, onSelections}: IProps) => {
  
  const [check, setCheck] = React.useState([]);
  const [expand, setExpand] = React.useState<{ ids: string[], idField: string }>({ids: [], idField: 'text'});
  const [select, setSelect] = React.useState(['']);
  
  
  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.text);
    
    index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
    setExpand({ids, idField: 'text'});
  }
  const onCheckChange = (event: TreeViewCheckChangeEvent) => {
    const settings = {singleMode: false, checkChildren: false, checkParents: false};
    setCheck(handleTreeViewCheckChange(event, check, tree, settings))
  }
  
  const onItemClick = (event: TreeViewItemClickEvent) => {
    clickItem(event.item);
    setSelect([event.itemHierarchicalIndex]);
  }
  
  useEffect(() => {
    if (select.length === 1) {
      onSelections(select[0]);
    }
  }, [select])
  
  useEffect(() => {
    const ids: string[] = [];
    const selections: string[] = [];
    
    function _recursive(tree: TreeViewDataItem[]) {
      tree.forEach(e => {
        if (e.text.toLowerCase().includes(filter.toLowerCase())) {
          selections.push(e.id);
          (e.data.key as string).split(separator).forEach(e2 => ids.push(capitalize(e2.replaceAll("_", " "))));
        }
        if (e.items) {
          _recursive(e.items);
        }
      })
    }
    
    if (filter) _recursive(tree);
    
    setExpand({...expand, ids});
    setSelect(selections);
    
  }, [filter, tree])
  
  
  return (
    <div>
      <TreeView
        data={processTreeViewItems(tree, {select, check, expand})}
        expandIcons={true}
        onExpandChange={onExpandChange}
        aria-multiselectable={true}
        onItemClick={onItemClick}
        checkboxes={true}
        onCheckChange={onCheckChange}
      />
    </div>
  )
}

export default TreeViewComponent;