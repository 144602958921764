import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Route} from "../routes/routes";
import {Breadcrumb} from "../types/utils";

interface IState {
  routes: Route[];
  breadcrumb?: Breadcrumb[];
  activeView?: string;
}

const initialState: IState = {
  routes: [],
}

const slice = createSlice({
  name: "router",
  initialState: initialState,
  reducers: {
    setCurrentRoutes: (state, action: PayloadAction<Route[]>) => {
      state.routes = action.payload;
    },
    changeBreadcrumb(state, action: PayloadAction<Breadcrumb[] | undefined>) {
      state.breadcrumb = action.payload;
    },
    changeActive(state, action: PayloadAction<string | undefined>) {
      state.activeView = action.payload;
    }
  }
});

export const routerActions = slice.actions;
export default slice.reducer;
