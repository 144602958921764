import {actionsModal, ModalTypes} from '../../../../store/Modal/Slice';
// import Form from '../../../../types/FormType';
import React, {FormEvent, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ModalComponent from '../ModalComponent/ModalComponent';
import {Button} from 'reactstrap';
import useTranslate from '../../../../hooks/useTranslate';
import {FormStates} from '../../../../store/Form/Types';
import {RootState} from '../../../../store/Reducers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faSpinner, faTimes} from '@fortawesome/free-solid-svg-icons';
import DynamicFieldsFromConfig from '../../../EditConfig/DynamicFieldsFromConfig';
import {TypeRule} from "../../../EditConfig/utils";
import {AnyData} from "../../../../types/AnyData";

interface IProps {
  open?: boolean;
  data: IPropsDynamicModal
}

export interface IPropsDynamicModal {
  type: ModalTypes.UPDATE_DYNAMIC_CONFIG;
  data: AnyData;
  identifierKey?: string;
  title: React.ReactNode;
  rules: TypeRule[],
  onSuccess: (data: any) => void;
}

const DynamicModal = ({open, data: props}: IProps) => {
  const dispatch = useDispatch();
  const {state} = useSelector((state: RootState) => state.FormReducer);
  const {t} = useTranslate("common");
  const [data, setData] = useState(props.data);
  
  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    props.onSuccess(data);
    toggle();
  }
  
  const loading = state === FormStates.FORM_PENDING;
  const toggle = () => dispatch(actionsModal.closeModal({
    type: ModalTypes.UPDATE_DYNAMIC_CONFIG,
    identifierKey: props.identifierKey
  }));
  
  return (
    <>
      <ModalComponent
        size={"xl"}
        title={props.title}
        isOpen={open!}
        footer={<>
          <Button size="sm" color="primary" disabled={loading} className="me-1" form={props.identifierKey}>
            <FontAwesomeIcon icon={loading ? faSpinner : faSave} spin={loading}/> {" " + t("save")}
          </Button>
          <Button size="sm" color="light" disabled={loading} onClick={toggle}>
            <FontAwesomeIcon icon={loading ? faSpinner : faTimes} spin={loading}/> {" " + t("cancel")}
          </Button>
        </>}
        toggle={toggle}>
        <form
          id={props.identifierKey}
          onSubmit={onSubmit}>
          <DynamicFieldsFromConfig
            identifier={props.identifierKey}
            config={data} rules={props.rules}
            onChange={setData}/>
        </form>
      </ModalComponent>
    </>
  )
  
}
export default DynamicModal;
