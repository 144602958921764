import React, {useState} from "react";
import {SingleDatePicker} from "react-dates";
import moment, {Moment} from "moment";

interface Props {
  id: string,
  value: Date | null,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  placeholder?: string,
  onChange?: (value: Date | null) => void,
  isOutsideRange?: (day?: Moment) => boolean,
  onFocusedChange?: (e: Boolean) => void;
  displayFormat?: string | (() => string);
  orientation?: "vertical" | "horizontal"
  monthFormat?: "MMMM YYYY";
  isDayBlockedDate?: string // 2022-09-01
  renderMonthElement?: ((props: {
    month: moment.Moment;
    onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
    onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
    isVisible: boolean;
  }) => React.ReactNode)
}

const DateComponent: React.FC<Props> = ({
                                          id,
                                          value,
                                          required,
                                          monthFormat,
                                          readonly,
                                          disabled,
                                          placeholder,
                                          onChange,
                                          displayFormat,
                                          isOutsideRange,
                                          onFocusedChange,
                                          orientation = "horizontal",
                                          renderMonthElement,
                                          isDayBlockedDate
                                        }) => {

  const [focused, setFocused] = useState(false);

  return (
    <div className="date-picker-component">
      <SingleDatePicker id={id}
                        block
                        monthFormat={monthFormat}
                        orientation={orientation}
                        required={required}
                        readOnly={readonly}
                        disabled={disabled}
                        renderMonthElement={renderMonthElement}
                        placeholder={placeholder}
                        isOutsideRange={isOutsideRange}
                        focused={focused}
                        onFocusChange={e => {
                          setFocused(e.focused)
                          if (onFocusedChange) {
                            onFocusedChange(Boolean(e.focused))
                          }
                        }}
                        isDayBlocked={day => {

                          if (isDayBlockedDate){
                            if (isDayBlockedDate === "new Date()"){
                              return day.diff(moment(), 'days') < 0
                            }

                            return day.diff(moment(isDayBlockedDate), 'days') < 0
                          }

                          return false
                        }}
                        onDateChange={e => onChange && onChange(e ? e.toDate() : null)}
                        date={value ? moment(value) : null}
                        displayFormat={displayFormat}
      />
    </div>
  )
};

export default DateComponent;
