import {all, put, select, takeEvery} from 'redux-saga/effects';
import {accountTypeSlice} from './Types';
import {actionsForm} from '../Form/Slice';
import {FormStates} from '../Form/Types';
import {toast} from '../../utils';
import {actionsAccount} from './Slice';
import {findByKey, findByLike, getAccounts} from '../../services/backend/AccountService';
import {PayloadAction} from '@reduxjs/toolkit';
import {Response} from '../../types/Response';
import {authReducer} from "../Selectors";

function error() {
  toast({type: 'error', message: ''});
}

function* getAccountsSaga({payload}: ReturnType<typeof actionsAccount.getAccounts>) {
  try {
    const {user} = yield select(authReducer)
    const res: Response = yield getAccounts({...payload, fetch: user?.config?.sbx_crm?.accounts?.account_models_fetch ?? []});
    if (res.success && res.items) {
      res.items = res.items.sort((a, b) => (a.company?.company_name ?? "").localeCompare((b.company?.company_name ?? "")))
      yield put(actionsAccount.setAccounts({total_accounts: res.total_items ?? 0, accounts: res.items}));
    } else {
      throw new Error();
    }
  } catch (e) {
    error();
    yield put(actionsForm.changeState(FormStates.FORMS_REJECTED));
  }
}

function* findByLikeSaga({payload}: ReturnType<typeof actionsAccount.findByLike>) {
  try {
    const res: Response = yield findByLike(payload);
    if (res.success && res.items) {
      yield put(actionsAccount.setAccounts({total_accounts: 0, accounts: res.items}));
    } else {
      throw new Error();
    }
  } catch (e) {
    error();
    yield put(actionsForm.changeState(FormStates.FORMS_REJECTED));
  }
}

function* findByKeySaga({payload}: PayloadAction<string>) {
  try {
    const res: Response = yield findByKey(payload);
    if (res.success && res.item) {
      yield put(actionsAccount.setCurrentAccount(res.item));
    } else {
      throw new Error();
    }
  } catch (e) {
    error();
    yield put(actionsForm.changeState(FormStates.FORMS_REJECTED));
  }
}

export default function* allSagas() {
  yield all([
    takeEvery(accountTypeSlice.GET_ACCOUNTS, getAccountsSaga),
    takeEvery(accountTypeSlice.FIND_BY_LINK, findByLikeSaga),
    takeEvery(accountTypeSlice.FIND_BY_KEY, findByKeySaga),

  ]);
}
