import {get, post} from '../../network';
import {analyticQueryValidation} from '../../utils';
import {AnalyticQuery, Source} from '../../types/Analytic';
import {EventMeta} from '../../classes/TrackEvent';

export const getCustomerGrowth = () => {
    return get("/api/customer-news-and-last-purchase").then(res => res);
}

export const getProductGroupsByYear = () => {
    return get(`/api/productgroup`).then(res => res);
}

export const getCustomerCohorts = () => {
    return get("/api/customer-cohorts").then(res => res)
}

export const getMarketingData = () => {
    return get("/api/marketing").then(res => res)
}

export const executeAnalyticJson = (json: any ) => {
    return post('/api/v2/sbx/analytics/query/execute_commands', analyticQueryValidation({...json}));
}

export const getAnalyticJsonColumns = (json: AnalyticQuery) => {
    return post('/api/v2/sbx/analytics/query/get_columns', analyticQueryValidation({...json}));
}

export const getSchema = (schemas: Source[]) => {
    return post('/api/v2/sbx/analytics/get_schema', schemas).then(res => res)
}

export const createEventService = (params: {
    meta: EventMeta,
    props: any,
    extra?: any
}) => {
    return post('/api/event', params).then(res => res);
};
