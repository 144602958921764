import {useState} from 'react';
import useIgnoreFirstEffect from "./useIgnoreFirstEffect";

const UseRequired = (value: any, required: boolean) => {

  const [error, setError] = useState(false);

  useIgnoreFirstEffect(() => {
    if (required) {
      setError(!value);
    }
  }, [value]);

  return error;
};

export default UseRequired;
