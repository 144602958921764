import {AnyData} from "../../types/AnyData";

export enum ConditionType {
  EQUAL_TO = "===",
  GREATER_THAN = ">",
  SMALLER_THAN = "<",
  DIFFERENT_OF = "!==",
  NO_EXIST = "=== null",
  EXIST = "!== null"
}

export type LabelType =
  typeof ConditionType.EQUAL_TO
  | typeof ConditionType.SMALLER_THAN
  | typeof ConditionType.GREATER_THAN
  | typeof ConditionType.DIFFERENT_OF
  | typeof ConditionType.EXIST
  | typeof ConditionType.NO_EXIST


export interface OptionType {
  label: string;
  value: string;
  data?: any;
}

interface Field {
  readonly?: boolean;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  label?: string;
}

interface SelectType extends Field, AnyData {
  type: FieldTypesRule.SELECT;
  value?: null | OptionType | OptionType[];
  options: OptionType[];
  multi?: boolean;
  isValidator?: boolean;
  isComparator?: boolean;
  onChange?: (e: OptionType | null) => void;
}

export enum FieldTypesRule {
  CONDITION = "condition",
  TEXT = "text",
  NUMBER = "number",
  SELECT = "select"
}

interface TextType extends Field, AnyData {
  type: FieldTypesRule.TEXT | FieldTypesRule.NUMBER;
  value?: string;
  isValidator?: boolean;
  onChange?: (e: string) => void;
}


interface ConditionField extends Field, AnyData {
  type: FieldTypesRule.CONDITION;
  value?: OptionType;
  onChange?: (e: OptionType) => void;
  exclude?: LabelType[];
  include?: OptionType[];
  options?: OptionType[];
}

export enum Operator {
  OR = "||",
  AND = "&&"
}

export interface RuleCondition {
  field: string;
  label?: string;
  condition: LabelType;
  value?: string | number;
  operator?: Operator
}

export interface ValuesData {
  [key: string]: { value: any, type: FieldTypesRule, label: "b" | "c" | "d" }
}

export type Container = SelectType | TextType | ConditionField;

export const conditions: OptionType[] = Object.keys(ConditionType).map((key: string) => {
  const T: any = ConditionType;
  return {label: key, value: T[key]};
})
