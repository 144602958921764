import {combineReducers} from 'redux';

import FormReducer from "../Form/Slice";
import ModalReducer from "../Modal/Slice";
import AccountReducer from "../Account/Slice";
import AuthReducer from "../Auth/Slice";
import DataProviderReducer from "../DataProvider/Slice";
import PermissionReducer from "../Permission/Slice";
import SearchReducer from "../SearchReducer";
import RouterReducer from "../RouterReducer";
import ConfigReducer from "../Config/Slice";

const RootReducer = combineReducers({
  FormReducer,
  ModalReducer,
  AccountReducer,
  AuthReducer,
  DataProviderReducer,
  PermissionReducer,
  SearchReducer,
  RouterReducer,
  ConfigReducer
});

export default RootReducer;
export type RootState = ReturnType<typeof RootReducer>;
