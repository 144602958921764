import common from "../es/common.json";
import config from "../es/config.json";
import routes from "../es/routes.json";
import dataProvider from "../es/data-provider.json";
import emailBlast from "../es/email-blast.json";
import flowChart from "../es/flowChart.json";
import formBuilder from "../es/form-builder.json";
import formList from "../es/form-list.json";
import inbox from "../es/inbox.json";
import perAssign from "../es/permissions-assignment.json";
import perManager from "../es/permissions-manager.json";
import projectManager from "../es/project-manager.json";
import task from "../es/task.json";
import salesGoal from "../es/sales-goal.json";
import account from "../es/account.json";
import calendar from "../es/calendar.json";
import customMessage from "../es/custom-message.json";
import report from "../es/report.json";

export default {
  common,
  routes,
  "data-provider": dataProvider,
  "email-blast": emailBlast,
  "flow-chart": flowChart,
  "form-builder": formBuilder,
  "form-list": formList,
  inbox,
  "permissions-assignment": perAssign,
  "permissions-manager": perManager,
  "project-manager": projectManager,
  task,
  "sales-goal": salesGoal,
  "custom-message": customMessage,
  account,
  report,
  calendar,
  config
}
