import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DataProviderState, DataProviderStates} from "./Types";
import {DataProvider, Option} from "../../types/DataProvider";
import {ProviderType} from "../../types/ProviderType";


const initialState: DataProviderState = {
  state: DataProviderStates.IDLE,
  dataProviders: []
}

const slice = createSlice({
  name: "dataProvider",
  initialState,
  reducers: {
    getDataProviders(state) {
      state.state = DataProviderStates.DATA_PENDING;
    },
    setDataProviders(state, action: PayloadAction<DataProvider[]>) {
      state.state = DataProviderStates.DATA_RESOLVED;
      state.dataProviders = action.payload;
    },
    getDataProviderByIdAndType(state, action: PayloadAction<{ providerId: number, providerType: ProviderType | string }>) {
      state.state = DataProviderStates.DATA_PENDING;
    },
    deleteOptionFromDataProviderById(state, action: PayloadAction<{ providerId: number, optionId: number, providerType: ProviderType | string }>) {
      state.state = DataProviderStates.OPTION_PENDING;
    },
    setDataProvider(state, action: PayloadAction<DataProvider>) {
      state.state = DataProviderStates.DATA_RESOLVED;
      state.dataProvider = action.payload;
    },
    addDataProvider(state, action: PayloadAction<DataProvider>) {
      state.state = DataProviderStates.PROVIDER_PENDING;
      state.dataProvider = action.payload;
    },
    deleteDataProvider(state, action: PayloadAction<number>) {
      state.state = DataProviderStates.PROVIDER_PENDING;
    },
    addOptionToProvider(state, action: PayloadAction<{ option: Option, providerId: number }>) {
      state.state = DataProviderStates.OPTION_PENDING;
    },
    updateDataProvider(state, action: PayloadAction<DataProvider>) {
      state.state = DataProviderStates.PROVIDER_PENDING;
    },
    changeState(state, action: PayloadAction<DataProviderStates>) {
      state.state = action.payload;
    }
  }
})


export const actionsDataProvider = slice.actions;
export default slice.reducer;
