import React from 'react';

type Props = {
  title: string;
  subtitle?: string;
  headerImage?: string
  extraContent?: JSX.Element
}

const DropdownMenuHeader = ({title, subtitle, headerImage, extraContent}: Props) => {

  return (
    <div className="dropdown-menu-header" >
      <div className="dropdown-menu-header-inner bg-premium-dark">
        <div className="menu-header-image opacity-2" style={{backgroundImage: headerImage ?? "url('/assets/images/dropdown-header/abstract4.jpg')"}}/>
        <div className="menu-header-content ">
          {extraContent}
          <h5 className="menu-header-title text-white">{title}</h5>
          {subtitle && <h6 className="menu-header-subtitle text-white">{subtitle}</h6>}
        </div>
      </div>
    </div>
  );
};

export default DropdownMenuHeader;