import common from "../en/common.json";
import config from "../en/config.json";
import routes from "../en/routes.json";
import dataProvider from "../en/data-provider.json";
import emailBlast from "../en/email-blast.json";
import flowChart from "../en/flowChart.json";
import formBuilder from "../en/form-builder.json";
import formList from "../en/form-list.json";
import inbox from "../en/inbox.json";
import perAssign from "../en/permissions-assignment.json";
import perManager from "../en/permissions-manager.json";
import projectManager from "../en/project-manager.json";
import task from "../en/task.json";
import salesGoal from "../en/sales-goal.json";
import account from "../en/account.json";
import calendar from "../en/calendar.json";
import customMessage from "../en/custom-message.json";
import report from "../en/report.json";

export default {
  common,
  routes,
  "data-provider": dataProvider,
  "email-blast": emailBlast,
  "flow-chart": flowChart,
  "form-builder": formBuilder,
  "form-list": formList,
  inbox,
  "permissions-assignment": perAssign,
  "permissions-manager": perManager,
  "project-manager": projectManager,
  task,
  "custom-message": customMessage,
  "sales-goal": salesGoal,
  account,
  calendar,
  report,
  config,
}
