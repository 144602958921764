import React from 'react';
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import TextProps from './Models/TextProps';

interface IProps extends TextProps {
  value: number,
  onChange?: (value: number) => void;
}

const CurrencyComponent = (props: IProps) => {


  const {
    id,
    name,
    value,
    required,
    onChange,
    className,
    style,
    invalid,
    placeholder,
    defaultValue,
    disabled
  } = props;

  return <NumberFormat id={id}
                       required={required}
                       name={name}
                       disabled={disabled}
                       defaultValue={defaultValue ?? undefined}
                       style={style}
                       className={`form-control ${className || ''} ${invalid ? 'invalid' : ''}`}
                       placeholder={placeholder}
                       value={value}
                       allowNegative={false}
                       thousandSeparator="."
                       prefix="$"
                       decimalSeparator=","
                       onValueChange={(values: NumberFormatValues) => onChange ? onChange(parseInt(values.value)) : null}
  />;

};

export default CurrencyComponent;
