// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {getEnvironmentByConfig} from "./utils";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const DOMAIN_ID = process.env.SBX_DOMAIN || process.env.NEXT_PUBLIC_SBX_DOMAIN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://9f9a5d88aae24f069f613515bd1938f6@o512690.ingest.sentry.io/5970686',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  environment: getEnvironmentByConfig(DOMAIN_ID) ?? "production",
  integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
  denyUrls: ["http://localhost:3000", "localhost:3000", "0.0.0.0:3000"],
  beforeSend(event, hint) {
    if (window.location.hostname === 'localhost' || window.location.pathname === "/") {
      return null;
    }
    return event;
  },
  enabled: !window.location.host.includes("localhost") || window.location.pathname !== "/"
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

