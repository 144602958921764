import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AccountState, AccountStates} from "./Types";
import {Account} from "../../types/Account";
import {Condition} from "../../types/Sbx";


const initialState: AccountState = {
  accounts: [],
  state: AccountStates.IDLE,
  total_accounts: 0
}


const slice = createSlice({
  name: "account",
  initialState,
  reducers: {
    getAccounts(state, action: PayloadAction<{ where?: Condition[], pagination?: boolean, page?: number, pageSize?: number, fetch?: string[] } | undefined>) {
      state.state = AccountStates.ACCOUNT_PENDING;
    },
    setAccounts(state, action: PayloadAction<{ accounts: Account[], total_accounts: number }>) {
      state.state = AccountStates.ACCOUNT_RESOLVED;
      state.accounts = action.payload.accounts;
      state.total_accounts = action.payload.total_accounts;
    },
    findByLike(state, action: PayloadAction<{ search: string, size?: number }>) {
      state.state = AccountStates.ACCOUNT_PENDING;
    },
    getAccountByKey(state, action: PayloadAction<string>) {
      state.state = AccountStates.ACCOUNT_PENDING;
    },
    setCurrentAccount(state, action: PayloadAction<Account>) {
      state.state = AccountStates.ACCOUNT_RESOLVED;
      state.currentAccount = action.payload;
    },
    changeState(state, action: PayloadAction<AccountStates>) {
      state.state = action.payload;
    }
  }
});

export const actionsAccount = slice.actions;

export default slice.reducer;
