import React, {useEffect, useReducer, useState} from 'react';
import CustomTableComponent, {Action, Column} from '../Shared/CustomTableComponent';
import {useDispatch, useSelector} from 'react-redux';
import {authReducer} from '../../store/Selectors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faEye, faTrash} from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../hooks/useTranslate';
import {State} from '../../types/State';
import {Model, SbxResponse} from '../../types/Sbx';
import {deleteSbxModelService, getSbxModelFields, insertSbxModelService} from '../../services/backend/SbxService';
import {capitalize, downloadKeyFile, getAllDataByProvider, getObjValueInDeep, toast} from '../../utils';
import {actionsModal, ModalTypes} from '../../store/Modal/Slice';
import {DataProviderStates} from '../../store/DataProvider/Types';
import {Button, Col} from 'reactstrap';
import {useRouter} from 'next/router';
import {TableFormColumn} from '../TaskComponent/TableForm/TableTaskComponent';
import {SbxCrmDataColumn, SbxCrmDataInfo} from '../../types/User';
import DataFilterComponent from './DataFilterComponent';
import {FileTypeIcon} from '../Shared/ContentEngine';
import SpinnerComponent from '../Shared/SpinnerComponent';
import {IPropsTableEditModal} from '../Shared/Modal/TableEditModal/TableEditModal';
import {ListProvider} from '../../types/Task';
import {Response} from '../../types/Response';
import {TabsNames} from '../Layouts/AdminLayout/SideBarContent';
import {routerActions} from '../../store/RouterReducer';
import DropzoneComponent from '../Shared/DropzoneComponent/DropzoneComponent';
import {uploadFile} from '../../services/backend/ContentService';
import {AnyData} from '../../types/AnyData';

type Props = {
  dataModelName?: string,
  keyFilter?: string;
  filterObj?: string // "account._KEY"
  isDetailView?: boolean,
  dataProcess?: SbxCrmDataInfo,
  dataType?: 'data' | 'file' | 'table'
  mainModel?: string;
  configName: TabsNames;
}

enum Types {
  SET_STATE,
  SET_MULTI_STATE
}

interface ModelState {
  process_name: string;
  columns: Column[];
  default_values: any;
  data: any[],
  copyData: any[],
  model: Model[]
  editTableHeaders: TableFormColumn[]
  process?: SbxCrmDataInfo
  isLoading: State;
  processActions: Action[]
}

const initialState: ModelState = {
  process_name: '',
  columns: [],
  default_values: {},
  data: [],
  processActions: [],
  copyData: [],
  model: [],
  editTableHeaders: [],
  isLoading: State.IDLE,
};


function reducer(state: ModelState, {
  type,
  payload
}: { type: Types, payload: { name: string, value?: any } | { name: string, value: any }[] }) {
  switch (type) {
    case Types.SET_STATE:
      return {
        ...state,
        [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
      };
    case Types.SET_MULTI_STATE:
      (payload as { name: keyof ModelState, value: any }[]).forEach(data => {
        state[data.name] = data.value;
      });
      return {...state};
    default:
      throw new Error();
  }
}


const DataComponentList = ({
                             dataModelName,
                             isDetailView,
                             filterObj,
                             keyFilter,
                             dataProcess,
                             mainModel,
                             dataType,
                             configName
                           }: Props) => {
  const [localState, dispatchLocal] = useReducer(reducer, initialState);
  const {user} = useSelector(authReducer);
  const state = useSelector((state: any) => state.ModalReducer);
  const history = useRouter();
  const {t} = useTranslate('common');

  const dispatch = useDispatch();
  const [modelName, setModelName] = useState(dataModelName ?? '');

  useEffect(() => {
    if (!isDetailView) {
      setModelName(history.query.modelName + '');
    }
  }, [history.query.modelName, isDetailView]);

  React.useEffect(() => {
    if (dataModelName) {
      setModelName(dataModelName);
    }
  }, [dataModelName]);

  const dispatchForm = ({name, value}: { name: keyof ModelState, value: any }) => {
    dispatchLocal({type: Types.SET_STATE, payload: {value, name}});
  };

  const dispatchMultiForm = (forms: { name: keyof ModelState, value: any }[]) => {
    dispatchLocal({type: Types.SET_MULTI_STATE, payload: forms});
  };

  const getModelData = async ({fetchModels, listProvider, where}: {
    fetchModels?: string[], where?: any[], listProvider?: (SbxCrmDataColumn | TableFormColumn)[]
  }) => {
    dispatchForm({name: 'isLoading', value: State.PENDING});

    let providers: (Response<ListProvider> | undefined)[] = [];
    if (listProvider) {

      const promisesListProvider = listProvider.map(provider => getAllDataByProvider({provider_id: (provider as TableFormColumn)?.list_provider ?? ''}));
      providers = await Promise.all(promisesListProvider).then(res => res as ListProvider[]);

    }

    const response: SbxResponse = await getSbxModelFields({
      provider: {
        name: modelName as string,
        query: JSON.stringify({WHERE: where, FETCH: fetchModels ?? []})
      }
    });





    if (response?.success) {
      response.results?.forEach(item => {
        if (item.meta_data && JSON.parse(item.meta_data)) {
          const meta = JSON.parse(item.meta_data);
          Object.keys(meta).forEach(key => {
            if (providers.length > 0 && listProvider) {
              // search provider by column
              const provider = listProvider.find(provider => provider.column === key);
              if (provider) {
                // get provider id
                const provider_id = parseInt((provider as TableFormColumn)?.list_provider ?? '');
                // get provider response by the service and get it to use the options to get the default label based on value
                const providerResponse = providers.filter(provider => provider?.success).find(provider => provider?.item?.id === provider_id);
                if (providerResponse && providerResponse.item?.options && providerResponse.item.options.length > 0) {
                  const default_value = providerResponse.item.options.find(item => item.value === meta[key]);
                  if (default_value) {
                    item[key] = default_value;
                  }
                } else {
                  item[key] = meta[key];
                }
              } else {

                // if there isn´t a provider set default value from sbx or database
                item[key] = meta[key];
              }
            } else {

              item[key] = meta[key];
            }
          });
        }else{
          if (providers.length > 0 && listProvider){
            Object.keys(item).forEach(key => {
              if (typeof item[key] === 'string'){
                const provider = listProvider.find(provider => provider.column === key || (provider as any).reference === key);
                if (provider) {
                  const providerResponse = providers.find(nProvider => nProvider?.row_model === provider.column || nProvider?.row_model === (provider as any).reference)
                  if (providerResponse && providerResponse.items){

                    const nItem = providerResponse.items.find((nItem: AnyData) => nItem._KEY === item[key])

                    if (nItem){
                      item[key] = nItem
                    }
                  }
                }
              }
            })
          }
        }
      });

      //Filter results by parent key
      if (keyFilter && filterObj) {
        response.results = response.results?.filter(data => getObjValueInDeep(data, filterObj) === keyFilter);
      }


      const resModel = await getSbxModelFields({
        provider: {
          size: 1,
          name: modelName as string,
          query: JSON.stringify({WHERE: where, FETCH: fetchModels ?? []})
        }
      });

      dispatchMultiForm([
        {name: 'data', value: response.results},
        {name: 'copyData', value: response.results},
        {name: 'isLoading', value: State.RESOLVED},
        {name: 'model', value: resModel?.model ?? []},
      ]);
    } else {
      dispatchForm({name: 'isLoading', value: State.REJECTED});
    }
  };

  useEffect(() => {
    if (configName && user.config?.sbx_crm[configName] && modelName && user.config.sbx_crm[configName][modelName]) {
      dispatch(routerActions.changeBreadcrumb(
        [
          {
            label: capitalize(configName),
          },
          {
            label: t(user.config.sbx_crm[configName][modelName].label),
            active: true
          }
        ]));
    }
  }, [configName, user, modelName]);

  const getTableData = () => {
    if (user.config && Object.keys(user.config?.sbx_crm[configName] ?? {}).length > 0) {
      const process = dataProcess ?? user.config.sbx_crm[configName][modelName as string];
      const jsonColumns = process.columns.filter(column => !column.hidden && column.type === 'json').reduce((arr: TableFormColumn[], column) => {
        column.data?.filter(columnItem => !columnItem.hidden).forEach(columnItem => {
          arr.push({
            label: columnItem.label,
            name: columnItem.column, // value: column?.column_reference?.column ?? '',
            sub_type: capitalize(columnItem.type),
            parent: column.column,
            read_only: column.read_only ?? false,
            sub_columns: columnItem.type === 'reference' ? [{
              compound_name: (columnItem as SbxCrmDataColumn)?.column_reference?.compound_name ?? '',
              name: (columnItem as SbxCrmDataColumn)?.column_reference?.column ?? '',
              label: (columnItem as SbxCrmDataColumn).column
            }] : null,
            ...columnItem as any
          });
        });
        return arr;
      }, []);

      const availableColumns = process.columns.filter(column => {
        return !column.hidden && column.type !== 'json';
      });


      dispatchMultiForm([
        {name: 'process', value: process},
        {
          name: 'processActions', value: process.actions?.map(action => ({
            label: action.label,
            title: '',
            type: 'info',
            onAction: (row: any) => {
              history.push(history.asPath + '/' + row._KEY + '/' + action.action);
            }
          })) ?? []
        },
        {name: 'process_name', value: process.label},
        {
          name: 'editTableHeaders',
          value: [...availableColumns.map((column) => {
            return {
              name: (column as SbxCrmDataColumn).column,
              read_only: (column as SbxCrmDataColumn).read_only ?? false,
              sub_type: capitalize((column as SbxCrmDataColumn).type),
              sub_columns: column.type === 'reference' ? [{
                compound_name: (column as SbxCrmDataColumn)?.column_reference?.compound_name ?? '',
                name: (column as SbxCrmDataColumn)?.column_reference?.column ?? '',
                label: (column as SbxCrmDataColumn).column
              }] : null,
              ...column
            };
          }), ...jsonColumns]
        },
        {
          name: 'columns',
          value: [...availableColumns.filter(column => column.list).map(column => ({
            ...column,
            header: column.label,
            name: column.column,
            value: column?.column_reference?.column ?? '',
            type: capitalize(column.table_column_type || column.type),
            style: {minWidth: `${column.label.length}em`}

          })), ...jsonColumns.map(jsonColumn => ({
            header: jsonColumn.label,
            name: jsonColumn.name, // value: column?.column_reference?.column ?? '',
            type: capitalize(jsonColumn.sub_type) as any,
            parent: jsonColumn.name,
            style: {minWidth: `${jsonColumn.label.length}em`}
          }))]
        }, {
          name: 'default_values',
          value: process.columns.filter(column => {
            return column.default && !column.calculated;
          }).reduce((obj, column) => {
            obj[column.column] = column.default;
            return obj;
          }, {} as any)
        }]);


      const fetchModels = [...process.columns.filter(column => column.type === 'reference'), ...jsonColumns.filter(column => column.sub_type === 'Reference')];
      const listProvider = [...process.columns.filter(column => column.type === 'list_provider'), ...jsonColumns.filter(column => column.sub_type === 'List_provider')];


      let where: any[] = [];
      if (process.filter && Object.keys(process.filter).length > 0) {
        where.push({
          'ANDOR': 'AND',
          'GROUP': Object.keys(process.filter).map(it => {
            return {'ANDOR': 'AND', 'FIELD': it, 'VAL': process.filter[it as string], 'OP': '='};
          })
        });
      }
      getModelData({
        fetchModels: fetchModels.filter(column => column.column_reference).map(column => {
          if (column.column_reference) {
            const sub_references = column.column_reference.column.split('.');
            return `${column.column}${sub_references.length > 1 ? '.' + sub_references[0] : ''}`;
          }
          return '';
        }), where, listProvider
      });
    }
  };

  React.useEffect(() => {
    if ((!state[ModalTypes.TABLE_EDIT_MODAL]?.open || dataProcess) && modelName !== '') {
      getTableData();
    }
  }, [modelName, user.config, state[ModalTypes.TABLE_EDIT_MODAL]?.open, dataProcess]);

  const handleProduct = (insert = false) => {
    if (modelName) {

      const payload = {
        type: ModalTypes.TABLE_EDIT_MODAL,
        row_model: modelName as string,
        insert,
        model: localState.model ?? [],
        headers: localState.editTableHeaders,
        defaultValues: localState.default_values,
        pre_data: dataProcess ? state[ModalTypes.TABLE_EDIT_MODAL].data : null
        // headers: localState.model?.map(model => ({name: model.name, label: model.name, sub_columns: model.reference_type_name ? })) ?? []
      };

      if (mainModel) {
        payload.defaultValues = {...payload.defaultValues, [mainModel]: keyFilter};
      }


      if (localState.data.some(item => item.account)) {
        const isAccount = localState.data.every(item => item?.account?._KEY === localState.data[0]?.account?._KEY || item.account === localState.data[0].account);
        if (isAccount) {

          const accountKey = localState.data[0]?.account?._KEY ?? localState.data[0]?.account ?? '';

          payload.defaultValues = {...payload.defaultValues, account: accountKey};
        }

      }


      dispatch(actionsModal.openModal(payload as IPropsTableEditModal));
    }
  };


  const actions: Action[] = [
    {
      label: <div className="d-flex align-items-center">
        <span className="me-1">Ver detalle</span>
        <FontAwesomeIcon icon={faEye}/>
      </div>,
      title: '',
      type: 'primary',
      onAction: row => {
        dispatch(actionsModal.openModal({
          type: ModalTypes.TABLE_EDIT_MODAL,
          row_model: modelName as string,
          configName,
          model: localState.model ?? [],
          process: localState.process,
          isDetailView: true,
          headers: localState.editTableHeaders.map(header => ({...header, read_only: true})),
          defaultValues: localState.default_values,
          item: row,
          pre_data: dataProcess ? state[ModalTypes.TABLE_EDIT_MODAL].data : null
        }));
      }
    },
    {
      label: <div className="d-flex align-items-center">
        <span className="me-1">Actualizar</span>
        <FontAwesomeIcon icon={faEdit}/>
      </div>,
      title: '',
      type: 'primary',
      onAction: row => {
        dispatch(actionsModal.openModal({
          type: ModalTypes.TABLE_EDIT_MODAL,
          configName,
          row_model: modelName as string,
          model: localState.model ?? [],
          headers: localState.editTableHeaders,
          defaultValues: localState.default_values,
          item: row,
          pre_data: dataProcess ? state[ModalTypes.TABLE_EDIT_MODAL].data : null
        }));
      }
    },
    {
      label: <div className="d-flex align-items-center">
        <span className="me-1">Eliminar</span>
        <FontAwesomeIcon icon={faTrash}/>
      </div>,
      title: '',
      type: 'danger',
      onAction: row => {
        dispatch(actionsModal.openModal({
          type: ModalTypes.CONFIRM,
          onConfirm: async () => {
            dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}));
            dispatchForm({name: 'isLoading', value: State.PENDING});
            const response = await deleteSbxModelService({row_model: modelName as string, keys: [row._KEY]});
            if (response?.success) {
              dispatchForm({name: 'isLoading', value: State.RESOLVED});
              toast({message: t('success_delete_message')});
            } else {
              dispatchForm({name: 'isLoading', value: State.REJECTED});
              toast({message: t('rejected_message'), type: 'error'});
            }
            getTableData();

          },
          message: <p>¿{`Estas seguro de eliminar esta fila`}?</p>,
          title: <span><FontAwesomeIcon className="me-2" icon={faTrash}/>{t('delete')}</span>,
          state: DataProviderStates.OPTION_PENDING
        }));
      }
    },
    ...localState.processActions
  ];

  const tableActions: Action[] = [
    {
      label: <span><FontAwesomeIcon icon={faEye}/> Ver datos</span>, type: 'primary', onAction: (row) => {
        setModelName(row.name);
      }
    }
  ];

  const handleData = (data: any[]) => {
    dispatchForm({name: 'data', value: data});
  };

  const exportButtons = user.config?.sbx_crm[configName] ? user.config?.sbx_crm[configName][modelName]?.export : [];

  
  const onFileSubmit = async (files: File[]) => {

    const uploadFiles: any[] = [];
    dispatchForm({name: 'isLoading', value: State.PENDING});
    if (files.length > 0) {
      for (const file of files) {
        const newFile = {
          file_name: file.name,
          path: `/chldemo/sbx_crm/test/files_data/${file.name}`,
          file: file
        };


        const response = await uploadFile(newFile);
        if (response?.success) {
          uploadFiles.push({name: file.name, key: response.item.key_id, [mainModel as string]: keyFilter});
        }
      }
    }


    const res = await insertSbxModelService({row_model: dataModelName ?? "", rows: uploadFiles});
    if (res?.success) {
      dispatchForm({name: 'isLoading', value: State.RESOLVED});
      getTableData();
    } else {
      dispatchForm({name: 'isLoading', value: State.REJECTED});
    }
  };

  const getDataByType = () => {


    switch (dataType) {

      case 'file':

        return localState.isLoading === State.PENDING ?
          <div className="d-flex align-items-center justify-content-center">
            <SpinnerComponent/>
          </div> :
          <div className="d-flex flex-column">

            <div className="row mb-2">
              {localState.data.map(row => {
                return <Col lg={2} md={3} sm={6} key={row._KEY} className=" text-center mt-2">
                  <div
                    title={'Double click to download file'}
                    onClick={async () => {
                      dispatchForm({name: 'isLoading', value: State.PENDING});
                      // await downloadFileService(row.url, row.name);
                      await downloadKeyFile(row.key);
                      dispatchForm({name: 'isLoading', value: State.RESOLVED});
                    }}
                    className="pointer shadow--hover text-center p-2">
                    <FileTypeIcon name={row.name}/>
                  </div>
                  <span title={row.name} className="text-blue pointer">{row.name}</span>
                </Col>;
              })}
            </div>
            <DropzoneComponent onSubmit={onFileSubmit}/>
          </div>;
      case 'table':
        return null;
      default:
        return <CustomTableComponent
          exportButtons={exportButtons}
          useLocalPage
          actions={actions}
          columns={localState.columns}
          data={localState.data ?? []}
          loading={localState.isLoading === State.PENDING}/>;
    }
  };

  return (
    <div className="d-flex flex-column">

      {user?.config && user?.config?.sbx_crm && user?.config?.sbx_crm[configName] && !modelName &&
        <CustomTableComponent
          useLocalPage
          actions={tableActions}
          columns={[{name: 'label', header: 'Modelo'}]}
          data={user?.config ? Object.keys(user.config.sbx_crm[configName])
            .reduce((object: any[], key) => {
              const data = user?.config?.sbx_crm[configName];
              if (data && !Array.isArray(data[key])) {
                object.push({
                  ...data[key],
                  name: key
                })
              }
              return object;
            }, []) : []}/>}


      {localState.isLoading !== State.PENDING && modelName &&
        <div>
          {!isDetailView &&
            <>
              <div className="text-end mb-2">
                <Button
                  key={1}
                  color="primary"
                  onClick={() => handleProduct(true)}>
                  Agregar
                </Button>
              </div>

              <DataFilterComponent
                configName={configName}
                model={modelName}
                process={localState.process}
                handleData={handleData}
                modelData={localState.copyData ?? []}/>
            </>
          }
        </div>
      }

      {getDataByType()}
    </div>
  );
};

export default DataComponentList;
