/* eslint no-eval: 0 */
import {differenceInWeeks, format} from 'date-fns';
import {ListProvider, Task, TaskProcess, TaskState} from '../types/Task';
import cogoToast from 'cogo-toast';
import {FieldType, SubType} from '../types/FieldType';
import IField from '../types/FormBuilder/IField';
import {ColumnsLabels, Field} from '../types/Field';
import {getFormList} from '../services/backend/FormService';
import {getDataStoreList} from '../services/backend/ProcessService';
import {Permissions, PermissionsData} from '../types/Permissions';
import {EventProcess, GatewayProcess, ProcessModel, Sequence} from '../types/models/processModel/Process';
import {ApplyToSalesManager, SalesManagerConfig, SalesOrgConfig, UserData} from '../types/User';
import * as Sentry from '@sentry/nextjs';
import {Chart} from '../types/Chart';
import Router from 'next/router';
import pdfIcon from '../public/assets/icons/pdf-file.png';
import excelIcon from '../public/assets/icons/excel.png';
import jpgIcon from '../public/assets/icons/jpg.png';
import pngIcon from '../public/assets/icons/file-png.png';
import powerPointIcon from '../public/assets/icons/powerpoint.png';
import wordIcon from '../public/assets/icons/word.png';
import documentIcon from '../public/assets/icons/document.png';

// @ts-ignore
import cookieCutter from 'cookie-cutter';
import moment from 'moment';
import {Andor, Condition, GroupCondition, SbxConditionType, SbxResponse} from '../types/Sbx';
import {findByModel, getSbxModelFields} from '../services/backend/SbxService';
import {
  ActionFilterOperator,
  AnalyticQuery,
  AnalyticQueryAction,
  Report,
  Source,
  SourceFilter,
  SourceFrom
} from '../types/Analytic';
import {getFileData, getParseToken} from '../services/UtilsService';
import {TableFormColumn} from '../components/TaskComponent/TableForm/TableTaskComponent';
import {getProviderById, getProviderByIdWidthOptions} from '../services/backend/DataProviderService';
import {AnyData} from '../types/AnyData';
import {Item} from '../components/LayoutComponenents/BadgeComponent';
import {FilterTableReport} from '../components/Shared/FilterTableDataComponent/FilterTableDataComponent';
import {Column, CustomTableColumnType} from '../components/Shared/CustomTableComponent';
import {MultiReport} from '../pages/analytics/crm-reports/[reportKey]';
import {downloadFile} from '../services/backend/ContentService';
import {Response} from '../types/Response';
import {getAllGroups, getAllUsers} from '../services/backend/PermissionsService';
import {conditions} from '../components/RuleGenerator/Types';
import EventType from '../types/Workflow/EventType';
import {executeAnalyticJson, getAnalyticJsonColumns} from '../services/backend/AnalyticsService';
import {Query} from '../components/Shared/QueryComponent/QueryComponent';
import {Theme} from 'react-autosuggest';
import {BusinessDay} from '../store/Config/Slice';
import {UpdateSource} from '../components/ReportGeneratorComponent/SourceComponent/SourceComponent';
import {ProviderType} from "../types/ProviderType";
import {Find} from "sbxcorejs";
import store from "../store";
import {Content} from "../types/Folder/Content";

export const DEFAULT_SIZE = 15;

export function sliceObjects(array: any[], pageSize: number, currentPage: number) {
  return array.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
}

export function uuidV4(): string {
  return `${makeId()}-${makeId()}-${makeId()}-${makeId()}`;
}

export function makeId() {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export const checkMail = (mail: string) => {
  return mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

export function megabyteToByte(value?: number) {
  return 1024 * 1024 * (value || 10);
}

export function getType(type: SubType) {
  switch (SubType[type]) {
    case SubType.EMAIL:
      return 'email';

    case SubType.PASSWORD:
      return 'password';

    case SubType.NUMBER:
      return 'number';

    case SubType.TIME:
      return 'time';

    default:
      return 'text';
  }
}

export const getHtmlFromEditor = async (refEditor: any) => {
  return new Promise<string>(resolve => {
    refEditor.current.editor.exportHtml((dataEditor: any) => {
      resolve(dataEditor.html);
    });
  });
};

export const saveDesignFromEditor = async (refEditor: any) => {
  return new Promise<string>(resolve => {
    refEditor.current.editor.saveDesign((dataEditor: any) => {
      resolve(dataEditor);
    });
  });
};

export function stopPropagation(event: any) {
  event.stopPropagation();
}

export function getFieldName(field: Field, complete?: boolean) {
  let name = `${field.name}`
    .replace(new RegExp(`${field.form_id}_`, 'g'), '')
    .replace(new RegExp(`_${field.id}`, 'g'), '')
    .split('_').join(' ');
  return complete ? (`${(field.form_id ? `${field.form_id}_` : '') + name + (field.id ? `_${field.id}` : '')}`).split(' ').map(str => str).join('_') : name;

}

export function setNameToField(field: IField) {
  let newField = Object.assign({}, field);
  newField.name = getFieldName(field, true);
  return newField;
}

export const getDefaultValue = (field: IField) => {
  switch (field.field_type) {
    case 'OPTIONS':
    case 'DATE':
      return null;
    case 'DATE_RANGE':
      return {startDate: null, endDate: null};
    default:
      return '';
  }
};

export const getRandomColor = () => {
  return `#${Math.random().toString(16).substr(-6)}`;
};

export const getInitialLetter = (name: string) => {
  return name?.charAt(0)?.toUpperCase() ?? '';
};

export const formatDate = (date: string) => {
  return new Date(date);
};

export const convertDateToYYYYMMMDD = (date: string | number, locale = 'en') => {
  if (typeof date === 'number') date.toString();
  try {
    const date1 = new Date(date);
    return format(date1, 'yyyy-MMM-dd');
  } catch (e) {
    return '';
  }

};

export const convertDateToNumberDate = (date: Date, locale = 'en') => {
  // if (typeof date === 'number') date.toString();
  try {


    return format(date, 'yyyyMMdd');
    // return format(date, 'yyyy-MM-dd');
  } catch (e) {
    return '';
  }

};

export const convertDateToYYYYMMDD = (date: Date) => {
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (e) {
    return ""
  }

};

export const convertDateToYYYYMM = (date: Date) => {
  try {
    return format(date, 'yyyy-MM');
  } catch (e) {
    return ""
  }

};

export const convertDateToYYYYMMDDHHmm = (date: any) => {
  return format(date, 'yyyy-MM-dd HH:mm');
};

export function getFormatNumber(date: Date) {
  const h = date.getHours(), m = date.getHours();

  function formatT(n: number) {
    return n >= 10 ? n.toString() : (`0${n}`);
  }

  return `${formatT(h)}:${formatT(m)}`;
}

export function convertNumberAndTimeToDate(date: number, time: string) {
  const newDate = convertNumberDateToDate(date);
  const [hour, minutes] = time.split(':');
  newDate.setHours(parseInt(hour));
  newDate.setMinutes(parseInt(minutes));
  return newDate;
}

export const convertDateToYYYYMMDDHHmmaa = (date: any) => {
  return format(date, 'dd MMM yyyy HH:mm a');
};

export const convertDateToDDMMMYYYYHHmm = (date: any) => {
  return format(date, 'dd MMM yyyy p');
};

export const convertDateToDDMMMYYYY = (date: Date) => {
  return format(date, 'dd MMM yyyy');
};

export function deleteAllCookies() {
  let cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf('=');
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

export const getTaskState = (taskState: string, stateList: TaskState[]) => {
  return taskState ?? stateList?.find(state => state.start)?.name ?? 'Por hacer';
};

type ToastProps = { type?: 'success' | 'info' | 'loading' | 'warn' | 'error', message: string, options?: { position?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right', heading?: string } }

export const toast = ({type = 'success', message = "", options}: ToastProps) => {
  cogoToast[type](message, {...options, position: options?.position ?? 'bottom-left'});
};

export const getUnderscoreLabel = (label: string) => {
  label = label.split('_').join(' ');
  const splitStr = label.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(' ');
};

export const getUserName = (task: Task) => {
  return task.resource_name ?? task.resource_id ?? '';
};

export const getUnderscoreColumn = (column: string, value: string) => {
  return `object_${column}_${value}`;
};

export const getArrowPosition = (index: number) => {
  return index % 2 === 0 ? '10%' : index % 3 === 0 ? '50%' : '90%';
};

export enum InputTypes {
  text = 'text',
  number = 'number',
  options = 'options',
  multi_options = 'multi_options',
  textarea = 'textarea',
  boolean = 'boolean'
}

export interface PropertyMenu {
  label?: string,
  name: string,
  type: InputTypes,
  index?: number,
  key?: string;
  keyOption?: string,
  labelOption?: string,
  isVisible?: (form: { [key: string]: any }) => boolean;
  onChange?: (evt: { label: string, value: string }[]) => ({ name: string, value: string });
  onChangeReplace?: (menu: PropertyMenu[], option: { label: string, value: string }) => PropertyMenu[];
  options?: { label: string, value: string | number }[]
}

export enum ProcessModelItems {
  TASK = 'TASK',
  TASK_SCRIPT = 'TASK_SCRIPT',
  SEQ = 'SEQ',
  EVENT = 'EVENT'
}

export const EVENT_MENU: PropertyMenu[] = [{
  name: 'event_type',
  type: InputTypes.options,
  options: [{value: 'END', label: 'END'}, {value: 'START', label: 'START'}, {
    label: 'INTERMEDIATE_WAIT',
    value: 'INTERMEDIATE_WAIT',
  }, {value: 'INTERMEDIATE_DATA', label: 'INTERMEDIATE_DATA'}],
}, {name: 'name', type: InputTypes.text},
  {name: 'data_store_id', type: InputTypes.options, options: [], index: 0},
  {
    label: 'attachment',
    name: 'attachments',
    type: InputTypes.options,
    options: [],
    keyOption: 'attachment_id',
    labelOption: '',
    index: 0,
  }];

export const ACTION_TYPES = [
  {label: "positive", value: 1},
  {label: "negative", value: 2},
  {label: "neutral", value: 0}
]

export const TASK_MENU: PropertyMenu[] = [
  {name: 'duration', type: InputTypes.number},
  {name: 'name', type: InputTypes.text},
  {name: 'label', type: InputTypes.text},
  {name: 'description', type: InputTypes.textarea},
  {name: 'rule', type: InputTypes.text},
  {
    name: 'assigment_type',
    type: InputTypes.options,
    options: [{label: 'USER', value: 'USER'}, {value: 'GROUP', label: 'GROUP'}, {value: 'COMPLEX', label: 'COMPLEX'}],
    onChangeReplace: (menu, option) => {

      return menu.map(menuI => {
        if (menuI.name === 'rule') {
          if (option.value === 'USER') {
            menuI = {
              ...menuI, type: InputTypes.multi_options, options: [], keyOption: 'user_list', onChange: (evt) => {
                const values = evt.map(event => event.value)
                return {name: 'rule', value: `process["__result"] = {'users':[${values}]}`};
              }
            };
          } else if (option.value === 'GROUP') {
            menuI = {
              ...menuI, type: InputTypes.multi_options, options: [], keyOption: 'group_list', onChange: (evt) => {
                const values = evt.map(event => event.value)
                return {name: 'rule', value: `process["__result"] = {'groups':[${values}]}`};
              }
            };
          } else {
            menuI = {...menuI, type: InputTypes.text};
          }
        }
        return menuI;
      });
    },
    isVisible: (form) => {
      return form ? form['task_type'] === 'USER' : false;
    }
  },
  {
    name: 'task_type',
    type: InputTypes.options,
    options: [{label: 'USER', value: 'USER'}, {value: 'SCRIPT', label: 'SCRIPT'}],
  },
  {
    name: 'activity_type',
    type: InputTypes.options,
    options: [{label: 'TASK', value: 'TASK'}, {label: 'TASK_SCRIPT', value: 'TASK_SCRIPT'}],
  },
  {
    label: 'attachment',
    name: 'attachments',
    type: InputTypes.options,
    options: [],
    keyOption: 'attachment_id',
    labelOption: '',
    index: 0,
  },
  {
    name: 'finish_assignments',
    type: InputTypes.boolean,
  },

];

export const SEQ_MENU: PropertyMenu[] = [
  {name: 'label', type: InputTypes.text},
  {name: 'name', type: InputTypes.text},
  {name: 'inline', type: InputTypes.boolean},
  {
    label: 'action_type',
    name: 'action_type',
    type: InputTypes.options,
    options: [{label: '0', value: 0}, {label: '1', value: 1}, {value: '2', label: '2'}],
  },

];

export const getNode: any = (processModel: ProcessModel, id: string) => {

  if (processModel) {
    return {
      TASK: processModel.tasks.find(task => (task.id && task.id.toString() === id) || (task.id_manager === id)),
      TASK_SCRIPT: processModel.tasks.find(task => (task.id && task.id.toString() === id) || (task.id_manager === id)),
      EVENT: processModel.events.find(event => (event.id && event.id.toString() === id) || (event.id_manager === id)),
      SEQ: processModel.sequences.find(sequence => (sequence.id && sequence.id.toString() === id) || (sequence.id_manager === id)),
    };
  } else {
    return {};
  }
};

export const updateNodeProcessModel = ({
                                         processModel,
                                         id,
                                         node,
                                         type,
                                       }: { processModel: ProcessModel, id: number | string, node: any, type: string }) => {

  if (processModel) {
    switch (type) {
      case 'TASK': {
        let arr = [...processModel?.tasks];
        const isNew = !arr.some(task => (task.id && task.id.toString() === id) || task.id_manager === id);
        if (isNew) {
          arr.push({
            ...node,
            task_type: node.task_type ?? 'USER',
            activity_type: node.activity_type ?? 'TASK',
          });
        } else {
          arr = arr.map(task => {
            if ((task.id && task.id.toString() === id) || task.id_manager === id) {
              task = {...task, ...node, manager_style: '{}'};
            }
            return task;
          });
        }
        return {...processModel, tasks: arr};
      }

      case 'TASK_SCRIPT': {
        let arr = [...processModel?.tasks];
        const isNew = !arr.some(task => (task.id && task.id.toString() === id) || task.id_manager === id);
        if (isNew) {
          arr.push({
            ...node,
            task_type: node.task_type ?? 'USER',
            activity_type: node.activity_type ?? 'TASK',
          });
        } else {
          arr = arr.map(task => {
            if ((task.id && task.id.toString() === id) || task.id_manager === id) {
              task = {...task, ...node, manager_style: '{}'};
            }
            return task;
          });
        }
        return {...processModel, tasks: arr};
      }

      case 'EVENT': {
        let arr = [...processModel?.events];
        const isNew = !arr.some(event => (event.id && event.id.toString() === id) || event.id_manager === id);

        if (isNew) {
          arr.push(node);
        } else {
          arr = arr.map(event => {
            if ((event.id && event.id.toString() === id) || event.id_manager === id) {
              event = {...event, ...node, manager_style: '{}'};
            }

            return event;
          });
        }

        return {...processModel, events: arr};
      }
      case 'SEQ': {
        let arr = [...processModel?.sequences];
        const isNew = !arr.some(sequence => (sequence.id && sequence.id.toString() === id) || sequence.id_manager === id);

        if (isNew) {
          arr.push(node);
        } else {
          arr = arr.map(sequence => {
            if ((sequence.id && sequence.id.toString() === id) || sequence.id_manager === id) {
              sequence = {...sequence, ...node, manager_style: '{}'};
            }

            return sequence;
          });
        }

        return {...processModel, sequences: arr};
      }
      default:
        return null;
    }
  }

  return null;
};

export const isInitialNode = (id: string) => id.startsWith('Activity') || id.startsWith('Event') || id.startsWith('Flow');

// export const getNodeType = (type: string) => {
//   switch (type) {
//     case 'Activity':
//       return 'TASK';
//     case 'Event':
//       return 'EVENT';
//     case 'Flow':
//       return 'SEQ';
//     default:
//       return type;
//   }
// };

export type EVENT_TYPE = 'START' | 'END' | 'INTERMEDIATE_WAIT' | 'INTERMEDIATE_DATA';

// export const getNodeTypeId = (type: string, id: string) => {
//   switch (type) {
//     case 'Activity':
//       return id.replace('Activity', getNodeType(type));
//     case 'Event':
//       return id.replace('Event', getNodeType(type));
//     case 'Flow':
//       return id.replace('Flow', getNodeType(type));
//     default:
//       return id;
//   }
// };

// export const getNodeTypeFull = (id: string) => {
//   let nodeType = id?.toString().split('_');
//   const typeTemp = nodeType.length > 2 ? nodeType.slice(0, nodeType.length - 1).join('_') : nodeType[0];
//   return getNodeType(typeTemp);
// };

// export const getNodeIdNumber = (id: string, type?: string) => {
//
//   const isInitial = isInitialNode(id);
//
//   if (!isInitial) {
//     const typeArr = id.split('_');
//     return parseInt(typeArr[typeArr.length - 1]);
//   } else if (type) {
//     return getNodeTypeId(type, id);
//   } else {
//     return id;
//   }
// };

export const getProcessModelItemType = (type: ProcessModelItems) => {
  switch (type) {
    case ProcessModelItems.TASK:
      return 'tasks';
    case ProcessModelItems.EVENT:
      return 'events';
    case ProcessModelItems.SEQ:
      return 'sequences';
    case ProcessModelItems.TASK_SCRIPT:
      return 'tasks';
    default:
      return '';
  }
};

export const eventTypesToBpmnType = {
  START: 'StartEvent',
  END: 'EndEvent',
  INTERMEDIATE_WAIT: 'IntermediateThrowEvent',
  INTERMEDIATE_DATA: 'IntermediateThrowEvent',
};

export const getEventType = (type: string) => {

  const typeShape = type.toLowerCase().split(':')[1];
  type = typeShape ?? type;

  switch (type) {
    case 'startEvent':
      return 'START';
    case 'startevent':
      return 'START';
    case 'endEvent':
      return 'END';
    case 'endevent':
      return 'END';
    case 'intermediatethrowevent':
      return 'INTERMEDIATE_WAIT';
    case 'intermediateThrowEvent':
      return 'INTERMEDIATE_WAIT';
    default:
      return type;
  }
};

// export const getNewNode = (type: string, data: any) => {
//   switch (type) {
//     case ProcessModelItems.TASK: {
//       const id = getNodeTypeId('Activity', data.id);
//       return {
//         name: id,
//         label: id,
//         id_manager: id,
//         id: data.id,
//       };
//     }
//     case ProcessModelItems.EVENT: {
//       const id = getNodeTypeId('Event', data.id);
//       return {
//         name: id,
//         label: id,
//         id_manager: id,
//         event_type: getEventType(data.type),
//         id: data.id,
//       };
//     }
//     case ProcessModelItems.SEQ:
//
//       let typeSource = data.businessObject.source.id?.split('_')[0];
//       const idSource = getNodeIdNumber(data.businessObject.source.id, typeSource);
//       typeSource = getNodeType(typeSource);
//
//       let typeTarget = data.businessObject.target.id?.split('_')[0];
//       const idTarget = getNodeIdNumber(data.businessObject.target.id, typeTarget);
//
//       typeTarget = getNodeType(typeTarget);
//
//       let seqData = {
//         id: data.id,
//         id_manager: getNodeTypeId('Flow', data.id),
//
//         from_item_type: typeSource,
//         to_item_type: typeTarget,
//       };
//
//       const sourceKey = typeof idSource === 'number' ? 'from_item_id' : 'from_item_id_manager';
//       const targetKey = typeof idTarget === 'number' ? 'to_item_id' : 'to_item_id_manager';
//
//       return {
//         ...seqData,
//         [sourceKey]: idSource,
//         [targetKey]: idTarget,
//       };
//     default:
//       return {};
//   }
// };

export const getListService = async ({process_id}: { process_id?: number }) => {
  return {
    attachment_id: await getFormList().then(res => {
      if (res.success) {
        res.items = res.items?.sort((a, b) => a.name.localeCompare(b.name));
      }
      return res;
    }).catch(error => console.error(error)),
    data_store_id: process_id ? await getDataStoreList(process_id) : null,
    user_list: await getAllUsers().then(res => {
      res.items = res.items?.map(user => ({
        name: user.full_name,
        id: user.id
      })).sort((a, b) => a.name.localeCompare(b.name));
      return res;
    }),
    group_list: await getAllGroups().then(res => {
      res.items = res.items?.sort((a, b) => a.name.localeCompare(b.name));
      return res;
    })
  };
};

export const getMenuLists = async ({
                                     menu,
                                     listService,
                                     process_id
                                   }: { menu: PropertyMenu[], process_id: number, listService?: { [key: string]: any[] } }) => {

  const nListService: any = listService ?? await getListService({process_id});

  const newMenu = menu.map(menuItem => {
    if (menuItem.type === InputTypes.options || menuItem.type === InputTypes.multi_options) {
      const keyName = menuItem.keyOption ?? menuItem.name;

      if (nListService[keyName]?.success) {
        menuItem = {
          ...menuItem,
          options: nListService[keyName].items.map((form: any) => ({label: form.name, value: form.id})),
        };
      }
    }

    return {...menuItem, key: uuidV4()};
  });

  return {newMenu, nListService}
};

export const getMenu = (node_id: string) => {
  const type = node_id?.split('_')[0];
  switch (type) {
    case ProcessModelItems.TASK:
      return TASK_MENU;
    case ProcessModelItems.TASK_SCRIPT:
      return TASK_MENU;
    case 'Activity':
      return TASK_MENU;
    case ProcessModelItems.EVENT:
      return EVENT_MENU;
    case 'Event':
      return EVENT_MENU;
    case 'Flow':
      return SEQ_MENU;
    case ProcessModelItems.SEQ:
      return SEQ_MENU;
    default:
      return [];
  }
};

export const getVariableDefaultValue = (defaultValue: string) => {
  return defaultValue.replace(/{*}*\$*/g, '');
};

export const isVarExpression = (defaultValue: string) => {
  return defaultValue.includes('+') || defaultValue.includes('-') || defaultValue.includes('*') || defaultValue.includes('/');
};

export const calculateExpression = (expression: string) => {
  try {
    return Function('"use strict";return (' + expression + ')')();
  } catch (e) {
    return null;
  }
};

export const evalExpression = (expression: string) => {
  const result = calculateExpression(expression);

  if ((result || result === 0) && !isNaN(result)) {
    return calculateExpression(expression);
  }

  return '';
};

export function downloadTextToFile(text: string, type: 'html' | 'txt' | 'csv' | string, name?: string) {
  let makeTextFile = function (text: string) {
    const data = new Blob([text], {type: 'text/' + type});
    return window.URL.createObjectURL(data);
  };
  const url = makeTextFile(text);
  let a = document.createElement('a');
  a.href = url;
  a.download = name || 'unassigned' + `.${type}`;
  a.click();
}

function getIteratorToTable(type: number, name: string) {
  switch (type) {
    case 1:
      return (`<table class="table">
                    <thead>
                     <th style=" border-bottom: 1px solid #ddd; padding: 10px">${capitalize(name)}</th>
                    </thead>
                    <tbody>
                     <% data.${name}.forEach(function(item) { %>
                      <tr>
                       <td style=" border-bottom: 1px solid #ddd; padding: 10px"><%= item %></td>
                      </tr>
                     <%}); %>
                    </tbody>
                </table>`);
    case 2:

      return (`<table class="table">
                    <thead>
                     <tr>
                     <% Object.keys(data.${name}[0]).forEach(function(key) { %>
                       <th style=" border-bottom: 1px solid #ddd; padding: 10px; min-width: 120px"><%= key %></th>
                     <%}); %>
                      </tr>
                    </thead>
                    <tbody>
                     <% data.${name}.forEach(function(item) { %>
                      <tr>
                      <% Object.keys(data.${name}[0]).forEach(function(key) { %>
                       <td style=" border-bottom: 1px solid #ddd; padding: 10px">
                       <% if (item[key]) { %>
                        <%= item[key] %>
                       <% } %>
                       </td>
                       <%}); %>
                      </tr>
                      <%}); %>
                    </tbody>
                </table>`);
  }
}

export function replaceValues(html: string) {
  html = html
    .replace(RegExp('&lt;', 'g'), '<')
    .replace(RegExp('&gt;', 'g'), '>');

  try {
    //table iterator
    if (html.includes("@table")) {
      console.log("transform table to iterator.")
      const tables: { toReplace: string, iterator: string }[] = html
        .split("@table")
        .reduce((a: any[], b: string, i) => {
          //Ignoring first position
          if (i) {
            const label = b.split(");").shift();
            if (label) {
              const name = label.split(".").pop();
              if (name) {
                a.push(
                  {
                    toReplace: `@table${label});`,
                    iterator: getIteratorToTable(label.includes("All") ? 2 : 1, name)
                  })
              }
            }
          }
          return a;
        }, []);
      tables.forEach(e => {
        html = html.replace(e.toReplace, e.iterator);
      })
    }
  } catch (e) {
    console.error(e);
  }
  return html
}

export function capitalize(str: string = '', config?: { lowerCase?: boolean }) {
  if (str) {
    if (config?.lowerCase) return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
}

export function splitJoinText(text: string, props?: AnyData) {
  return text.split('_').join(' ');
}

export const redirect404 = () => {
  return {
    redirect: {
      destination: '/404',
      permanent: false,
    },
  };
};

export const removeDuplicateFromArrayObj = (arr: any[], key: string) => {
  const seen = new Set();

  return arr.filter((element) => {
    const duplicate = seen.has(element[key]);
    seen.add(element[key]);
    return !duplicate;
  });
};

export const removeDuplicateFromArrayDeepObj = (arr: any[], key: string) => {
  const seen = new Set();

  return arr.filter((element) => {
    const duplicate = seen.has(getObjValueInDeep(element, key));
    seen.add(getObjValueInDeep(element, key));
    return !duplicate;
  });
};


export const getModulesWithPermissions = (permissions: PermissionsData[]) => {
  const m = permissions.reduce((obj: any, data) => {
    if (!obj[data.module_name]) {
      obj[data.module_name] = [];
    }

    //validate if the permission already exist in the list
    if (!obj[data.module_name].find((e: any) => e.id === data.id)) {
      obj[data.module_name].push(data);
    }

    return obj;
  }, {});

  return Object.keys(m).map(e => ({module_name: e, permissions: m[e]}));
};

export const permissionListToMap = (permissions: PermissionsData[]) => {
  let permissionsMapper = permissions.reduce((object: { [key: string]: PermissionsData | Permissions }, data: PermissionsData) => {
    if (!object[data.module_name]) {
      object[data.module_name] = data.module_name as Permissions;
    }
    const per = `${data.module_name}_${data.permission}`;
    object[per] = data;
    return object;
  }, {});
  permissionsMapper[Permissions.NO_VALIDATE] = Permissions.NO_VALIDATE;
  return {permissionsMapper};
};

export function isObject(data?: any): boolean {
  return typeof data === "object";
}

export function toMap(array: any[], key: string) {

  return array.reduce((obj: any, row: any) => {
    obj[row[key]] = row;
    return obj;
  }, {})
}

export const grantPermission = (permission: Permissions | Permissions[], mapper: string | any, id?: number) => {
  try {
    if (typeof mapper === 'string') {
      mapper = JSON.parse(mapper);
    }
    const somePermission = Array.isArray(permission) ? permission.find(per => mapper[per]) : mapper[permission];

    if (isObject(somePermission)) {
      const metadata = (somePermission as PermissionsData).metadata;
      if (metadata) {
        if (metadata.length) {
          const allAllow = metadata.reduce((allows: number[], meta) => {
            if (meta.includes("allow")) {
              const newMeta = toJSON(meta) as ({ allow?: number[] | string[] });
              if (newMeta?.allow) {
                newMeta.allow.forEach(key => {
                  if (typeof key === "number") {
                    allows.push(key);
                  }
                });
              }
            }
            return allows;
          }, []);

          if (allAllow.length && id) {
            return allAllow.includes(id);
          }
        }
      }
    }

    return !!somePermission;
  } catch (e) {
    return false;
  }
};

export function filterArray(array: any[], value: string) {
  return array.filter(gr => {
    return Object.keys(gr).some(key => (`${gr[key]}`).toLowerCase().indexOf(value.toLowerCase()) !== -1);
  });
}

export function filterArrayString(array: string[], value: string) {
  return array.filter(gr => {
    return gr.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  });
}

export function downloadFileUtil(file: File, name: string) {
  let a = document.createElement('a');
  a.href = URL.createObjectURL(file);
  a.setAttribute('download', `${name}`);
  a.click();
}

export const localeEn = {
  format: '{reason} at line {line}',
  symbols: {
    colon: 'colon',           // :
    comma: 'comma',           // ,  ،  、
    semicolon: 'semicolon',   // ;
    slash: 'slash',           // /  relevant for comment syntax support
    backslash: 'backslash',   // \  relevant for escaping character
    brackets: {
      round: 'round brackets',   // ( )
      square: 'square brackets', // [ ]
      curly: 'curly brackets',   // { }
      angle: 'angle brackets'    // < >
    },
    period: 'period',          // . Also known as full point, full stop, or dot
    quotes: {
      single: 'single quote', // '
      double: 'double quote', // "
      grave: 'grave accent'   // ` used on Javascript ES6 Syntax for String Templates
    },
    space: 'space',           //
    ampersand: 'ampersand',   // &
    asterisk: 'asterisk',     // *  relevant for some comment sytanx
    at: 'at sign',            // @  multiple uses in other coding languages including certain data types
    equals: 'equals sign',    // =
    hash: 'hash',             // #
    percent: 'percent',       // %
    plus: 'plus',             // +
    minus: 'minus',           // −
    dash: 'dash',             // −
    hyphen: 'hyphen',         // −
    tilde: 'tilde',           // ~
    underscore: 'underscore', // _
    bar: 'vertical bar',      // |
  },
  types: { // ... Reference: https://en.wikipedia.org/wiki/List_of_data_structures
    key: 'key',
    value: 'value',
    number: 'number',
    string: 'string',
    primitive: 'primitive',
    boolean: 'boolean',
    character: 'character',
    integer: 'integer',
    array: 'array',
    float: 'float',
  },
  invalidToken: {
    tokenSequence: {
      prohibited: '\'{firstToken}\' token cannot be followed by \'{secondToken}\' token(s)',
      permitted: '\'{firstToken}\' token can only be followed by \'{secondToken}\' token(s)'
    },
    termSequence: {
      prohibited: 'A {firstTerm} cannot be followed by a {secondTerm}',
      permitted: 'A {firstTerm} can only be followed by a {secondTerm}'
    },
    double: '\'{token}\' token cannot be followed by another \'{token}\' token',
    useInstead: '\'{badToken}\' token is not accepted. Use \'{goodToken}\' instead',
    unexpected: 'Unexpected \'{token}\' token found'
  },
  brace: {
    curly: {
      missingOpen: 'Missing \'{\' open curly brace',
      missingClose: 'Open \'{\' curly brace is missing closing \'}\' curly brace',
      cannotWrap: '\'{token}\' token cannot be wrapped in \'{}\' curly braces'
    },
    square: {
      missingOpen: 'Missing \'[\' open square brace',
      missingClose: 'Open \'[\' square brace is missing closing \']\' square brace',
      cannotWrap: '\'{token}\' token cannot be wrapped in \'[]\' square braces'
    }
  },
  string: {
    missingOpen: 'Missing/invalid opening string \'{quote}\' token',
    missingClose: 'Missing/invalid closing string \'{quote}\' token',
    mustBeWrappedByQuotes: 'Strings must be wrapped by quotes',
    nonAlphanumeric: 'Non-alphanumeric token \'{token}\' is not allowed outside string notation',
    unexpectedKey: 'Unexpected key found at string position'
  },
  key: {
    numberAndLetterMissingQuotes: 'Key beginning with number and containing letters must be wrapped by quotes',
    spaceMissingQuotes: 'Key containing space must be wrapped by quotes',
    unexpectedString: 'Unexpected string found at key position'
  },
  noTrailingOrLeadingComma: 'Trailing or leading commas in arrays and objects are not permitted'
};

export const setUserSentry = (userData: UserData) => {
  Sentry.setUser({
    email: userData.email,
    user_id: userData.id,
    full_name: userData.full_name,
    is_admin: userData.isAdmin
  });
};

export function recursiveChart(item: Chart, {
  dep,
  update
}: { dep?: (chart: Chart) => void | any, update?: { field: 'user' } }) {
  function recursive(chart: Chart, dep?: any) {
    if (dep) {
      if (update) {
        chart[update.field] = dep(chart);
      } else {
        dep(chart);
      }
    }
    chart.items.forEach(c => recursive(c, dep));
  }

  recursive(item, dep);
  return item;
}

export const getObjValueInDeep = (obj: { [key: string]: any }, deepProperties: string) => {
  try {

    const properties = deepProperties.split('.');

    properties.forEach(property => {

      if (obj[property] || !!obj[property] || typeof obj[property] === 'boolean' || typeof obj[property] === 'number') {
        obj = obj[property];
      }

      if (obj[property] === null) {
        obj[property] = null;
        obj = obj[property];
      }


    });


    return typeof obj === 'object' ? '' : (obj ?? '') as any;
  } catch (e) {
    return '';
  }
};
export const getArrayValueInDeep = (obj: { [key: string]: any }, deepArrayProperties: string[]) => {

  try {
    deepArrayProperties.forEach(property => {

      if (obj[property] === '') {
        obj[property] = '';
      }

      if (obj[property]) {
        obj = obj[property];
      }

      if (obj[property] === null) {
        obj[property] = null;
        obj = obj[property];
      }


    });

    return (obj ?? '') as any;
  } catch (e) {
    return '';
  }
};

export const getLastDayOfMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0);
};

export const getMonthTwoDigits = (month: number) => {
  return month < 10 ? '0' + month : month;
};

export const routeKey = () => Router.pathname.toString().replace(RegExp('/', 'g'), '');

export const saveCookieData = (value: string, key: string) => {
  let history: any = JSON.parse(sessionStorage.getItem(key) ?? '{}');
  history[routeKey()] = value;
  sessionStorage.setItem(key, JSON.stringify(history));
};

export const getCookieData = (key: string) => {
  let history: any = JSON.parse(sessionStorage.getItem(key) ?? '{}');
  return history[routeKey()] ?? '';
};

export const getDifferenceDays = (date1: Date, date2: Date) => {
  try {
    const dateStart = moment(date1); //todays date
    const dateUpdate = moment(date2); // another date
    const days = dateUpdate.diff(dateStart, 'days');

    return `${days} ${(days > 1 || days === 0) ? 'Días' : 'Día'}`;
    // return moment.duration(dateStart.diff(dateUpdate)).asDays();
  } catch (e) {
    return '';
  }

};

export const getDifferenceDaysNumber = (date1: Date, date2: Date) => {
  try {
    const dateStart = moment(date1); //todays date
    const dateUpdate = moment(date2); // another date
    return dateUpdate.diff(dateStart, 'days');
  } catch (e) {
    return 0;
  }
};

export const thousandFormat = function (num: number | string) {
  return num.toString()
    .split('')
    .reverse()
    .join('')
    .replace(/(?=\d*\.?)(\d{3})/g, '$1.')
    .split('')
    .reverse()
    .join('')
    .replace(/^[.]/, '');
};

export const getFetchedResults = (response: SbxResponse, fetch: string[]) => {
  if (response?.fetched_results && Object.keys(response.fetched_results)?.length > 0) {
    for (let fetched of fetch) {
      let entities = fetched.split('.');
      for (const item of (response?.results || [])) {
        const nItem: any = item;
        for (const fetched_result in response.fetched_results) {
          // Fetched, get the base reference if exist another one.
          fetched = entities.length > 1 ? entities[0] : fetched;
          if (nItem[fetched] in response.fetched_results[fetched_result]) {
            nItem[fetched] = response.fetched_results[fetched_result][nItem[fetched]];
          }
        }
        // Use entities since 2 position, because the first is the base and the rest is the references.
        // Ex: ["customer.company"]
        if (entities.slice(1,).length > 0) {
          for (const entity of entities.slice(1,)) {
            for (const fetched_result in response.fetched_results) {
              if (nItem[fetched] && nItem[fetched].hasOwnProperty(entity) && response.fetched_results.hasOwnProperty(fetched_result) &&
                nItem[fetched][entity] in response.fetched_results[fetched_result]) {
                nItem[fetched][entity] = response.fetched_results[fetched_result][nItem[fetched][entity]];
              }
            }
          }
        }
      }
    }
  }
  return response;
};

export const isDefaultVarExpression = (default_value: string) => {
  return (default_value.toString()).includes('${');
};

export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export const fullMonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December'];

export const removeDuplicateFromArray = (array: any[]) => {
  return array.filter((c, index) => {
    return array.indexOf(c) === index;
  });
};

export function convertNumberDateToDate(number_date: number | string) {
  try {
    let string_date = number_date.toString();
    const year = string_date.slice(0, 4);
    const month = string_date.slice(4, 6);
    const day = string_date.slice(6, 8);
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  } catch (e) {
    return new Date();
  }
}

export function convertTimeToFormatHHMM(time: number) {
  console.log(time);
  if (!time || time.toString().length > 4) {
    return '';
  }
  const format = time.toString().split('');
  const mm = format.slice(2, 3);
  const hh = format.slice(0, 1);
  return `${hh}:${mm}`;
}

export function convertHHMMToNumber(value: string) {
  return parseInt(value.split(':').join(''));
}

export const getSbxItemByKey = async ({key, model, fetch}: {
  model: string, key: string, fetch?: string[]
}) => {

  const where = [{
    'ANDOR': 'AND',
    'GROUP': [{'ANDOR': 'AND', 'FIELD': '_KEY', 'VAL': key, 'OP': '='}]
  }];

  return await getSbxModelFields({
    provider: {
      name: model,
      query: JSON.stringify({WHERE: where, FETCH: fetch ?? []})
    }
  });
};

export const removeBracketsFromString = (str: string) => {
  return str.replace(/[\])}[{(]/g, '').trim();
};

export const addTokenToAnalyticQuery = (query: AnalyticQuery) => {
  const token = localStorage.getItem('crm_token');

  if (token) {
    if (query?.source?.from === 'sbx-workflow' || query?.source?.from === 'sbx-crm-user') {
      query.source.override_auth = getParseToken(token);
    }

    if (query?.source?.sources && query.source.sources.length > 0) {
      query.source.sources.forEach(source => {
        if (source.from === 'sbx-workflow' || source.from === 'sbx-crm-user') {
          source.override_auth = getParseToken(token);
        }
      });
    }

    if (query.actions && query.actions.length > 0) {
      query.actions.forEach(action => {
        if (action.type === 'merge' && action.source) {
          if (action.source.from === 'sbx-workflow' || action.source.from === 'sbx-crm-user') {
            action.source.override_auth = getParseToken(token);
          }
        }
      });
    }
  }


  return query;
};

export function filterData(arr: any[], value: string) {
  try {
    return arr.filter(data => Object.keys(data).some(e => (data[e] ?? '').toString().toLowerCase().includes(value.toLowerCase())));
  } catch (e) {
    console.log(e);
    return arr;
  }
}


export const getDefaultVarsFromStr = (str: string) => {
  return (str ?? '').match(/\${[^}]*}/g);
};

export const getDynamicListProviderQuery = ({list_provider, getFormValues, getValue, formState, isDefaultQuery}: {
  list_provider: ListProvider,
  formState?: { [key: string]: string | number }, getFormValues?: (value: any) => any, getValue?: (value: any) => any, isDefaultQuery?: boolean
}) => {

  const varList = getDefaultVarsFromStr(list_provider.query);


  if (varList && varList.length > 0) {
    let isVarNull = false;

    for (const defaultVar of varList) {
      const defaultValue = getVariableDefaultValue(defaultVar);
      if (((formState && formState[defaultValue]) || (getFormValues && getFormValues(defaultValue)) || (getValue && getValue(defaultValue))) && list_provider) {
        let value: any = null;

        if (getFormValues && getFormValues(defaultValue)) {
          value = getFormValues(defaultValue);
        } else if (formState && formState[defaultValue]) {
          value = formState[defaultValue];
        } else {
          if (getValue && getValue(defaultValue)) {
            value = getValue(defaultValue)
          }
        }

        // const value = formState ? formState[defaultValue] : getFormValue ? getFormValue(defaultValue) : null
        if (value) {

          // special case when is an object by process data {name, value}
          if (value?.value) {
            value = value.value
          }


          if (IsJsonString(value) && Array.isArray(JSON.parse(value))) {
            value = JSON.parse(value)
            const providerQuery: Query = JSON.parse(list_provider.query)
            // providerQuery[defaultVar as string ] = value
            providerQuery.where = providerQuery.where.map(where => {
              const group = where.GROUP.map(group => {
                if (group.VAL === defaultVar) {
                  group.VAL = value
                }
                return group
              })
              return {...where, GROUP: group}
            })

            list_provider.query = JSON.stringify(providerQuery)

          } else {
            list_provider = {
              ...list_provider,
              query: list_provider.query.replace(defaultVar, value)
            };
          }

        }

      } else {
        if (isDefaultQuery) {

          list_provider = {
            ...list_provider,
            query: list_provider.query.replace(defaultVar, "")
          };
        } else {
          isVarNull = true;
          break;
        }

      }
    }
    if (isVarNull) {

      return null;
    }

    return list_provider;

  } else {
    return list_provider;
  }
};

export const parseString = (str: string) => {
  return str.replace('"', '');
};

export const getProviderOptions = async ({
                                           list_provider,
                                           formState,
                                           header,
                                           getFormValue
                                         }: { list_provider: ListProvider, header?: TableFormColumn, formState?: { [key: string]: string }, getFormValue?: (value: any) => void }) => {
  if (list_provider?.id) {

    if (list_provider.provider_type === ProviderType.DATABASE) {

      const responseOptions = await getProviderByIdWidthOptions(list_provider.id);
      if (responseOptions.success) {
        return {...responseOptions, items: responseOptions.item?.options, row_model: header?.column ?? ''};
      } else {
        return responseOptions;
      }
    } else {

      const new_list_provider = getDynamicListProviderQuery({list_provider, formState, getFormValues: getFormValue});

      if (new_list_provider) {

        let responseSbx: SbxResponse<ListProvider> | null = null

        if (new_list_provider.provider_type === ProviderType.SBX_SEQUENTIAL_QUERY) {
          if (IsJsonString(new_list_provider.query)) {
            const query: Query = JSON.parse(new_list_provider.query)
            responseSbx = await getParallelQueryProvider(query, new_list_provider)
          }
        } else {
          responseSbx = await getSbxModelFields({
            provider: {...new_list_provider},
            findUrl: 'find_all'
          });
        }


        if (responseSbx && responseSbx?.success) {
          const row_model = header?.column ?? JSON.parse(new_list_provider.query).row_model ?? JSON.parse(new_list_provider.query).ROW_MODEL;
          return {...responseSbx, items: responseSbx.results, provider_response: list_provider, row_model};
        } else {
          return responseSbx;
        }
      } else {
        if (list_provider.default_query) {
          const varList = getDefaultVarsFromStr(list_provider.default_query)
          let provider: ListProvider | null = {...list_provider}
          if (varList && varList.length > 0) {
            provider = getDynamicListProviderQuery({
              list_provider: {
                ...list_provider,
                query: list_provider.default_query
              }, formState, isDefaultQuery: true
            });
            if (provider) {
              provider.default_query = provider.query
            } else {
              provider = {...list_provider}
            }
          }


          if (provider && provider.default_query) {
            provider = {
              ...provider,
              query: provider.default_query
            }

            let responseSbx: SbxResponse<ListProvider> | null = null

            if (provider.provider_type === ProviderType.SBX_SEQUENTIAL_QUERY) {
              if (IsJsonString(provider.query)) {
                const query: Query = JSON.parse(provider.query)
                responseSbx = await getParallelQueryProvider(query, provider)
              }
            } else {
              responseSbx = await getSbxModelFields({
                findUrl: 'find_all',
                provider
              });
            }

            if (responseSbx && responseSbx?.success && provider.default_query) {
              const row_model = header?.column ?? JSON.parse(provider.default_query).row_model ?? JSON.parse(provider.default_query).ROW_MODEL;
              return {...responseSbx, items: responseSbx.results, provider_response: provider, row_model};
            } else {
              return responseSbx;
            }

          } else {
            return {success: true, items: []};
          }
        }
        return {success: true, items: []};
      }
    }
  }
};

export const getParallelQueryProvider = async (query: Query, provider: ListProvider) => {
  let index = 0
  let stopFetch = false
  let responseSbx: SbxResponse<ListProvider> | null = null

  while (!stopFetch) {
    responseSbx = await getSbxModelFields({
      findUrl: 'find_all',
      provider: {
        ...provider,
        query: JSON.stringify({...query, where: [query.where[index]]})
      }
    });

    if (!responseSbx?.success || (responseSbx.success && responseSbx.results && responseSbx.results?.length > 0)) {
      stopFetch = true
    }

    if ((index + 1) <= query.where.length) {
      index++
    } else {
      stopFetch = true
    }
  }

  return responseSbx

}

export const getAllDataByProvider = async ({
                                             provider_id,
                                             header,
                                             formState,
                                             getFormValue
                                           }: { provider_id: string, header?: TableFormColumn, formState?: { [key: string]: string }, getFormValue?: (value: any) => void }) => {
  const response = await getProviderById(parseInt(provider_id));
  if (response.success && response.item) {
    const varList = getDefaultVarsFromStr(response.item.query);
    if (varList && varList.length > 0 && !formState) {
      return {success: true, items: []};
    } else {
      return await getProviderOptions({list_provider: response.item, header, formState, getFormValue});
    }
  }
};

export const getColumnValueFromRules = ({columns, isProvider}: { isProvider?: boolean, columns: ColumnsLabels[] }) => {
  return isProvider ? columns[0].key_from_value ? columns[0].value[0] : '_KEY' : '_KEY';
};

export const getCompoundName = ({
                                  columns,
                                  item,
                                  custom_compound_name
                                }: { columns?: ColumnsLabels[], item: any, custom_compound_name?: string }) => {


  if (columns) {
    const {name, compound_name} = columns[0];
    if (compound_name) {
      return getInterpretVar({item, strVar: compound_name});
    }

    return (name && item) ? (item[name] ?? getObjValueInDeep(item, name)) : '';
  } else {
    if (custom_compound_name) {
      return getInterpretVar({item, strVar: custom_compound_name});
    }
  }


  return "";
};

export const getInterpretVar = ({strVar, item, inDeep = true}: { item: any, strVar: string, inDeep?: boolean }) => {
  const varList = getDefaultVarsFromStr(strVar);
  if (varList && varList.length > 0) {
    let label = strVar;
    varList.forEach(strVar => {
      const nameVar = getVariableDefaultValue(strVar);
      const value = inDeep ? getObjValueInDeep(item, nameVar) : item[nameVar];
      if ((value || value === "") && label.includes(strVar)) {
        label = label.replace(strVar, value);
      }
    });
    return label;
  }

  return '';
};

export const cleanInterpretVar = ({strVar, item, inDeep = true}: { item: any, strVar: string, inDeep?: boolean }) => {
  const varList = getDefaultVarsFromStr(strVar);
  if (varList && varList.length > 0) {
    let label = strVar;
    varList.forEach(strVar => {
      const nameVar = getVariableDefaultValue(strVar);
      const value = inDeep ? getObjValueInDeep(item, nameVar) : item[nameVar];
      if (!value && label.includes(strVar)) {
        label = label.replace(strVar, '');
      }
    });
    return label;
  }

  return '';
};

export function getReferenceSelectOptions({
                                            options,
                                            header,
                                            isProvider
                                          }: { header: TableFormColumn, options: any[], isProvider?: boolean }) {

  const columns = isProvider ? header.format_rules?.columns_labels : header.sub_columns;

  if (options) {
    if (columns) {
      const {name, compound_name} = columns[0];
      const valueKey = getColumnValueFromRules({columns, isProvider});

      if (options.length > 0) {

        if (compound_name) {
          const varList = getDefaultVarsFromStr(compound_name);
          if (varList && varList.length > 0) {

            return options.map(item => {

              let label = compound_name;

              varList.forEach(strVar => {
                const nameVar = getVariableDefaultValue(strVar);
                const value = getObjValueInDeep(item, nameVar);
                if (value && label.includes(strVar)) {
                  label = label.replace(strVar, value);
                }
              });

              return {
                label,
                value: getObjValueInDeep(item, valueKey)
              };
            });
          }
        }

        if (name) {
          return options?.filter(option => !!getObjValueInDeep(option, name)).sort((a, b) => {
            return (getObjValueInDeep(a, name).toString()).localeCompare(getObjValueInDeep(b, name).toString());
          }).map(res => {
            return {
              label: getObjValueInDeep(res, name) ?? '',
              value: getObjValueInDeep(res, valueKey)
            };
          });
        }


      }

      return options.map((fieldColumn: { [x: string]: any; }) => ({
        label: fieldColumn[name] ?? '',
        value: getObjValueInDeep(fieldColumn, valueKey)
      }));
    } else {
      return options;
    }
  }

  return [];
}


export function getItemsBetweenRange(rows: any[], range: { startDate: null | Date, endDate: null | Date }, field: string) {
  return rows.filter(row => {
    if (range.endDate && range.startDate) {
      return row[field] >= convertDateToNumberDate(range.startDate) && row[field] <= convertDateToNumberDate(range.endDate);
    }
    return true;
  });
}

export const getIconType = (name: string, t: (data: string) => string): Item => {

  const newName = t(`common:${name.toLowerCase()}`);
  switch (name) {
    default:
      return (
        {type: 'success', icon: 'file-add', label: newName}
      );

    case 'EDIT':
      return (
        {type: 'secondary', icon: 'pencil', label: newName}
      );

    case 'READ':
      return (
        {type: 'primary', icon: 'license', label: newName}

      );

    case 'DELETE':
      return (
        {type: 'danger', icon: 'trash', label: newName}
      );

    case 'EXECUTE':
      return (
        {type: 'info', icon: 'select', label: newName}
      );

  }

};

export const transformObjToNewSingleObj = (obj: { [key: string]: any }, keyValue: string) => {
  const newObj: { [key: string]: string } = {};

  Object.keys(obj).forEach(key => {
    newObj[key] = getObjValueInDeep(obj[key], keyValue);
  });

  return newObj;
};


export function iterator(f: number, u: number) {
  const arr: number[] = [];
  let i = f;
  while (i <= u) arr.push(i++);
  return arr;
}


let time: any;

export function debounceTime(func: (params: any) => any, params: any, timeout: number): Promise<any> {
  if (time) clearTimeout(time);
  return new Promise((resolve => {
    time = setTimeout(async () => {
      const res = await func(params);
      resolve(res);
    }, timeout);
  }));
}

export function stringLimit(str: string, limit: number) {
  return str.length > limit ? `${str.slice(0, limit)}...` : str;
}


export const getReportQuery = async ({report, parentFilters, filtersToAdd, user}: {
  report: Report, parentFilters?: FilterTableReport[], filtersToAdd?: string[], user?: UserData
}) => {
  const convertQuery = checkAnalyticQueryVars(report.query);

  if (report?.query && JSON.parse(convertQuery)) {
    let handleQuery = false;
    let query = addTokenToAnalyticQuery(JSON.parse(convertQuery));
    let columns: Column[] = [];

    if (report.sort && JSON.parse(report.sort)) {
      columns = JSON.parse(report.sort).map((key: string) => {
        return {
          name: key,
          header: key,
        };
      });
    }

    if (parentFilters) {
      const result = removeOrAddColumnsByQuery({parentFilters, query, columns, filtersToAdd});
      query = result.query;
      handleQuery = result.handleQuery;
    }

    if (report.visible_for && IsJsonString(report.visible_for) && user) {
      const visibleFor = JSON.parse(report.visible_for)
      let filter = ''

      Object.keys(visibleFor).forEach((key, index) => {
        if (user[key]) {
          filter += index > 0 ? " & (" : "" + `'${user[key]}' == ${visibleFor[key]} ${index > 0 ? ")" : ""}`
        }
      })

      query.actions.push({
        type: 'filter',
        filter
      })
    }


    const responseJson = await executeAnalyticJson(query);
    if (responseJson.success && responseJson.items && responseJson.items?.length > 0) {

      if (columns.length === 0) {
        columns = Object.keys(responseJson.items[0]).map(key => {
          return {
            name: key,
            header: key,
          };
        });
      }

      if (report.columns_to_summarize && JSON.parse(report.columns_to_summarize)) {
        const columns_to_summarize = JSON.parse(report.columns_to_summarize);

        columns = columns.map(column => ({...column, isTotalColumn: columns_to_summarize.includes(column.name)}));
      }


      let filters: { table_name: string, label: string }[] = [];

      if (report.filter && JSON.parse(report.filter)?.length > 0) {
        filters = JSON.parse(report.filter);
      }
      if (report.custom_column && JSON.parse(report.custom_column)?.length > 0) {
        const custom_columns: { column: string, type: CustomTableColumnType }[] = JSON.parse(report.custom_column)

        columns = columns.map(column => {
          const custom_column = custom_columns.find(cColumn => cColumn.column === column.name)
          if (custom_column) {
            column.type = custom_column.type
          }
          return column
        })
      }

      if (columns.some(column => column.type === "Document")) {

        const documentColumns = columns.filter(column => column.type === "Document").map(column => column.name)

        const documents = await getFileData("");
        if (documents?.success) {

          responseJson.items = responseJson.items.map(item => {
            documentColumns.forEach(column => {
              const temp: Content[] = []
              let docsKey: string[] = []
              if (item[column] && IsJsonString(item[column]) && Array.isArray(JSON.parse(item[column]))) {
                docsKey = JSON.parse(item[column])
              }

              if (item[column] && Array.isArray(item[column])) {
                docsKey = item[column]
              }

              if (docsKey.length > 0) {
                docsKey.forEach(docKey => {
                  const document = documents.item.contents.find((content: Content) => content.key === docKey)
                  if (document) {
                    temp.push(document)
                  }
                })

                item = {...item, [column]: temp}
              }
            })


            return item;
          })
        }
      }

      return {
        data: responseJson.items,
        columns,
        filters,
        name: report.name,
        handleQuery,
        remove_empty_columns: report.remove_empty_columns
      } as MultiReport;
    } else {
      const response = await getAnalyticJsonColumns(query)

      if (response?.success && response.items) {
        let columns: string[] = response.items
        if (report.sort && JSON.parse(report.sort)) {
          const sortColumns = JSON.parse(report.sort)
          columns = columns.filter(column => sortColumns.includes(column))
        }

        return {
          name: report.name,
          columns: columns.map(column => ({
            name: column,
            header: column,
          })),
          data: [],
          remove_empty_columns: false,
          filters: []
        }
      }


    }
  }

  return null;
};


export function removeOrAddColumnsByQuery({
                                            parentFilters,
                                            query,
                                            columns,
                                            filtersToAdd
                                          }: { parentFilters: FilterTableReport[], query: AnalyticQuery, columns: Column[], filtersToAdd?: string[] }) {
  let noIncludeFilters: string[] = [];
  let handleQuery = false;
  if (parentFilters && columns.length > 0) {
    const columnsName = columns.map(column => column.name);
    noIncludeFilters = parentFilters.filter(parentF => parentF.table_name && !columnsName.includes(parentF.table_name)).map(filter => filter.table_name ?? "");
  }

  if (noIncludeFilters.length > 0) {
    const group_by_list = query.actions.filter(action => action.type === 'group_by');
    if (group_by_list.length > 0) {
      const last_group_by = group_by_list[group_by_list.length - 1];
      if (last_group_by.columns && last_group_by.columns.length > 0) {
        if (filtersToAdd && filtersToAdd.length > 0) {
          last_group_by.columns = (last_group_by.columns as string[]).filter(column => {
            if (noIncludeFilters.includes(column)) {
              return filtersToAdd.includes(column);
            } else {
              return true;
            }
          });
        } else {
          last_group_by.columns = (last_group_by.columns as string[]).filter(column => !noIncludeFilters.includes(column));
        }
        handleQuery = true;
      }
    }
  }


  return {query, handleQuery};
}

export function convertTableRowsToCSVString(columns: Column[], rows: any[]): string {
  let text = columns.map(e => e.header).join(';');
  rows.forEach((row: any) => {
    text += `\n` + columns.map(c => jsonLevels(row, `${c.name}${c.value ? `.${c.value}` : ''}`)).join(';');
  });
  return text;
}

export function jsonLevels(json: AnyData, field: string) {

  function getValue(value: any) {
    const type = typeof value;
    switch (type) {
      case 'object':
        let newVal: any = {};
        if (value) {
          Object.keys(value).forEach(key => {
            const tt = typeof value[key];
            switch (tt) {
              case 'string':
              case 'boolean':
              case 'number':
              case 'undefined':
                newVal[key] = value[key] ?? '';
            }
          });
        }
        return JSON.stringify(newVal ?? '');
      default:
        return value ?? '';
    }
  }

  if (field.includes('.')) {
    let val = json;
    field.split('.').forEach(key => {
      val = val[key];
    });
    return getValue(val);
  }
  return getValue(json[field]);
}

export const downloadKeyFile = async (fileKey: string) => {
  const response = await downloadFile(fileKey);
  if (response?.success) {
    window.open(response.url);
  }
};

export const promiseLimit = async ({
                                     arrayPromise,
                                     limit,
                                     functionPromise,
                                     objKey
                                   }: { arrayPromise: any[], limit: number, functionPromise: (params: any) => Promise<any>, objKey?: string }) => {
  let responseArray: Response[] = [];

  let initial = 0;
  let end = limit;

  while (end <= arrayPromise.length + 1) {

    const promises = arrayPromise.slice(initial, end).map(obj => objKey ? functionPromise(getObjValueInDeep(obj, objKey)) : functionPromise(obj));
    const response = await Promise.all(promises);

    responseArray = [...responseArray, ...response];

    initial += limit;
    end += limit;
  }


  return responseArray;
};

export const splitRuleArray = (rule: string) => {
  const defaultValues: any[] = [];

  if (rule) {
    const vArrOr = rule.split(' || ');
    vArrOr.forEach(a => {
      const vArrAnd = a.split(' && ');
      vArrAnd.forEach(a => defaultValues.push(a));
    });
  }

  const sp = defaultValues.map(a => {
    const d = a.split(` ${conditions.find(c => a.includes(c.value))?.value} `);

    return {
      value: d[1],
      field: d[0],
    };
  });
  return sp;
};

export function getValueField(valueField: {
  value: any;
  field: IField
}) {
  const {
    field: {field_type, sub_type, single_value, name, id, detail_form},
    value,
  } = valueField;

  const fields = detail_form?.fields || [];

  switch (field_type) {
    case FieldType.SMALL_TEXT:
    case FieldType.LARGE_TEXT:
      const val = {
        id,
        name,
        value,
        single_value,
      };
      switch (sub_type) {
        case SubType.NUMBER:
          return {...val, value: parseInt(val.value)}
        default:
          return val
      }

    case FieldType.DATE:
      return {
        id,
        name,
        value: value ? new Date(value).toISOString() : '',
        single_value,
      };

    case FieldType.DATE_RANGE:
      return {
        id,
        name,
        value: (value && value.startDate && value.endDate) ?
          `${new Date(value.startDate).toDateString()} -> ${new Date(value.endDate).toDateString()}` : '',
        single_value,
      };

    case FieldType.TABLE:

      const result: any = Object.keys(value).map(e => {
        const row = value[e];
        return Object.keys(row).map(r => {
          const subField = fields.find(f => f.id === parseInt(r))
          if (subField) {
            return getValueField({value: row[r], field: subField})
          } else return undefined;
        });
      }).slice();
      return {
        id,
        name,
        value: result,
        single_value,
      };

    case FieldType.FORM_GROUP:
      const resultForm: any = Object.keys(value).map(r => {
        const subField = fields.find(f => f.id === parseInt(r))
        if (subField) {
          return getValueField({value: value[r], field: subField})
        } else return undefined;
      });
      return {
        id,
        name,
        value: resultForm,
        single_value,
      };

    case FieldType.OPTIONS:
      switch (sub_type) {
        case 'SELECT':
        case 'TOGGLE':
          return value ? {
            id,
            name,
            single_value,
            value: single_value ? value.value : value.map((val: any) => val.value),
          } : null;
        default:
          return null;
      }

    default:
      return null;
  }
}

export const filterFields = (
  array: IField[],
  val: { [key: string]: any }
) => {

  function getRuleValueField(name: string) {
    let vr: any = Object.keys(val).reduce((a: any, v: string) => {
      const typeField = array.find(f => `${f.id}` === v);

      if (typeField?.field_type !== FieldType.FORM_GROUP &&
        typeField?.field_type !== FieldType.TABLE && v === name) {
        a = val[v];
      }
      return a;
    }, null);
    const field = array.find(f => f.name === name);
    if (vr && field) {
      vr = getValueField({value: vr, field})?.value;
    }

    return (typeof vr === "string" ? `"${vr}"` : vr) || `""`;
  }

  const b = array.filter(a => {
    let visible = a.visible_when || 'true';
    let rules = splitRuleArray(a.visible_when || "");
    rules.forEach(r => {
      const value = getRuleValueField(r.field);
      if (Array.isArray(value)) {
        const val = value.find((e: string) => `"${e}"` === r.value);
        visible = visible.replace(r.field, `"${val}"`);
      } else {
        visible = visible.replace(r.field, value);
      }
    });
    return eval(visible);
  });
  return b;
}


export function sortSequences(sequences: any[], events: any[]) {
  let sequences2: any[] = []
  let visited = sequences.map(it => {
    return false
  });
  let nexts: any[] = [];
  let event_start = events.find(event => event.event_type === EventType.START);
  if (!event_start) {
    return sequences
  }
  for (let i = 0; i < sequences.length; i++) {
    if (sequences[i]["from_item_id"] === event_start.id && sequences[i]["from_item_type"] === "EVENT") {
      nexts.push(i);
    }
  }
  if (nexts.length === 0) {
    return sequences;
  }
  while (nexts.length != 0) {
    if (!sequences2.some(seq => seq.id === sequences[nexts[0]].id)) {
      sequences2.push(sequences[nexts[0]])
    }

    visited[nexts[0]] = true;
    for (let i = 0; i < sequences.length; i++) {
      if (sequences[i]["from_item_id"] === sequences[nexts[0]]["to_item_id"] && sequences[i]["from_item_type"] === sequences[nexts[0]]["to_item_type"] && !visited[i]) {
        nexts.push(i);
      }
    }
    nexts = nexts.slice(1, nexts.length)
  }

  for (let i = 0; i < sequences.length; i++) {
    if (!visited[i]) {
      sequences2.push(sequences[i])
    }
  }
  return sequences2;
}

export function sortTaskEventBySequences(tasks: TaskProcess[], sequences: Sequence[], events: EventProcess[], gateways: GatewayProcess[]) {
  const noExistInSequences: any[] = [], results: any[] = [];
  const existInSequences: any[] = sequences.reduce((taskEvents: any[], seq) => {

    if (seq.from_item_type === "EVENT") {
      const evt = events.find(e => e.id === seq.from_item_id);
      if (evt && !taskEvents.some(event => event.id === evt.id)) {
        taskEvents.push({...evt, type: "event"});
      }
    } else if (seq.from_item_type !== "EVENT") {
      if (seq.from_item_type === "GATEWAY") {
        const gateway = gateways.find(gateway => gateway.id === seq.from_item_id && seq.from_item_type === "GATEWAY");
        if (gateway && !taskEvents.some(gate => gate.id === gateway.id)) {
          taskEvents.push({...gateway, type: 'gateway'});
        }
      }
    } else {
      if (seq.from_item_type !== "EVENT") {
        const tks = tasks.find(task => task.id === seq.from_item_id && seq.from_item_type === task.activity_type);
        if (tks && !taskEvents.some(task => task.id === tks.id)) {
          taskEvents.push({...tks, type: 'task'});
        }
      }
    }


    return taskEvents;
  }, []);

  tasks.forEach(task => {
    const exist = existInSequences.find(data => data.type === "task" && data.id === task.id);
    if (!exist) noExistInSequences.push({...task, type: 'task'})
  });

  events.forEach(event => {
    const exist = existInSequences.find(data => data.type === "event" && data.id === event.id);
    if (!exist) noExistInSequences.push({...event, type: 'event'});
  });

  gateways.forEach(gateway => {
    const exist = existInSequences.find(data => data.type === "gateway" && data.id === gateway.id);
    if (!exist) noExistInSequences.push({...gateway, type: 'gateway'});
  });


  existInSequences.forEach((data: any) => results.push(data));
  noExistInSequences.forEach((data: any) => results.push(data));
  return results;
}


export function splitDataArrayFromString(rule: string) {
  try {
    return rule.split("[").pop()?.split("]").shift()?.split(",").map(i => parseInt(i)) ?? [];
  } catch (e) {
    return [];
  }
}

// const sbxKeyToAnalyticKey: {[key: string]: string} = {
//   ANDOR: "logic_operator",
//   FIELD: "field",
//   OP: "filter_operator",
//   VAL: "value"
// }

export const convertSbxFilterToAnalytic = (filters: GroupCondition[]) => {
  return filters.map(it => {
    return {
      "logic_operator": it.ANDOR.toLowerCase(),
      "field": it.FIELD,
      "filter_operator": it.OP,
      "value": it.VAL
    }
  })
}

export const convertAnalyticFilterToSbx = (filters: SourceFilter[]) => {
  return filters.map(it => {
    return {
      "ANDOR": it.logic_operator as Andor,
      "FIELD": it.field,
      "OP": (it.filter_operator as SbxConditionType),
      "VAL": it.value
    }
  })
}


export const removeTemporalIdFromQuery = (json: AnalyticQuery) => {

  const actions = [...json.actions];

  let query = {...json}

  if (query.source.temporal_id) {
    delete query.source.temporal_id
  }

  if (query.source?.sources && query.source.sources.length > 0) {
    query.source.sources = query.source.sources.map(source => {
      if (source.temporal_id) {
        delete source.temporal_id
      }

      return source
    })
  }


  return {
    ...query
    , actions: actions.reduce((arr: AnalyticQueryAction[], action) => {
      if (action.temporal_id) {
        delete action.temporal_id;
      }

      if (action.dependency_action_id) {
        delete action.dependency_action_id;
      }

      if ((action.type === 'select' || action.type === 'sort' || action.type === 'rename')
        && (action.columns?.length === 0 || (action.renamed_columns && Object.keys(action.renamed_columns).length === 0))) {
      } else {
        arr.push(action);
      }

      return arr;
    }, [])
  };
};

export const getQuerySources = (json: AnalyticQuery) => {
  const querySources: { [key: string]: Query } = {}
  if (json.source && json.source.temporal_id) {
    querySources[json.source.temporal_id] = {
      row_model: json.source.with,
      fetch: json.source.fetch,
      where: json.source.filters ? [{GROUP: convertAnalyticFilterToSbx(json.source.filters), ANDOR: "AND"}] : []
    }
  }

  if (json.source.sources && json.source.sources.length > 0) {
    for (const source of json.source.sources) {
      if (source.temporal_id) {
        querySources[source.temporal_id] = {
          row_model: source.with,
          fetch: source.fetch,
          where: source.filters ? [{GROUP: convertAnalyticFilterToSbx(source.filters), ANDOR: "AND"}] : []
        }
      }
    }
  }

  return querySources
}

export const addTemporalIdToQuery = (json: AnalyticQuery) => {

  const actions = [...json.actions].map((action) => {
    if (!action.temporal_id) {
      action.temporal_id = uuidV4()
    }
    return action
  });


  actions.forEach(action => {
    if (action.transformation && typeof action.transformation === "string" && action.transformation.includes("@date_to_formateddate")) {
      // Debug this


      const transformationVar = action.transformation.split(" ")[1]

      const dependency_action = actions.find(nAction => {

        if (nAction.name && transformationVar === nAction.name) {
          return nAction
        }
      })

      if (dependency_action?.temporal_id) {
        action.dependency_action_id = dependency_action.temporal_id
      }
    }
  })

  let query = {...json}

  if (!query.source.temporal_id) {
    query.source.temporal_id = uuidV4()
  }

  if (query.source?.sources && query.source.sources.length > 0) {
    query.source.sources = query.source.sources.map(source => {
      if (!source.temporal_id) {
        source.temporal_id = uuidV4()
      }

      return source
    })
  }


  return {
    ...query, actions
  };
};

export const sourcesValidationFromQuery = (json: AnalyticQuery) => {

  let query = {...json};

  if (query.source.from === SourceFrom.SBX_EVENT && query.source.filters && query.source.filters.length > 0) {
    const fromDate = query.source.filters.find(filter => filter.field === "fromDate")?.value
    const toDate = query.source.filters.find(filter => filter.field === "toDate")?.value

    if (fromDate && toDate) {

      const diff = differenceInWeeks(new Date(toDate as string), new Date(fromDate as string))
      if (diff > 2) {
        query.source.pagination = true
      }
    }
  }

  if (query.source?.sources && query.source.sources.length > 0) {
    query.source.sources = query.source.sources.filter(source => source.with);

    query.source.sources = query.source.sources.map(source => {
      if (source.filters && source.filters.length > 0) {
        const fromDate = source.filters.find(filter => filter.field === "fromDate")?.value
        const toDate = source.filters.find(filter => filter.field === "toDate")?.value

        if (fromDate && toDate) {
          const diff = differenceInWeeks(new Date(toDate as string), new Date(fromDate as string))
          if (diff > 2) {
            source.pagination = true
          }
        }
      }

      return source
    })

  }

  if (query.source.filters && query.source.filters.length > 0) {
    query.source.filters = query.source.filters.map(filter => {

      if (filter.filter_operator === 'IS' && filter.value === null) {
        filter.filter_operator = 'is null';
      }

      if (filter.filter_operator === 'IS NOT' && filter.value === null) {
        filter.filter_operator = 'is not null';
      }

      return filter;
    });
  }

  return query;
};

export const removeInvalidActionsFromQuery = (json: AnalyticQuery) => {

  let query = {...json}

  if (query.actions && query.actions.length > 0) {
    query.actions = query.actions.filter(action => {
      switch (action.type) {
        case "sort":
          return action.columns && action.columns.length > 0;
        case "select":
          return action.columns && action.columns.length > 0;
        case "limit":
          return action.top! > 0;
        case "group_by":
          return action.agg && Object.keys(action.agg).length > 0 && action.columns && action.columns.length > 0;
        case "rename":
          return action.renamed_columns && Object.keys(action.renamed_columns).length > 0;
        case "transform":
          return (action.transformation || action.transformation === 0) && action.name
        case "filter":
          return action.filter
        case 'default_values':
          return action.columns && Object.keys(action.columns).length > 0
        case "ml":
          if (action.subtype === "forecast") {
            return action.forecast_to && action.forecast_from && action.x && action.y
          }

          return true
        default:
          return true;
      }
    })
  }

  return query;
};

export function analyticQueryValidation(json: AnalyticQuery) {
  if (isValidAnalyticQuery(json)) {
    return addTokenToAnalyticQuery(json);
  }

  return null;
}

type StaticConstVar = '${date_start_month}' | '${now}' | '${now_numberdate}'
const StaticConstVar: StaticConstVar[] = ['${date_start_month}', '${now_numberdate}', '${now}']

export const replaceConstVarWith = (constVar: StaticConstVar) => {
  switch (constVar) {
    case '${date_start_month}':
      const today = new Date()
      return `${new Date(today.getFullYear(), today.getMonth(), 1).toISOString()}`
    case '${now}':
      return `${new Date().toISOString()}`
    case '${now_numberdate}':
      return convertDateToNumberDate(new Date())
    default:
      return constVar
  }
}

export function checkAnalyticQueryVars(query: string) {

  StaticConstVar.forEach(constVar => {
    if (query.toLowerCase().includes(constVar.toLowerCase())) {
      query = query.replace(constVar, replaceConstVarWith(constVar))
    }
  })


  return query;
}


export const getAnalyticQueryDomain = (json: AnalyticQuery, domain: number) => {
  if (!json.source.in) {
    json.source.in = domain
  }

  if (json.source.sources!?.length > 0) {
    json.source.sources?.forEach(source => {
      if (!source.in) {
        source.in = domain
      }
    })
  }


  return json;
}

export const isValidAnalyticQuery = (query: AnalyticQuery) => query.source.with && query.source.from

export const getSelectOptionByConfig = (field: Field, options: any[]) => {
  if (field.format_rules_definition?.columns_labels) {
    const {name, compound_name} = field.format_rules_definition?.columns_labels[0];

    if (options && options.length > 0) {
      if (name) {
        return options?.filter(option => !!getObjValueInDeep(option, name)).sort((a, b) => (getObjValueInDeep(a, name) ?? '')?.localeCompare(getObjValueInDeep(b, name) ?? '')).map(res => {
          return {
            label: getObjValueInDeep(res, name) ?? '',
            value: res._KEY ?? res.value ?? ""
          };
        });
      }

      if (compound_name) {
        const varList = getDefaultVarsFromStr(compound_name);
        if (varList && varList.length > 0) {
          return options.filter(item => !isDefaultVarExpression(getInterpretVar({
            strVar: compound_name,
            item
          }))).map(item => {

            let label = getInterpretVar({strVar: compound_name, item});
            return {
              label,
              value: item._KEY ?? item.value ?? ""
            };
          });
        }
      }
    }

  } else {
    if (options && options.length > 0) {

      return options.map(option => ({label: option.label, value: option.value}))
    }
  }

  return [];
};

export function success(message = "") {
  toast({type: "success", message});
}

export function error(message = "") {
  toast({type: "error", message});
}

export const getDuplicateProcessModel = (newProcessModel: ProcessModel) => {


  delete newProcessModel.updated;
  delete newProcessModel.created;
  delete newProcessModel.id;
  // newProcessModel.name += ` (${t("duplicate")})`

  newProcessModel.tasks = newProcessModel.tasks.map(task => {
    // task.manager_style = pbW.getSyles(xml)[task.id_manager ?? task.activity_type + "_" + task.id]
    let newTask = {...task};
    if (newTask.id) {
      if (newTask.process_id) {
        delete newTask.process_id;
      }

      newTask.id_manager = newTask.id.toString();
      delete newTask.id;

      if (newTask.attachments && newTask.attachments.length > 0) {
        newTask.attachments = newTask.attachments.map((attachment) => {
          const newAttachment = {...attachment}
          if (newAttachment.id) {
            delete newAttachment.id
          }
          return newAttachment
        })
      }
    }
    delete newTask.created;
    delete newTask.updated;
    return newTask;
  });

  newProcessModel.events = newProcessModel.events.map(event => {
    // event.manager_style = pbW.getSyles(xml)[event.id_manager ?? "EVENT_" + event.id]
    const newEvent = {...event};
    if (newEvent.event_type) {
      newEvent.event_type = getEventType(newEvent.event_type);
    }

    if (newEvent.id) {
      if (newEvent.process_id) {
        delete newEvent.process_id;
      }
      newEvent.id_manager = newEvent.id.toString();
      delete newEvent.id;

      if (newEvent.attachments && newEvent.attachments.length > 0) {
        newEvent.attachments = newEvent.attachments.map((attachment) => {
          const newAttachment = {...attachment}
          if (newAttachment.id) {
            delete newAttachment.id
          }
          return newAttachment
        })
      }

    }

    delete newEvent.created;
    delete newEvent.updated;
    return newEvent;
  });

  newProcessModel.gateways = newProcessModel.gateways.map(gateway => {
    // event.manager_style = pbW.getSyles(xml)[event.id_manager ?? "EVENT_" + event.id]
    const newGateway = {...gateway};
    if (newGateway.id) {
      if (newGateway.process_id) {
        delete newGateway.process_id;
      }
      newGateway.id_manager = newGateway.id.toString();
      delete newGateway.id;

    }

    delete newGateway.created;
    delete newGateway.updated;
    return newGateway;
  });

  newProcessModel.sequences = newProcessModel.sequences.map(sequence => {
    const newSequence: any = {...sequence};
    // newSequence.manager_style = pbW.getSyles(xml)[sequence.id_manager ?? "SEQ_" + sequence.id]

    if (newSequence.id) {
      newSequence.id_manager = newSequence.id.toString();
      delete newSequence.id;

      // if (sequence.attachments && sequence.attachments[0]?.id) {
      //   delete sequence.attachments[0].id;
      // }

      if (newSequence.process_id) {
        delete newSequence.process_id;
      }

      if (newSequence.from_item_id) {
        newSequence.from_item_id_manager = newSequence.from_item_id.toString();
        delete newSequence.from_item_id;
      }

      if (newSequence.to_item_id) {
        newSequence.to_item_id_manager = newSequence.to_item_id.toString();
        delete newSequence.to_item_id;
      }

    }

    delete newSequence.created;
    delete newSequence.updated;

    if (newSequence.updateEl) {
      if (newSequence.updateEl.from_item_id_manager) {
        newSequence.from_item_id_manager = newSequence.updateEl.from_item_id_manager;
      } else {
        newSequence.from_item_id = newSequence.updateEl.from_item_id;
      }

      if (newSequence.updateEl.to_item_id_manager) {
        newSequence.to_item_id_manager = newSequence.updateEl.to_item_id_manager;
      } else {
        newSequence.to_item_id = newSequence.updateEl.to_item_id;
      }

      // newSequence.from_item_id = newSequence.updateEl.from_item_id
      newSequence.from_item_type = newSequence.updateEl.from_item_type;
      // newSequence.to_item_id = newSequence.updateEl.to_item_id
      newSequence.to_item_type = newSequence.updateEl.to_item_type;

      delete newSequence.updateEl;
    }


    return newSequence;
  });


  return newProcessModel;
};

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

export const getIconFile = (extension: string) => {
  switch (extension) {
    case 'pdf':
      return pdfIcon;
    case 'xlsx':
      return excelIcon;
    case 'csv':
      return excelIcon;
    case 'png':
      return pngIcon;
    case 'jpg':
      return jpgIcon;
    case 'pptx':
      return powerPointIcon;
    case 'docx':
      return wordIcon;
    default:
      return documentIcon
  }
}

export const compare = (a: any, b: any, property: string, asc: boolean) => {
  if (a[property]) {
    if (asc) {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
    } else {
      if (a[property] > b[property]) {
        return -1;
      }
      if (a[property] < b[property]) {
        return 1;
      }
    }

    return 0;
  } else {
    return 0
  }
}

export const checkValidColor = (strColor: string) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color == strColor;
}


export function IsJsonString(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const getAnalyticActionFilterOption = (t: (str: string) => string) => {
  return [
    {
      label: t("EQUAL_TO"),
      value: ActionFilterOperator.EQUAL_TO
    },
    // {
    //   label: t("EXIST"),
    //   value: ActionFilterOperator.EXIST
    // },
    // {
    //   label: t("NO_EXIST"),
    //   value: ActionFilterOperator.NOT_EXIST
    // },
    {
      label: t("GREATER_THAN"),
      value: ActionFilterOperator.GREATER_THAN
    },
    {
      label: t("SMALLER_THAN"),
      value: ActionFilterOperator.SMALLER_THAN
    },
    {
      label: t("GREATER_OR_EQUAL_THAN"),
      value: ActionFilterOperator.GREATER_OR_EQUAL_THAN
    },

    {
      label: t("SMALLER_OR_EQUAL_THAN"),
      value: ActionFilterOperator.SMALLER_OR_EQUAL_THAN
    },
    {
      label: t("DIFFERENT_OF"),
      value: ActionFilterOperator.DIFFERENT_OF
    }
    // {
    //   label: t("START_LIKE"),
    //   value: {
    //     condition: SbxConditionType.LIKE, format: "word%"
    //   }
    // },
    // {
    //   label: t("END_LIKE"),
    //   value: {
    //     condition: SbxConditionType.LIKE, format: "%word"
    //   }
    // },
    // {
    //   label: t("CONTAIN_LIKE"),
    //   value: {
    //     condition: SbxConditionType.LIKE, format: "%word%"
    //   }
    // },
  ];
}

export const isValidNumber = (numb: string) => {
  return !isNaN(parseFloat(numb))
}
export const actionFilterOperatorArr = ['==', '!=', '>', '<', 'is not null', 'is null']

export const isFilterAction = (action: AnalyticQueryAction) => {
  return action.filter?.includes("&") || action.filter?.includes("|") || actionFilterOperatorArr.some(operator => action.filter?.includes(operator)) || action?.filter === "*"
}

export const drawRect = ({
                           y,
                           x,
                           width,
                           height,
                           ctx,
                           text
                         }: { ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, text?: string }) => {
  // ctx.rect(x, y, height, width);
  ctx.rect(x, y, 50, 50);
  ctx.fillStyle = "white";
  ctx.fill();
  ctx.lineWidth = 1;
  if (text) {
    ctx.fillStyle = "black"
    ctx.fillText(text, x, y + (height / 2));
  }
};

export const drawCircle = ({
                             y,
                             x,
                             ctx,
                             text,
                             radius
                           }: { ctx: CanvasRenderingContext2D, radius: number, x: number, y: number, text?: string }) => {
  let circle = new Path2D();
  circle.arc(x, y, radius, 0, Math.PI * 2, false);

  if (text) {
    ctx.fillText(text, x - radius, y);
  }
  ctx.stroke(circle);
};

export const drawArrow = ({
                            y,
                            x,
                            ctx,
                            toX,
                            toY
                          }: { ctx: CanvasRenderingContext2D, toX: number, toY: number, x: number, y: number }) => {
  const headlen = 10; // length of head in pixels
  const tox = toX;
  const toy = toY;
  const dx = tox - x;
  const dy = toy - y;
  const angle = Math.atan2(dy, dx);
  ctx.moveTo(x, y);
  ctx.lineTo(tox, toy);
  ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
  ctx.moveTo(tox, toy);
  ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
};

type ProcessNodeTypes = "TASK" | "EVENT" | "SEQUENCE"

export const getNodeTypeToProcess = (type: ProcessNodeTypes): keyof ProcessModel => {
  switch (type) {
    case 'SEQUENCE':
      return "sequences"
    case 'EVENT':
      return "events"
    default:
      return "tasks"
  }
}

export const getNodeByProcessModel = ({processModel, id}: { processModel: ProcessModel, id: string }) => {
  const type_id = id.split("_")
  if (type_id.length > 0) {
    const type = type_id[0] as ProcessNodeTypes
    const idNumber = parseInt(type_id[1])

    const node = processModel[getNodeTypeToProcess(type)].find((node: { id: number }) => node.id === idNumber)
    if (node) {
      return node as Sequence | Task | EventProcess
    }

  }

}

export function toNumber(str: string): number {
  try {
    const n = parseInt(str);
    return isNaN(n) ? 0 : n;
  } catch (e) {
    return 0;
  }
}

export const autoSuggestDefaultTheme: Theme = {
  container: {
    position: 'relative'
  },
  // input: {
  //   width: 240,
  //   height: 30,
  //   padding: '10px 20px',
  //   fontFamily: 'Helvetica, sans-serif',
  //   fontWeight: 300,
  //   fontSize: 16,
  //   border: '1px solid #aaa',
  //   borderTopLeftRadius: 4,
  //   borderTopRightRadius: 4,
  //   borderBottomLeftRadius: 4,
  //   borderBottomRightRadius: 4,
  // },
  inputFocused: {
    outline: 'none'
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 51,
    width: 280,
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px'
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd'
  }
};


export function groupByHead(key: string, toValue: boolean, arr: any[], ignoreKeys?: string[]): { rows: any[], columns: Column[] } | AnyData {
  if (!arr.length) {
    return {rows: [], columns: []};
  }
  const newData = arr.reduce((newObj: any, obj: any) => {
    const obj2 = {...obj};
    ignoreKeys?.forEach(key => {
      delete obj2[key];
    });
    Object.keys(obj2).forEach(subKey => {
      if (subKey === key) {
        if (toValue) {
          if (obj2[subKey]) {
            newObj.a = [...(newObj.a ?? []), obj2[subKey]];
            newObj.b = (newObj.b ?? 0) + 1;
            newObj.c = (newObj.c ?? []).filter((bb: any) => bb !== obj2[subKey]);
            newObj.c.push(obj[subKey]);
          }
        } else {
          if (!newObj[obj[subKey]]) newObj[obj2[subKey]] = [];
          newObj[obj2[subKey]].push(obj2);
        }
      }
    });

    return newObj;
  }, {});
  if (toValue) {
    const vv = newData.c;
    return {
      filtered: vv && vv.length ? vv.length > 1 ? vv : vv.shift() : null,
      length: newData.b,
      result: newData.a
    };
  } else {
    const values = Object.values(newData);
    const rows = values.map((obj: any) => {
      if (obj.length > 1) {
        const data = Object.keys(obj[0]).reduce((newObj: any, key2) => {
          const {result, length, filtered} = groupByHead(key2, true, obj) as any;
          newObj[key2] = filtered;
          if (length && length > 1) {
            newObj[`${key2}_length`] = length;
          }
          if (newObj[`${key2}_length`] && Array.isArray(filtered) && filtered.length !== length) {
            newObj[key2] = result;
          }
          return newObj;
        }, {});

        return {
          ...data,
          all_data: obj ?? []
        }
      }
      return obj.shift();
    });
    let existAll = false, columnsToArray: string[] = [];
    const columns = Object.values(rows.reduce((objKeys: any, obj: any) => {
      Object.keys(obj).forEach(subKey => {

        if (Array.isArray(obj[subKey]) && !subKey.includes("_all")) {
          objKeys[subKey] = {
            type: "Array",
            header: subKey.split("_").filter(e => e).join(" "),
            name: subKey
          };
          columnsToArray.push(subKey);
        }
        if (subKey.includes("all_")) {
          objKeys[subKey] = {
            type: "ArrayObject",
            header: subKey.split("_").filter(e => e).join(" "),
            name: subKey
          };
          existAll = true;
        }
        if (subKey.includes("_length") && Array.isArray(obj[subKey.replace("_length", "")])) {
          objKeys[subKey] = {
            type: "String",
            header: subKey.split("_").filter(e => e).join(" "),
            name: subKey
          };
        }
      })
      return objKeys;
    }, {})) as Column[];


    return {
      rows: rows.map((v: any) => {

        if (existAll && !v.all_data) {
          if (!v.all_data) {
            const all = Object.assign({}, v);
            v.all_data = [all];
          }
        }

        columnsToArray.forEach(key => {
          if (!Array.isArray(v[key])) {
            v[key] = [v[key]];
            v[`${key}_length`] = 1;
          }
        })
        return v;
      }),
      columns
    };
  }
}


export function filterColumnsToGroup(columns: Column[], value: string) {
  return columns.filter(c => (c.header ?? "").includes(value));
}

export const checkSpecialCharactersForRegex = (list: string[]) => {
  return list.map(character => {
    if (character === "+" || character === "*") {
      return "\\" + character
    }
    return character
  })
}

export const getNextBusinessDay = (businessDays: BusinessDay[]) => {
  let date = new Date();
  date.setDate(date.getDate() + 1)

  let isAvailableDay = false;
  while (!isAvailableDay) {
    const month = date.getMonth();
    let todayDate = date.getDate().toString();
    todayDate = parseInt(todayDate) < 10 ? '0' + todayDate : todayDate;

    const businessDate = businessDays.find(bDays => parseInt(bDays.month_index) === month + 1);
    if (businessDate?.days && JSON.parse(businessDate.days)) {
      isAvailableDay = JSON.parse(businessDate.days)[todayDate];
      if (!isAvailableDay) {
        date.setDate(date.getDate() + 1);
      }
    } else {
      break;
    }
  }

  return isAvailableDay ? date.toISOString() : '';
}

export function toJSON(str?: string | null): any {
  try {
    if (str) {
      return JSON.parse(str);
    }
    throw Error;
  } catch (e) {
    return undefined;
  }
}

export function JSONtoString(obj: any): string | undefined {
  try {
    if (obj) {
      return JSON.stringify(obj);
    }
    throw Error;
  } catch (e) {
    return undefined;
  }
}

export const updateAnalyticSource = ({
                                       baseSource,
                                       sources,
                                       query
                                     }: { query: AnalyticQuery, sources: UpdateSource[], baseSource?: Source }) => {
  if (sources.length > 0) {

    for (const source of sources) {
      if (source.sourceId === query.source.temporal_id) {
        if (source.sourceValue === null) {
          delete query.source[source.sourceKey];
        } else {
          query.source = {...query.source, [source.sourceKey]: source.sourceValue};
        }
      } else {
        if (query.source?.sources && query.source.sources.length > 0) {
          query.source.sources = query.source.sources.map(sourceQuery => {
            if (source.sourceId === sourceQuery.temporal_id) {
              if (source.sourceValue === null) {
                delete sourceQuery[source.sourceKey];
              } else {
                sourceQuery = {...sourceQuery, [source.sourceKey]: source.sourceValue};
              }
            }

            return sourceQuery;
          });

        }
      }


    }

    // if (baseSource){
    //   getColumns({source:baseSource, analyticQuery: query});
    // }

    return query
    // dispatchForm({name: 'analyticQuery', value: query});
  }
};

export const isEnableTaskAction = (data: { [key: string]: any }, sequence_rules: string) => {

  data = Object.keys(data).reduce((obj: { [key: string]: { value: any } }, key) => {

    if (typeof data[key] === 'number' || typeof data[key] === "string") {
      obj[key] = {value: data[key]}
    } else {
      obj[key] = data[key]
    }

    return obj;
  }, {})

  const code = "process =" + JSON.stringify(data) + "; process['__sequence_enabled'] = true;" + sequence_rules + "return process";

  const F = new Function(code);
  const x = F();

  return !!(("__sequence_enabled" in x && x["__sequence_enabled"]) || !("__sequence_enabled" in x));

}

export function removeItemFromArray(numberToRemove: number, array: Array<any>) {
  return new Array(...array).filter(n => n !== numberToRemove);
}

export const getEnvironmentByConfig = (domain_id: string) => {
  return {
    129: "iBuyFlowers",
    272: "Chilco",
    281: "Fempha",
    284: "Simas",
    286: "Sbx"
  }[domain_id]
}

export const copyToClipboard = (text: string) => {
  const textarea = document.createElement('textarea');
  document.body.appendChild(textarea);
  textarea.value = text;
  textarea.select();
  textarea.setSelectionRange(0, 99999);
  document.execCommand('copy');
  document.body.removeChild(textarea);
  toast({type: "info", message: 'Text copied'});
};

const organizationQueryCache: { [where: string]: SbxResponse } = {}

export const getSalesManagerOrganizationQuery = async (params: { model: string, where?: Condition[] }) => {
  const {user} = store.getState().AuthReducer;
  const config = user.config
  if (config?.sbx_crm.organization?.sales_managers && Object.keys(config?.sbx_crm.organization?.sales_managers).length > 0) {
    const sales_manager = config.sbx_crm.organization?.sales_managers

    params.where = await getSalesOrganizationWhere({
      where: params.where,
      items_id: sales_manager.manager_id,
      sales_obj: sales_manager as SalesManagerConfig,
      id: "manager_id",
      model: params.model
    })
  }

  return params?.where ? params.where : []
}


export const getSalesOrgOrganizationQuery = async (params: { model: string, where?: Condition[] }) => {
  const {user} = store.getState().AuthReducer;
  const config = user.config

  if (config?.sbx_crm.organization?.sales_org && Object.keys(config?.sbx_crm.organization?.sales_org).length > 0) {
    const sales_org = config.sbx_crm.organization?.sales_org
    params.where = await getSalesOrganizationWhere({
      where: params.where,
      items_id: sales_org.org_id,
      sales_obj: sales_org as SalesOrgConfig,
      id: "org_id",
      model: params.model
    })

  }
  return params?.where ? params.where : []
}

/**
 * It takes a sales organization configuration object and a user, and returns a where clause that can be used to filter the
 * data that the user can see
 * @param params - {sales_obj: (SalesManagerConfig | SalesOrgConfig), where?: Condition[], id: string, model: string,
 * items_id: string}
 */
const getSalesOrganizationWhere = async (params: { sales_obj: (SalesManagerConfig | SalesOrgConfig), where?: Condition[], id: string, model: string, items_id: string }) => {
  const {user} = store.getState().AuthReducer;
  const {sales_obj} = params
  const apply_model: ApplyToSalesManager | undefined = (sales_obj.apply_to as Array<SalesManagerConfig>).find(apply => apply.model.split(".")[0] === params.model)

  if (sales_obj.hasOwnProperty("exclude_users") && sales_obj.exclude_users.includes(user.id ?? 0)) {
    return params.where
  }

  if (apply_model) {

    if (sales_obj?.user_id && user) {
      if (sales_obj[params.id as keyof typeof sales_obj] && sales_obj.model) {
        // const manager_id = user[sales_org.manager_id]
        let query = new Find(sales_obj.model, 0)
        query.andWhereIsEqualTo(sales_obj.user_id, user.id)
        const final_query: Query = query.compile()

        let response: null | SbxResponse = null;


        if (organizationQueryCache[JSON.stringify(final_query.where)]) {
          response = organizationQueryCache[JSON.stringify(final_query.where)]
        } else {
          response = await findByModel({
            row_model: final_query.row_model,
            where: final_query.where,
            fetch: [],
            page: 1,
            size: 15,
            noGetOrganization: true
          })


          if (response?.success) {
            organizationQueryCache[JSON.stringify(final_query.where)] = response
          }
        }

        if (response && response?.success && response?.items && response?.items.length > 0) {

          const sales_key: string[] = response.items.filter((sales_item: { [key: string]: string }) => sales_item[params.items_id])
            .map((sales_item: { [key: string]: string }) => sales_item[params.items_id])


          if (sales_key.length > 0 && params) {
            const apply_model: ApplyToSalesManager | undefined = (sales_obj.apply_to as Array<SalesManagerConfig>).find(apply => apply.model.split(".")[0] === params.model)
            if (apply_model) {

              if (!params.where) {
                params.where = []
              }

              let field = apply_model[params.id as keyof typeof apply_model]

              if (apply_model.model.split(".").length > 1) {
                // Ej: model -> sbx_crm.company & manager_id -> acc_manager = company.acc_manager
                field = apply_model.model.split(".").slice(1)[0] + "." + field
              }

              params.where.push(
                {
                  "ANDOR": "AND",
                  "GROUP": [
                    {
                      "ANDOR": "AND",
                      "VAL": sales_key,
                      // "FIELD": model + "." + apply_model[params.id as keyof typeof apply_model],
                      "FIELD": field,
                      "OP": "IN"
                    }
                  ]
                }
              )
            }
          }
        }
      }
    }
  }


  return params.where
}

/**
 * It takes a query, and if the query has a `where` clause, it will add the `where` clause to the query
 * @param params - {where: Condition[] | { keys: string[] }, row_model: string }
 */
export const organizationFindQuery = async (params: { where: Condition[] | { keys: string[] }, row_model: string }) => {


  if (params.where?.keys && params.where?.keys.length > 0) {
    let query = new Find(params.row_model, 0)
    query.andWhereIsIn("_KEY", params.where.keys)
    const nQuery: Query = query.compile()
    params.where = nQuery?.where.length > 0 ? nQuery.where : []
  }


  params.where = await getSalesManagerOrganizationQuery({
    where: params.where as Condition[],
    model: params.row_model
  })

  params.where = await getSalesOrgOrganizationQuery({
    where: params.where as Condition[],
    model: params.row_model
  })


  return params.where as Condition[]
}

export const getContentFileName = (file: Content) => file.name.includes("_") ? file.name.split("_")?.at(-1) ?? file.name : file.name

export const labelRange: {[key: number]: string} = {
  0: "Este mes",
  1: "Ultimos 30 dias",
  2: "Este año",
  3: "Hace un año",
  4: "Todo"
}

const truncateRange = {
  THIS_MONTH: 0,
  LAST_30_DAYS: 1,
  THIS_YEAR: 2,
  YEAR_AGO: 3,
  ALL_TIME: 4
}

export const truncateArray = [
  {label: "Este mes", value: truncateRange["THIS_MONTH"]},
  {label: "Ultimos 30 dias", value: truncateRange["LAST_30_DAYS"]},
  {label: "Este año", value: truncateRange["THIS_YEAR"]},
  {label: "Hace un año", value: truncateRange["YEAR_AGO"]},
  {label: "Todo", value: truncateRange["ALL_TIME"]},
]