import {conditions, ConditionType, Container, FieldTypesRule, Operator, RuleCondition, ValuesData} from "./Types";

export function getSize(containers: Container[], field: Container) {
  let size = Math.trunc((12 / containers.length));
  size = (containers.length) > 4 ? 3 : size;
  size = field.type === "condition" ? 2 : size;
  return size;
}

export function existCondition(containers: Container[]) {
  return containers.some(c => c.type === FieldTypesRule.CONDITION);
}

export function existField(containers: Container[]) {
  return containers.some(c => (c.type === FieldTypesRule.TEXT || c.type === FieldTypesRule.NUMBER || c.type === FieldTypesRule.SELECT) && c.isValidator);
}

export function existComparator(containers: Container[]) {
  return containers.some(c => (c.type === FieldTypesRule.TEXT || c.type === FieldTypesRule.NUMBER || c.type === FieldTypesRule.SELECT) && c.isComparator);
}

export function sortContainers(containers: Container[]) {
  
  let newContainers = containers.map(c => {
    let labelSort = "a";
    if (c.type === FieldTypesRule.CONDITION) {
      labelSort = "c";
    }
    
    if (c.isValidator) {
      labelSort = "d"
    }
    
    if (c.isComparator) {
      labelSort = "b";
    }
    
    return {...c, labelSort};
  }).sort((a, b) => a.labelSort.localeCompare(b.labelSort));
  
  return newContainers;
}

export function addRule(rules: RuleCondition[], value: ValuesData) {
  let array = new Array(...rules);
  let rule: any = {};
  Object.keys(value).forEach(key => {
    const val = value[key];
    switch (val.type) {
      case FieldTypesRule.CONDITION:
        rule.condition = val.value.value;
        break;
      default:
        switch (val.label) {
          case "b":
            rule.field = val.type === FieldTypesRule.SELECT ? (val.value.value || "") : val.value;
            rule.label = val.type === FieldTypesRule.SELECT ? (val.value.label || "") : undefined;
            break;
          case "d":
            switch (val.type) {
              case FieldTypesRule.TEXT:
                rule.value = val.value;
                break;
              case FieldTypesRule.SELECT:
                rule.value = val.value.value;
                break;
              case FieldTypesRule.NUMBER:
                rule.value = parseInt(rule.value);
            }
            break;
        }
        break;
    }
  });
  rule.operator = Operator.OR;
  array.push(rule);
  return array;
}


export function generateValues(containers: Container[], values: ValuesData) {
  let valuesT: ValuesData = {};
  sortContainers(containers).forEach((c: Container, index) => {
    const validate = c.value !== values[index]?.value
    const val = validate ? c.value : valuesT[index]?.value;
    switch (c.type) {
      case "text":
      case "number":
        valuesT[index] = {value: val ?? "", type: c.type, label: c.labelSort};
        break;
      case "condition":
      case "select":
        valuesT[index] = {value: val ?? null, type: c.type, label: c.labelSort};
    }
  });
  
  return valuesT;
}

export function onChangeValues(rules: RuleCondition[], index: number, value: RuleCondition) {
  try {
    
    let array = new Array(...rules);
    array[index] = value;
    return array;
  } catch (e) {
    return rules;
  }
}

export function onDeleteRule(rules: RuleCondition[], index: number) {
  let array = new Array(...rules);
  return array.filter((r, i) => i !== index);
}

function validateStringValue(value?: string | number) {
  try {
    switch (typeof value) {
      case "number":
        return `${value}`;
      case "string":
        return `"${value}"`
      case "undefined":
        return "";
      default:
        return `"${value}"`
      
    }
  } catch (e) {
    return "";
  }
}

export function rulesToStringJS(rules: RuleCondition[]) {
  let str: string = "";
  const lastIndex = rules.length - 1;
  rules.forEach((rule, index) => {
    const value = (rule.condition === ConditionType.NO_EXIST || rule.condition === ConditionType.EXIST) ? undefined : rule.value;
    str += `${rule.field} ${rule.condition} ${validateStringValue(value)} ${index !== lastIndex ? `${rule.operator} ` : ""}`;
  });
  return str;
}

export function stringRuleToArray(rule: string, replaceFieldLabel?: { [key: string]: string }) {
  let rules: RuleCondition[] = [];
  
  const getValues = (validator: string) => {
    const condition = conditions.find(c => {
      const result = validator.split(c.value) as string[];
      return result.length === 2 && !result.some(e => e.includes("null"));
    })?.value ?? "" as any;
    
    if (condition) {
      const sp = validator.split(condition);
      const value = (sp.pop()?.replaceAll('"', "") ?? "").split(" ").join("");
      const field = (sp.shift() ?? "").split(" ").join("");
      return {
        value: (condition === ConditionType.NO_EXIST || condition === ConditionType.EXIST) ? undefined : value,
        field,
        condition
      }
    }
    
    return {field: "", value: "", condition: ""}
  }
  
  function setRule(v: string, operator: Operator) {
    const {field, condition, value} = getValues(v)
    let rule: RuleCondition = {
      field,
      value,
      condition,
      operator,
      label: replaceFieldLabel ? replaceFieldLabel[field] : undefined
    }
    rules.push(rule)
  }
  
  rule.split(Operator.OR).forEach(v => {
    if (v.includes(Operator.AND)) {
      v.split(Operator.AND).forEach(v2 => {
        setRule(v2, Operator.AND);
      })
    } else {
      setRule(v, Operator.OR);
    }
  });
  
  return rules;
}
