import {deleteReq, get, post, put} from '../../network';
import {PermissionsData} from '../../types/Permissions';

export const getPermissions = () => {
  return get(`/api/v2/security/permission`);
};

export const savePermission = async (data: PermissionsData | PermissionsData[]) => {
  function save(permission: PermissionsData) {
    return post('/api/v2/security/permission', permission);
  }
  
  if (Array.isArray(data)) {
    let res = [];
    for await (let permission of data) {
      const r = await save(permission);
      res.push(r);
    }
    return {success: !res.some(e => !e.success)};
  } else {
    return save(data);
  }
};

export const deletePermission = async (permissionId: number | number[]) => {
  function deletePerm(id: number) {
    return deleteReq('/api/v2/security/permission/' + id);
  }
  
  if (Array.isArray(permissionId)) {
    let res = [];
    for await (let id of permissionId) {
      const r = await deletePerm(id);
      res.push(r);
    }
    return {success: !res.some(e => !e.success)};
  } else {
    return deletePerm(permissionId);
  }
};

export const getAllUsers = (params?: { ids: string }) => {
  return get('/api/v2/security/auth/users', params);
};
export const createUser = (user: { username: string, password: string, first_name: string, last_name: string, email: string, enabled: boolean, password_expiration_at?: Date }, recover: boolean) => {
  return post('/api/v2/security/auth/user', {user, recover});
};
export const getUserById = (id: number) => {
  return get('/api/v2/security/auth/user/' + id);
  
};
export const removePermissionFromUser = (id: number) => {
  return deleteReq('/api/v2/security/permission/assignment/' + id);
};


export function createAssignment(params: { permissionId?: number, userId: number, groupId?: number, metadata?: string }) {
  return post('/api/v2/security/permission/assignment', {
    user_id: params.userId,
    group_id: params.groupId,
    metadata: params.metadata,
    permission_id: params.permissionId,
  });
}

export function updateAssignment(params: {
  permissionId?: number,
  userId?: number,
  groupId?: number,
  metadata?: string
}) {
  return put('/api/v2/security/permission/assignment', {
    permission_id: params.permissionId,
    user_id: params.userId,
    group_id: params.groupId,
    metadata: params.metadata
  });
}

export function createMembership(groupId: number, userId: number) {
  return post(`/api/v2/security/group/${groupId}/membership`, {user_id: userId});
}

export function removeMembership(groupId: number, userId: number) {
  return deleteReq(`/api/v2/security/group/${groupId}/membership`, {data: {user_id: userId}});
}

export const assignPermissionOrGroupToUser = (params: any) => {
  switch (params.type) {
    case 'user':
      return createAssignment(params);
    case 'group':
      return createMembership(params.groupId, params.userId);
  }
};

export const assignPermissionToGroup = (groupId: number, permissionId: number) => {
  return post('/api/v2/security/permission/assignment', {permission_id: permissionId, group_id: groupId});
};

export const getAllGroups = () => {
  return get('/api/v2/security/group');
};

export const saveGroup = (group: { name: string, label: string }) => {
  return post('/api/v2/security/group', group);
};
export const updateGroup = (group: { id: number, name: string, label: string }) => {
  return put(`/api/v2/security/group/${group.id}`, group);
};
export const getGroupById = (id: number) => {
  return get('/api/v2/security/group/' + id);
};
export const removeGroup = (id: number) => {
  return deleteReq('/api/v2/security/group/' + id);
};
