import {deleteReq, get, post, put} from "../../network";
import {Response} from "../../types/Response";
import {ListProvider} from "../../types/Task";
import {DataProvider, Option} from "../../types/DataProvider";
import {findByModel} from "./SbxService";

export function getAllListProvider() {
  return get("/api/v2/wf/dl");
}

export function addDataProvider(data: DataProvider) {
  return post("/api/v2/wf/dl", data);
}

export function getProviderById(id: number): Promise<Response<ListProvider>> {
  return get("/api/v2/wf/dl/" + id);
}

export function updateProvider(data: DataProvider): Promise<Response<ListProvider>> {
  return put("/api/v2/wf/dl/" + data.id, data);
}

export function deleteProviderById(id: number): Promise<Response<ListProvider>> {
  return deleteReq("/api/v2/wf/dl/" + id);
}

export function deleteOptionFromProviderById(id: number, optionId: number): Promise<Response<ListProvider>> {
  return deleteReq("/api/v2/wf/dl/" + id + "/options/" + optionId);
}

export function getProviderByIdWidthOptions(id: number): Promise<Response<ListProvider>> {
  return get("/api/v2/wf/dl/" + id + "/options");
}

export function addOptionToProvider(option: Option, providerId: number): Promise<Response<ListProvider>> {
  return post("/api/v2/wf/dl/" + providerId + "/options", option);
}

export async function getProviderBySbxQuery(providerId: number) {
  try {
    const res = await getProviderById(providerId);
    if (res.success && res.item?.query) {
      return findByModel(JSON.parse(res.item.query))
    } else throw Error();
  } catch (e) {
    return {success: true, items: []}
  }
}
