const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: process.env.SBX_LOCALE || 'es',
    locales: ['en', 'es', 'nl'],
  },
  react: {
    useSuspense: false,
    wait: true
  },
  localePath: path.resolve('./public/locales'),
};
