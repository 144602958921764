import React from 'react';
import NumberFormat, {NumberFormatValues} from "react-number-format";
import TextProps from "./Models/TextProps";

interface IProps extends TextProps{
  value: string,
  indicative?: string,
  hideIndicative?: boolean,
  onChange?: (value: string) => void
}

const PhoneComponent = (props: IProps) => {
  const {
    id,
    name,
    value,
    required,
    onChange,
    className,
    style,
    invalid,
    placeholder,
    disabled,
    defaultValue,
    indicative,
    hideIndicative
  } = props;

  return <NumberFormat id={id}
                       required={required}
                       name={name}
                       format={`${!hideIndicative ? indicative ? "+"+indicative : "+57" : ""} ### #######`}
                       style={style}
                       defaultValue={defaultValue}
                       disabled={disabled}
                       className={`form-control ${className || ''} ${invalid ? 'invalid' : ''}`}
                       placeholder={placeholder}
                       value={value}
                       allowNegative={false}
                       onValueChange={(values: NumberFormatValues) => onChange ? onChange(values.value) : null}
  />;
};

export default PhoneComponent;
