import React from 'react';
import TextProps from "./Models/TextProps";
import {AnyData} from "../../../types/AnyData";

interface Traditional extends TextProps {
  value: number;
  onChange?: (value: number) => void;
}

interface Ref extends TextProps, AnyData {
  register: any;
}

type IProps = Traditional | Ref;


const NumberComponent = (props: IProps) => {

  if (!(props as any).register) {
    const {
      id,
      name,
      value,
      required,
      onChange,
      className,
      style,
      invalid,
      placeholder,
      disabled,
      defaultValue
    } = props;

    return <input id={id}
                  required={required}
                  disabled={disabled}
                  name={name}
                  value={value}
                  type="number"
                  style={style}
                  defaultValue={defaultValue}
                  className={`form-control ${className || ''} ${invalid ? 'invalid' : ''}`}
                  placeholder={placeholder}
                  onChange={e => onChange ? onChange(e.target.value) : null}/>;

  } else {

    return <input
      {...props}
      type="number"
      defaultValue={props.defaultValue}
      className={`form-control ${props.className || ''} ${props.invalid ? 'invalid' : ''}`}
      {...(props as any).register}
    />
  }
};

export default NumberComponent;
