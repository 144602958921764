import {useCallback, useEffect} from "react";

//Execute the first effect and use a callback
const useAsyncEffect = (func: () => void, deps: any[]) => {

  const callBack = useCallback(func, deps);

  useEffect(() => {
    callBack();
  }, [callBack])

}

export default useAsyncEffect;
