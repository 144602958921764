import {applyMiddleware, compose, createStore,} from "redux";
import createSagaMiddleware from "redux-saga";
import RootReducer from "./Reducers";
import TrackJSLogger from ".//Middleware/TrackJSLogger";
import formsSaga from "./Form/Saga";
import accountSaga from "./Account/Saga";
import authSaga from "./Auth/Saga";
import permissionSaga from "./Permission/Saga";
import dataProviderSaga from "./DataProvider/Saga";
import * as Sentry from "@sentry/react";
import configSagas from "./Config/Saga";


declare var window: any;
const composeEnhancers = typeof window != 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const sagaMiddleware = createSagaMiddleware();


const store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, TrackJSLogger), sentryReduxEnhancer)
);


sagaMiddleware.run(formsSaga);
sagaMiddleware.run(accountSaga);
sagaMiddleware.run(authSaga);
sagaMiddleware.run(dataProviderSaga);
sagaMiddleware.run(permissionSaga);
sagaMiddleware.run(configSagas);

export default store;
