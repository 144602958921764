import React from 'react';
import TextProps from "./Models/TextProps";
import {AnyData} from "../../../types/AnyData";


interface Traditional extends TextProps {
  value: string,
  onChange?: (value: string) => void
}

interface Ref extends TextProps, AnyData {
  register: any;
}

type IProps = Traditional | Ref;

const TimeComponent = (props: IProps) => {

  if (!(props as any).register) {

    const {
      value,
      onChange,
      required,
      readonly,
      disabled,
      defaultValue
    } = props;

    return (
      <input
        disabled={disabled}
        readOnly={readonly}
        required={required}
        value={value}
        defaultValue={defaultValue}
        onChange={e => onChange(e.target.value)}
        type="time"
        className="form-control"/>
    )
  } else {
    return (
      <input
        {...(props as any)}
        disabled={props.disabled}
        type="time"
        className="form-control"
        {...(props as any).register}
      />
    )
  }
};

export default TimeComponent;
