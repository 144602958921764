import {AnyData} from "../../types/AnyData";
import React, {useCallback, useEffect, useState} from "react";
import SmallTextComponent from "../Shared/FieldComponents/SmallTextComponent";
import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import EditorComponent from "../Shared/EditorComponent/EditorComponent";
import {validateQuery} from "../Shared/QueryComponent/QueryComponent";
import {Switch} from "antd";
import CustomTableComponent, {CustomTableColumnType} from "../Shared/CustomTableComponent";
import {capitalize, toNumber} from "../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {InputTags} from "react-bootstrap-tagsinput";
// import {actionsConfig} from '../../store/Config/Slice';
import {actionsModal, ModalTypes} from '../../store/Modal/Slice';
import {PermissionStates} from '../../store/Permission/Types';
// import {FormDataList} from '../Shared/Modal/ConfigModal/AccountConfigModal';
import {useDispatch} from 'react-redux';
import useTranslate from '../../hooks/useTranslate';
import NumberComponent from "../Shared/FieldComponents/NumberComponent";
import TreeViewComponent, {TreeViewDataItem} from "../Shared/TreeViewComponent";
import {Data, recursive, recursiveTree, scrollToView, separator, TypeRule} from "./utils";


const getNewRowFromExistingData = (rows: any[]): AnyData => {
  
  const toObject = (value: AnyData) => {
    const isArray = Array.isArray(value);
    if (isArray) {
      const type = typeof value[0];
      switch (type) {
        case "number":
          return [0];
        case "undefined":
        case "string":
          return [""];
        default:
          return [getNewRowFromExistingData([value[0]])];
      }
    }
    
    return getNewRowFromExistingData([value]);
  }
  return rows.reduce((obj, row) => {
    Object.keys(row).forEach(key => {
      if (obj[key] === undefined) {
        const type = typeof row[key];
        switch (type) {
          case "boolean":
            obj[key] = false;
            break;
          case "number":
            obj[key] = 0;
            break;
          case "object":
            obj[key] = toObject(row[key]);
            break;
          default:
            obj[key] = "";
            break;
        }
      }
    });
    return obj;
  }, {});
}


const DynamicFieldsFromConfig = (props: {
  identifier?: string;
  config: AnyData;
  rules: TypeRule[];
  height?: string;
  onChange: (data: any) => void;
  filter?: string;
}) => {
  
  const [types, setTypes] = useState<Data[]>([]);
  const [tree, setTree] = useState<TreeViewDataItem[]>([]);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string>("");
  const {t} = useTranslate('config');
  
  function onView(item: TreeViewDataItem) {
    scrollToView(item.data.key.split(separator).join(""), 100);
  }
  
  const callback = useCallback((config: AnyData) => {
    const result = recursive(config, [], "", {rules: props.rules});
    const treeData = recursiveTree(config, [], "", {rules: props.rules});
    setTree(treeData);
    setTypes(result);
  }, [])
  
  useEffect(() => {
    callback(props.config);
  }, [callback]);
  
  
  function SplitLabel(props: { label: string }) {
    const id = props.label.split(separator).join("");
    return <Label id={id} className="pt-3">
      <small id={id + "child"} style={{transition: "all .5s"}} className="fw-bolder">
        {props.label.split(separator).map(key => capitalize(key.split("_").join(" "))).join(" - ")}
      </small>
    </Label>
  }
  
  function onChange(value: any, field: string) {
    let newConfig = {...props.config};
    const tt = typeof value;
    
    function looseJsonParse() {
      let nf: string[] | string = field.split(separator);
      nf = nf.map((key, i) => key.includes(".") ? `["${key}"]` : key).join(".").split(".[").join("[");
      return eval(`(function exec() {
        let cc = ${JSON.stringify(newConfig)};
        cc.${nf} = ${tt === "string" ? `"${value}"` : tt === "object" ? JSON.stringify(value) : value};
        return cc;
      })`)();
    }
    
    props.onChange(looseJsonParse())
  }
  
  const upsertItemTable = (value: Array<any>, row: any, index: number, field: string) => {
    
    function update(r: any) {
      onChange(value.map((res, i) => {
        if (index === i) {
          return r;
        } else {
          return res;
        }
      }), field)
    }
    
    function create(r: any) {
      const arr = new Array(...value);
      arr.push(r);
      onChange(arr, field);
    }
    
    dispatch(actionsModal.openModal({
      type: ModalTypes.UPDATE_DYNAMIC_CONFIG,
      title: "Edit ",
      rules: [],
      identifierKey: (props.identifier ?? "") + field,
      data: row ?? getNewRowFromExistingData(value),
      onSuccess: !row ? create : update
    }))
  }
  
  const removeItemTable = (arr: Array<any>, index: number, field: string) => {
    function onConfirm() {
      onChange(arr.filter((_, i) => index !== i), field);
    }
    
    dispatch(actionsModal.openModal({
      closeModal: true,
      type: ModalTypes.CONFIRM,
      onConfirm,
      state: PermissionStates.GROUP_PENDING,
      message: <>¿Are you sure to remove this element?</>,
      title: <><FontAwesomeIcon
        icon={faTrash}/> {' ' + t('common:delete')}</>,
    }));
  };
  
  
  function getValue(field: string) {
    let value = {...props.config};
    try {
      const keys = field.split(separator);
      let value = {...props.config};
      while (keys.length) {
        value = value[keys.shift() ?? ""];
      }
      return value;
    } catch (e) {
      return value;
    }
  }
  
  function getFields() {
    if (props.filter === undefined) {
      return types;
    }
    if (!selected) return [];
    const tt = types.filter(e => selected === e.id);
    if (tt.length) {
      return tt;
    } else {
      
      return types.filter(e => e.id.split(selected).length > 1 &&
        e.id.split("_").length === selected.split("_").length + 1);
      
    }
  }
  
  const rows = getFields();
  return (
    <div style={{height: props.height}}>
      <Row>
        <Col
          className="overflow-auto bg-white shadow-sm rounded"
          style={{height: `calc(${props.height})`}} sm={4} lg={2}>
          <TreeViewComponent
            onSelections={setSelected}
            filter={props.filter ?? ''}
            onItemClick={onView}
            tree={tree}/>
        </Col>
        
        <Col sm={8} lg={10}>
          <Card>
            <CardBody
              style={{scrollBehavior: "smooth", height: `calc(${props.height})`}}
              className="overflow-auto py-5">
              <Row>
                {rows.sort((a, b) => a.type.localeCompare(b.type))
                  .map(({type, field}) => {
                      const value: any = getValue(field);
                      
                      switch (type) {
                        case "string":
                          return (
                            <Col key={field} sm={12}>
                              <FormGroup>
                                <SplitLabel label={field}/>
                                <SmallTextComponent
                                  onChange={e => onChange(e, field)}
                                  value={value ?? ""} id={field}
                                  name={field}/>
                              </FormGroup>
                            </Col>
                          )
                        
                        case "number":
                          return (
                            <Col key={field} sm={12}>
                              <FormGroup>
                                <SplitLabel label={field}/>
                                <NumberComponent
                                  onChange={e => onChange(e, field)}
                                  value={value ?? ""} id={field}
                                  name={field}/>
                              </FormGroup>
                            </Col>
                          )
                        
                        case "string_object":
                          return (
                            <Col key={field} sm={12}>
                              <FormGroup>
                                <SplitLabel label={field}/>
                                <EditorComponent
                                  width="100%"
                                  enableSnippets
                                  value={JSON.stringify(
                                    value,
                                    null,
                                    "\t"
                                  )}
                                  onChange={value => {
                                    validateQuery(value ?? "", (query) => {
                                      onChange(query, field);
                                    })
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          )
                        case "boolean":
                          return (
                            <Col key={field} sm={12}>
                              <FormGroup>
                                <SplitLabel label={field}/><br/>
                                <Switch onChange={e => onChange(e, field)} checked={value}/>
                              </FormGroup>
                            </Col>
                          )
                        case "table":
                          return (
                            <FormGroup key={field}>
                              <SplitLabel label={field}/>
                              <div className="d-flex justify-content-end">
                                <Button size="sm" color="primary"
                                        onClick={() => upsertItemTable(value, null, 0, field)}>
                                  <FontAwesomeIcon icon={faPlusCircle}/> {t("common:new")}
                                </Button>
                              </div>
                              <CustomTableComponent
                                data={value}
                                actions={[
                                  {
                                    label: <FontAwesomeIcon icon={faEdit}/>,
                                    type: "primary",
                                    onAction: (e, i) => upsertItemTable(value, e, i as number, field)
                                  },
                                  {
                                    label: <FontAwesomeIcon icon={faTrash}/>,
                                    type: "danger",
                                    onAction: (e, i) => removeItemTable(value, i as number, field)
                                  }
                                ]}
                                columns={Object.keys(value ? value[0] : {})
                                  .map(key => {
                                    let _type: CustomTableColumnType = "String";
                                    
                                    switch (typeof value[0][key]) {
                                      case "boolean":
                                        _type = "Boolean"
                                        break;
                                      case "object":
                                        _type = "Json";
                                        break;
                                    }
                                    return {
                                      type: _type,
                                      header: capitalize(key),
                                      name: key
                                    }
                                  })}
                              />
                            </FormGroup>
                          )
                        
                        case "list_string":
                          return (
                            <Col key={field} sm={12}>
                              <SplitLabel label={field}/>
                              <InputTags onTags={e => onChange(e.values, field)} values={value}/>
                            </Col>
                          )
                        
                        case "list_number":
                          return (
                            <Col key={field} sm={12}>
                              <SplitLabel label={field}/>
                              <InputTags onTags={e => onChange(e.values.map(toNumber), field)} values={value}/>
                            </Col>
                          )
                        default:
                          return null;
                      }
                    }
                  )}
                
                {!rows.length && (
                  <div className="justify-content-center align-items-center d-flex">
                    <h5 className="text-secondary">{t("common:no_results")}</h5>
                  </div>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default DynamicFieldsFromConfig;
