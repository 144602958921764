import React, {useState} from "react";
import AutoSuggestAccounts from "./AutoSuggestAccounts";

const SearchBox = () => {

  const [show, setShow] = useState(false)

  return (
    <div className={"search-wrapper " + (show ? "active" : "")}>
      <div className="input-holder">
        <AutoSuggestAccounts/>
        <button onClick={() => setShow(true)} className="search-icon">
          <span/>
        </button>
      </div>
      <button onClick={() => setShow(false)} className="btn-close"/>
    </div>
  )
}

export default SearchBox;
