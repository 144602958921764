import React, {FormEvent, useEffect, useState} from 'react';
import ModalComponent from './ModalComponent/ModalComponent';
import {actionsModal, ModalTypes} from '../../../store/Modal/Slice';
import {useDispatch, useSelector} from 'react-redux';
import {FormGroup, Label} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {RootState} from '../../../store/Reducers';
import {PermissionStates} from '../../../store/Permission/Types';
import useIgnoreFirstEffect from '../../../hooks/useIgnoreFirstEffect';
import {actionsPermissions} from '../../../store/Permission/Slice';
import useTranslate from '../../../hooks/useTranslate';
import {getGroupById} from '../../../services/backend/PermissionsService';


interface IProps {
  open: boolean;
  data: IPropsGroupModal
}


export interface IPropsGroupModal {
  type: ModalTypes.CREATE_GROUP|ModalTypes.UPDATE_GROUP;
  id?:number;
}

const GroupModal = (props: IProps) => {
  const {open ,data} = props;
  const dispatch = useDispatch();
  const {state} = useSelector((state: RootState) => state.PermissionReducer);
  const [{name, label}, setState] = useState({name: "", label: ""});
  const {t} = useTranslate("permissions-manager");

  function onChange({target}: any) {
    setState(e => ({...e, [target.name]: target.value}));
  }

  function toggle(id?:number) {
    if(id){
      dispatch(actionsModal.closeModal({type: ModalTypes.UPDATE_GROUP}));
    }else {
      dispatch(actionsModal.closeModal({type: ModalTypes.CREATE_GROUP}));
    }

  }
  const loadingGroup = (id:number) => {
    getGroupById(id).then((res) => {
      if (res.success) {
        setState({name: res.item.name,label: res.item.label});
      }
    })

  }
  useEffect(()=>{
    if (data.id){
      loadingGroup(data.id)
    }

  },[data.id])
  useIgnoreFirstEffect(() => {
    if (state === PermissionStates.GROUP_RESOLVED) {
      toggle(data.id);
    }
  }, [state])

  function onSubmit(e: FormEvent<HTMLFormElement>,id?:number) {
    e.preventDefault();
    if (id){
      dispatch(actionsPermissions.updateGroup({id, name, label}));
    }else{
      dispatch(actionsPermissions.saveGroup({name, label}));
    }

  }


  return (
    <ModalComponent
      isLoading={state === PermissionStates.GROUP_PENDING}
      title={<><FontAwesomeIcon icon={faPlusCircle}/>{" " + t("modal-create-title")}</>}
      isOpen={open}
      form={"new_group_form"}
      type="submit"
      toggle={()=>toggle(data.id)}>
      <form onSubmit={(e)=>onSubmit(e,data.id)} id="new_group_form">
        <FormGroup>
          <Label>
            {t("common:name")}
          </Label>
          <input required
                 type="text"
                 placeholder="Ej: admin"
                 className="form-control"
                 name="name"
                 value={name}
                 onChange={onChange}/>
        </FormGroup>
        <FormGroup>
          <Label>
            {t("common:label")}
          </Label>
          <input type="text"
                 required
                 placeholder="Ej: Admin"
                 className="form-control"
                 name="label"
                 value={label}
                 onChange={onChange}/>
        </FormGroup>
      </form>
    </ModalComponent>
  )
}

export default GroupModal;
