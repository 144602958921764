import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Table,
  UncontrolledButtonDropdown
} from 'reactstrap';
import React, {CSSProperties, useEffect, useState} from 'react';
import SpinnerComponent from './SpinnerComponent';
import {Pagination, Switch} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisV, faFileExport, faSortDown, faSortUp} from '@fortawesome/free-solid-svg-icons';
import Permission from '../AuthorityPermission/Permission';
import {Permissions} from '../../types/Permissions';
import {
  capitalize,
  checkValidColor,
  convertDateToYYYYMM,
  convertDateToYYYYMMDD,
  convertDateToYYYYMMDDHHmmaa,
  convertDateToYYYYMMMDD,
  convertNumberDateToDate,
  convertTableRowsToCSVString,
  DEFAULT_SIZE,
  downloadTextToFile,
  filterData,
  getCompoundName,
  getContentFileName,
  getObjValueInDeep,
  IsJsonString,
  uuidV4
} from '../../utils';
import {SbxModelField} from '../../types/Sbx';
import PopoverComponent from './PopoverComponent';
import useTranslate from '../../hooks/useTranslate';
// @ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store/Reducers';
import {actionHistory} from '../../store/SearchReducer';
import {ListProviderOption} from '../../types/Task';
import DropdownMenuHeader from './DropdownComponent/DropdownMenuHeader';
import {TypeFIle} from '../../types/User';
import {ButtonType} from '../LayoutComponenents/types/ButtonType';
import {FormatRules} from '../../types/Field';
import EditorComponent from "./EditorComponent/EditorComponent";
import {Content} from "../../types/Folder/Content";
import {downloadFileService, getFile} from "../../services/UtilsService";
import {FileTypeIcon} from "./ContentEngine";
import useIgnoreFirstEffect from "../../hooks/useIgnoreFirstEffect";

export type CustomTableColumnType =
  'String'
  | 'Date'
  | 'Formatter'
  | SbxModelField
  | 'Reference'
  | 'Fixed'
  | 'Icon'
  | 'Json'
  | 'NumberDate'
  | 'Boolean'
  | 'List_provider'
  | 'Year_month'
  | 'Custom'
  | 'DateTime'
  | 'ReactNode'
  | 'Color'
  | "Array"
  | 'ArrayObject'
  | 'Document'

export interface Column extends FormatRules {
  name: string,
  header: string;
  parent?: string;
  reference?: string;
  className?: string;
  style?: CSSProperties;
  action?: React.ReactNode;
  isSort?: boolean
  isTotalColumn?: boolean;
  headerClassName?: string
  customShowColumn?: (value: any) => void;
  type?: CustomTableColumnType
  value?: string;

}

export interface Action {
  label?: React.ReactNode;
  disabled?: boolean;
  title?: string;
  onAction?: (row: any, index?: number) => void;
  permission?: Permissions | Permissions[];
  visible?: boolean;
  visibleRow?: (row: any) => boolean;
  custom?: boolean;
  customComponent?: (row: any) => JSX.Element | JSX.Element[];
  type?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | string;
}

interface IProps {
  id?: string
  columns: Column[];
  data: any[];
  actions?: Action[];
  loading?: boolean;
  pagination?: boolean;
  showSizeChanger?: boolean
  currentPage?: number
  rowAction?: (row: any) => void;
  tableShading?: boolean;
  onChangePage?: (page: number, size: number) => void;
  totalData?: number
  actionsType?: 'list' | 'button',
  getColumns?: (columns: Column[]) => void;
  columnsSetting?: boolean;
  onShowSizeChange?: (page: number, size: number) => void;
  filter?: boolean;
  useLocalPage?: boolean;
  exportButtons?: { type: TypeFIle, color: ButtonType }[]
  actionsColumnLabel?: string
  actionsColumnClass?: string
  removeEmptyColumns?: boolean
  sortableTable?: boolean;
  shadow?: "none" | "lg" | "sm";
  localFilter?: boolean;
}

export const CustomTableComponent = (
  {
    columns: columnsData,
    data,
    filter: search = true,
    actions,
    loading,
    pagination = true,
    tableShading,
    rowAction,
    onChangePage,
    totalData,
    showSizeChanger,
    actionsType,
    id,
    getColumns,
    columnsSetting,
    onShowSizeChange,
    useLocalPage = false,
    exportButtons = [],
    actionsColumnLabel = '',
    actionsColumnClass,
    removeEmptyColumns,
    sortableTable,
    currentPage,
    shadow,
    localFilter
  }: IProps) => {

  const {page, text} = useSelector((state: RootState) => state.SearchReducer);
  const [newRows, setNewRows] = useState<Array<any>>([]);
  const [excludeColumns, setExcludeColumns] = useState<string[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [filterLocal, setFilterLocal] = useState('');
  const [filter, setFilter] = useState('');
  const [selectAll, setSelection] = useState(true);
  const {t} = useTranslate('common');
  const [size, setSize] = useState(DEFAULT_SIZE);
  const dispatch = useDispatch();
  const [localPage, setLocalPage] = useState(1);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (search) {
      setNewRows(filterData(data, text));
    } else {
      setNewRows(data);
    }
  }, [data, text]);

  useEffect(() => {
    if (localFilter) {
      if (filterLocal.trim()) {
        setNewRows(filterData(data, filterLocal));
      } else {
        setNewRows(data);
      }
    }
  }, [data, filterLocal]);

  React.useEffect(() => {
    if (useLocalPage && !onChangePage) {
      setLocalPage(1);
    }
  }, [data]);

  React.useEffect(() => {
    if (currentPage && currentPage > 0 && useLocalPage && onChangePage) {
      setLocalPage(currentPage)
    }
  }, [currentPage]);

  React.useEffect(() => {
    if (!useLocalPage) {
      setLocalPage(page);
    }
  }, [page]);

  useIgnoreFirstEffect(() => {
    if (onChangePage) {
      onChangePage(localPage, size);
    }
  }, [localPage])
// useEffect(() => {
  //   if (onChangePage) {
  //     onChangePage(localPage, size);
  //   }
  // }, [localPage]);
  
  React.useEffect(() => {
    setRows((pagination && !totalData ? (size > 0 ? newRows.slice((localPage - 1) * size, (localPage - 1) * size + size) : newRows) : newRows));
  }, [pagination, totalData, localPage, size, newRows]);


  const getValue = (value: string | number | boolean | ListProviderOption | { [key: string]: any } | Content, header: Column) => {

    if (header.type) {
      const cases: { [column: string]: () => any } = {
        [SbxModelField.DATE]: () => value ? convertDateToYYYYMMMDD(value as string) : "",
        Date: () => value ? convertDateToYYYYMMMDD(value as string) : "",
        DateTime: () => convertDateToYYYYMMDDHHmmaa(new Date(value as string)),
        NumberDate: () => convertDateToYYYYMMDD(convertNumberDateToDate(value as number)),
        Reference: () => typeof value === 'string' ? value : getObjValueInDeep(value as { [key: string]: any }, header.value as string),
        Formatter: () => {
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });

          return formatter.format(value as number);
        },
        Formatter0: () => {
          const formatter = new Intl.NumberFormat('es-ES', {
            // style: 'currency',
            // currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });

          return "$"+formatter.format(value as number);
        },
        ArrayObject: () => <PopoverComponent
          id="style_rules_definition_id"
          label={<FontAwesomeIcon icon={faEdit}/>}
          trigger="click" title=""
          placement="bottom">
          <EditorComponent
            readOnly
            value={typeof value !== 'string' ? JSON.stringify(value, null, "\t") : value}
            theme="dark_mitsuketa_tribute"
            height="250px"
          />
        </PopoverComponent>,
        Json: () => <PopoverComponent
          id="style_rules_definition_id"
          label={<FontAwesomeIcon icon={faEdit}/>}
          trigger="click" title=""
          placement="bottom">
          <EditorComponent
            readOnly
            value={typeof value !== 'string' ? JSON.stringify(value, null, "\t") : value}
            theme="dark_mitsuketa_tribute"
            height="250px"
          />
        </PopoverComponent>,
        [SbxModelField.BOOLEAN]: () => Boolean(value) ? t('yes') : 'No',
        Boolean: () => Boolean(value) ? t('yes') : 'No',
        Fixed: () => typeof value === 'number' ? value.toFixed(2) : value,
        "List_provider": () => {
          if ((value as ListProviderOption)?.label) {
            return (value as ListProviderOption).label;
          } else if (header.format_rules && header.format_rules.columns_labels?.length > 0) {
            return getCompoundName({columns: header.format_rules.columns_labels, item: value});
          } else {
            if (typeof value === 'string') {
              return value;
            }
          }

          return '';
        },
        "Year_month": () => convertDateToYYYYMM(convertNumberDateToDate((value + '01'))),
        Custom: () => header.customShowColumn ? header.customShowColumn(value) : '',
        Color: () => <div className="rounded-circle " style={{
          backgroundColor: checkValidColor(value as string) ? value as string : 'white',
          height: '15px',
          width: '15px'
        }}>
        </div>,
        Document: () => {
          if (!value) {
            return ""
          }

          async function onConfirmDownload(fileData: Content) {

            // async function onDownload() {
            const res: any = await getFile(fileData.key);
            if (res.success) {
              await downloadFileService(res.url, res.name);
              // dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}));
            }
            // }

            // dispatch(actionsModal.openModal({
            //   type: ModalTypes.CONFIRM,
            //   onConfirm: onDownload,
            //   title: <><FontAwesomeIcon icon={faFileDownload}/>{" " + "Download file"} </>,
            //   message: <>{t("custom-message:warn-download")} {getContentFileName(fileData)}?</>
            // }))
          }

          if (IsJsonString(value as string) && Array.isArray(JSON.parse(value as string))) {
            const documents: Content[] = JSON.parse(value as string)


            return <div className="d-flex flex-column gap-2"> {documents.map(document => (
              <span key={document.key} onClick={() => onConfirmDownload(document)} className="underline pointer">
                <FileTypeIcon name={document.name}/> {getContentFileName(document)}
              </span>
            ))}
            </div>
          }

          return ""
        }
      }

      return cases[header.type] ? cases[header.type]() : (Array.isArray(value) ? (value as string[]).join(", ") : value) as string;
    }

    return (Array.isArray(value) ? (value as string[]).join(", ") : value) as string;
  };


  //
  // const getValue = (value: string | number | boolean | ListProviderOption | { [key: string]: any }, header: Column) => {
  //   switch (header.type) {
  //     case SbxModelField.DATE:
  //       return value ? convertDateToYYYYMMMDD(value as string) : "";
  //     case 'Date':
  //       return value ? convertDateToYYYYMMMDD(value as string) : "";
  //     case 'DateTime':
  //       return convertDateToYYYYMMDDHHmmaa(new Date(value as string));
  //     case 'NumberDate':
  //       return convertDateToYYYYMMDD(convertNumberDateToDate(value as number));
  //     case 'Reference':
  //       if (typeof value === 'string') {
  //         return value;
  //       } else {
  //         return getObjValueInDeep(value as { [key: string]: any }, header.value as string);
  //       }
  //     case 'Formatter':
  //       const formatter = new Intl.NumberFormat('en-US', {
  //         style: 'currency',
  //         currency: 'USD',
  //
  //         // These options are needed to round to whole numbers if that's what you want.
  //         //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //         //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  //       });
  //       return formatter.format(value as number);
  //     case 'Json':
  //     case 'ArrayObject':
  //       return <PopoverComponent
  //         id="style_rules_definition_id"
  //         label={<FontAwesomeIcon icon={faEdit}/>}
  //         trigger="click" title=""
  //         placement="bottom">
  //         <EditorComponent
  //           readOnly
  //           value={typeof value !== 'string' ? JSON.stringify(value, null, "\t") : value}
  //           theme="dark_mitsuketa_tribute"
  //           height="250px"
  //         />
  //       </PopoverComponent>;
  //     case SbxModelField.BOOLEAN:
  //     case 'Boolean':
  //       return (
  //         Boolean(value) ? t('yes') : 'No'
  //       );
  //     case 'Fixed':
  //       return typeof value === 'number' ? value.toFixed(2) : value;
  //     case 'List_provider':
  //       if ((value as ListProviderOption)?.label) {
  //         return (value as ListProviderOption).label;
  //       } else if (header.format_rules && header.format_rules.columns_labels?.length > 0) {
  //         return getCompoundName({columns: header.format_rules.columns_labels, item: value});
  //       } else {
  //         if (typeof value === 'string') {
  //           return value;
  //         }
  //       }
  //
  //       return '';
  //     case 'Year_month':
  //       return convertDateToYYYYMM(convertNumberDateToDate((value + '01')));
  //     case 'Custom':
  //       return header.customShowColumn ? header.customShowColumn(value) : '';
  //     case 'Color':
  //
  //       return <div className="rounded-circle " style={{
  //         backgroundColor: checkValidColor(value as string) ? value as string : 'white',
  //         height: '15px',
  //         width: '15px'
  //       }}>
  //
  //       </div>
  //     case 'Document':
  //       if (!value){
  //         return ""
  //       }
  //
  //       // if (IsJsonString(value as string) && Array.isArray(JSON.parse(value as string))){
  //       //   const documentKeys: string[] = JSON.parse(value as string)
  //       //   const docItems = getFileData("").then(res => res)
  //       //   console.log(docItems)
  //       //
  //       // }
  //
  //       return ""
  //     default:
  //       return Array.isArray(value) ? value.join(", ") : value;
  //
  //
  //   }
  // };

  const getRowValue = (row: any, header: Column) => {
    if (header.value && row[header.name] && row[header.name].hasOwnProperty(header.value)) {
      return getValue(row[header.name][header.value], header);
    } else if (row[header.name] && (!header.value || header.type === 'Reference')) {
      return getValue(row[header.name], header);
    } else if (typeof row[header.name] === 'number' && row[header.name] === 0) {
      return getValue(row[header.name], header);
    }
    return getValue(row[header.name], header);
  };

  useEffect(() => {
    if (getColumns) {
      getColumns(columns);
    }
  }, [columns]);

  React.useEffect(() => {
    setColumns(columnsData.filter(c => !excludeColumns.some(cx => cx === c.name)).map(column => ({
      ...column,
      isSort: false
    })));
  }, [excludeColumns, columnsData]);

  const actionsMobileMenu = (row: any, rowIndex: number) => {
    return <UncontrolledButtonDropdown className="mini-menu custom">
      <DropdownToggle
        className="border-0">
        <FontAwesomeIcon icon={faEllipsisV}/>
      </DropdownToggle>
      <DropdownMenu className="position-fixed">
        <DropdownMenuHeader title={'Acciones'}/>
        {actions?.filter(({visible = true}) => visible)
          .map((act, index) => !act.custom && (act.permission ?
              <Permission key={index} permission={act.permission}>
                {getActionButton(row, act, index, rowIndex, true)}
              </Permission> : (
                getActionButton(row, act, index, rowIndex, true)
              )
          ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>;
  };

  const getActionButton = (row: any, action: Action, index: number, rowIndex: number, isMobile = false) => {

    if (action.visibleRow && !action.visibleRow(row)) {
      return <div/>;
    }

    if (isMobile) {
      return <DropdownItem
        disabled={action.disabled}
        key={index}
        title={action.title}
        className={'pl-4 pr-2 ' + `text-${action.type}`}
        onClick={() => {
          action.onAction && action.onAction(row, rowIndex);
        }}>
        {action.label}
      </DropdownItem>;
    }


    return action?.custom && action.customComponent ? action.customComponent(row) :
      <Button
        disabled={action.disabled}
        size="sm"
        title={action.title}
        key={index}
        className="mx-1"
        onClick={() => action.onAction && action.onAction(row, rowIndex)}
        color={action.type}>{action.label}
      </Button>;
  };

  const renderActionsMenu = (row: any, rowIndex: number) => {
    switch (actionsType) {
      case 'list':
        return actionsMobileMenu(row, rowIndex);
      default:
        return actions?.filter(({visible = true}) => visible)
          .map((action, index) => action.permission ? (
            <Permission
              key={index}
              permission={action.permission}>
              {getActionButton(row, action, index, rowIndex)}
            </Permission>
          ) : (
            getActionButton(row, action, index, rowIndex)
          ));

    }
  };

  function alterExclude(columnName: string) {
    const exist = excludeColumns.some(cx => cx === columnName);
    const array = new Array(...excludeColumns);
    if (exist) {
      setExcludeColumns(array.filter(c => c !== columnName));
    } else {
      array.push(columnName);
      setExcludeColumns(array);
    }
  }

  const filterColumns = (f: string) => {
    return columnsData?.filter(c => c.header.toLowerCase().indexOf(f.toLowerCase()) !== -1) ?? [];
  };

  useEffect(() => {
    if (selectAll) {
      setExcludeColumns([]);
    } else {
      setExcludeColumns(columns.map(c => c.name));
    }
  }, [selectAll]);

  const total_items = totalData || newRows.length;

  const getColumnTotal = (column: Column) => {
    if (column.isTotalColumn) {
      let total = data.reduce((total, row) => {

        if (row[column.name]) {
          total += row[column.name];
        }

        return total;
      }, 0);

      if (typeof total === 'number') {
        total = parseFloat(total.toFixed(2));
      }


      return getValue(total, column);
    }

    return null;
  };

  const isTableTotal = () => {
    return columns.some(column => column.isTotalColumn);
  };

  function downloadDataToFile(type: TypeFIle) {
    const text = convertTableRowsToCSVString(columns, rows);
    downloadTextToFile(text, type, convertDateToYYYYMMDD(new Date()));
  }

  React.useEffect(() => {
    if (removeEmptyColumns && rows.length > 0) {
      // console.log(columns.filter(column => rows.some(row => getRowValue(row, column))));
      setColumns(prevColumns => prevColumns.filter(column => rows.some(row => (getRowValue(row, column) || getRowValue(row, column) === 0))));
    }
  }, [removeEmptyColumns, rows]);


  const sortColumn = (column: Column) => {
    setNewRows(currentRows => [...currentRows].sort((a, b) => {
      if (typeof getRowValue(a, column) === 'string') {
        if (column.isSort) {
          return getRowValue(b, column).localeCompare(getRowValue(a, column));
        } else {
          return getRowValue(a, column).localeCompare(getRowValue(b, column));
        }
      } else {
        if (column.isSort) {
          return getRowValue(b, column) - getRowValue(a, column);
        } else {
          return getRowValue(a, column) - getRowValue(b, column);
        }
      }
    }));

    setColumns(currentColumns => {
      return currentColumns.map(nColumn => {
        if (nColumn.name === column.name) {
          nColumn.isSort = !nColumn.isSort;
        }
        return nColumn;
      });
    });
  };

  return (
    <div className={"mb-3 " + (shadow ? ` shadow-${shadow} ` : " card main-card")}>
      <div className={!shadow ? "card-body" : ""}>
        {loading ? (
          <div className="d-flex justify-content-center">
            <SpinnerComponent/>
          </div>) : (
          <>
            {exportButtons.map((button, index) => (
              <div className="d-flex justify-content-end mb-1" key={'export_button_' + index}>
                <Button
                  className="me-1"
                  onClick={() => downloadDataToFile(button.type)}
                  color={button.color}
                  size="sm">
                  <FontAwesomeIcon icon={faFileExport} className="me-1"/>
                  {`${t('common:export')} ${button.type}`}
                </Button>
              </div>
            ))}
            {columnsSetting && (
              <div>
                <div className="d-flex align-items-center justify-content-end">
                  <span className="mb-2">{t('columns')}:</span> {(
                  <PopoverComponent
                    zIndex={3000}
                    label={<FontAwesomeIcon className="text-gray" icon={faEllipsisV}/>}
                    trigger="click"
                    title="Columns"
                    placement="left"
                    id={'pop-columns' + id}>
                    <div
                      style={{
                        maxHeight: '80vh',
                        overflowY: 'auto'
                      }}
                    >
                      <FormGroup>
                        <label>
                          {!selectAll ? t('select_all') : t('deselect_all')}
                          <Switch
                            className="ms-1"
                            defaultChecked={selectAll}
                            onChange={e => setSelection(e)}
                          />
                        </label>
                      </FormGroup>
                      <FormGroup>
                        <Input placeholder={t('search')} value={filter} onChange={e => setFilter(e.target.value)}/>
                      </FormGroup>
                      {filterColumns(filter).map((c, index) => {
                        const checked = !excludeColumns?.some(cx => cx === c.name);
                        return (
                          <div key={c.name + index}>
                            <label className="m-0">
                              <input onChange={() => alterExclude(c.name)} type="checkbox"
                                     checked={checked}/> {' ' + capitalize(c.header).replaceAll("-", " ").replaceAll("_", " ")}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </PopoverComponent>
                )}
                </div>
              </div>
            )}

            {localFilter && <div className="form-search">
                <input className="form-control"
                       placeholder={t("common:search").concat("...")}
                       type="search"
                       onChange={e => setFilterLocal(e.target.value)}
                       value={filterLocal}/>
            </div>}
            <Table className="table-hover table-striped d-lg-table d-none" responsive
                   id={id ?? ''}>
              <thead>
              <tr>
                {isTableTotal() &&
                    <th style={{borderBottom: '1px solid #eeeeee'}} className="fw-bold"/>
                }
                {columns.map((column, index) => (
                  <th style={{borderBottom: '1px solid #eeeeee', minWidth: "100px"}}
                      className={column.headerClassName ?? ''}
                      key={`column_${column.isSort ? "sorteable_" : ""}${index}`}>
                    {column.header} {sortableTable &&
                      <FontAwesomeIcon className="pointer" onClick={() => sortColumn(column)}
                                       icon={column.isSort ? faSortUp : faSortDown}/>}
                    {column.action && column.action}
                  </th>
                ))}
                {actions && (
                  <th className={actionsColumnClass ?? ''} style={{borderBottom: '1px solid #eeeeee'}}>
                    {actionsColumnLabel ?? ''}
                  </th>
                )}
              </tr>
              </thead>
              <tbody>

              {rows.map((row, index) => (
                <tr key={`row_${row.id ?? uuidV4()}_${index}`}
                    className={`${tableShading ? 'pointer shading py-5' : 'py-5'}`}>
                  {isTableTotal() && <td/>}
                  {columns.map((column, index) => (
                    <td key={index} onClick={() => rowAction ? rowAction(row) : undefined}
                        className={column.className ?? ''} style={column.style ?? {}}>
                      {getRowValue(row, column)}
                    </td>
                  ))}
                  {actions && (
                    <td className="d-table-cell ">
                      <div className={actionsColumnClass ?? 'd-flex justify-content-end'}>
                        {renderActionsMenu(row, index)}
                      </div>
                    </td>
                  )}

                </tr>
              ))}

              {isTableTotal() && rows.length > 0 &&
                  <>
                      <tr>
                          <td className="fw-bold ">TOTAL</td>
                        {columns.map((column, index) => (
                          <td key={index} className={`fw-bold  ${column.headerClassName ?? 'text-start'} `}
                              style={column.style ?? {}}>
                            {getColumnTotal(column)}
                          </td>
                        ))}
                      </tr>
                  </>
              }
              </tbody>
            </Table>

            <div className="d-lg-none d-block custom px-3">
              {rows.map((row, index) => {
                return <Card key={index} className="my-2">
                  <CardBody className="d-flex align-items-center">
                    <div className="w-100" onClick={() => rowAction ? rowAction(row) : undefined}>
                      {columns.map((column, index) => (
                        <span key={index}>
                        {getRowValue(row, column) &&
                            <div className="m-0">
                                <b>{column.header}:</b> {getRowValue(row, column)}
                            </div>
                        }
                      </span>
                      ))}
                    </div>
                    {actions && (
                      actionsMobileMenu(row, index)
                    )}
                  </CardBody>
                </Card>
              })}

            </div>
          </>
        )}
        {!total_items && !loading && (
          <div className="text-center py-5">
            {t('empty_table')}
          </div>)}
        {(!!total_items && pagination) && <div className="d-flex justify-content-between  align-items-center px-2">
            <div>
                <small
                    className="text-gray">{(size * localPage) > total_items ? total_items : (size * localPage)} of {total_items}</small>
            </div>
            <Pagination disabled={loading}
                        current={localPage}
                        onShowSizeChange={(p, s) => {
                          onShowSizeChange && onShowSizeChange(p, s);
                          setSize(s);
                        }}
                        pageSizeOptions={[DEFAULT_SIZE.toString(), '30', '60', '100', '500', '1000']}
                        showSizeChanger={total_items > DEFAULT_SIZE && showSizeChanger}
                        onChange={page => useLocalPage ? setLocalPage(page) : dispatch(actionHistory.changePage(page))}
                        pageSize={size}
                        total={total_items}/>
        </div>}
      </div>
    </div>
  );
};

export default CustomTableComponent;
