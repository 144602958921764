import React from 'react';
import ModalComponent from './ModalComponent/ModalComponent';
import {actionsModal, ModalTypes} from '../../../store/Modal/Slice';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {RootState} from '../../../store/Reducers';
import {PermissionStates} from '../../../store/Permission/Types';
import useTranslate from '../../../hooks/useTranslate';
import {Response} from '../../../types/Response';
import ChangePassword from "../../ProposalProfile/ChangePassword";


interface IProps {
  open: boolean;
  data: IPropsChangePasswordModal
}


export interface IPropsChangePasswordModal {
  type: ModalTypes.CHANGE_PASSWORD;
  id?: number;
  email?: string;
  onFinish?: (res: Response, obj?: { [key: string]: any }) => void;
}

const ChangePasswordModal = (props: IProps) => {
  const {open, data} = props;
  const dispatch = useDispatch();
  const {state} = useSelector((state: RootState) => state.PermissionReducer);
  const {t} = useTranslate("permissions-manager");
  
  
  function toggle() {
    dispatch(actionsModal.closeModal({type: ModalTypes.CHANGE_PASSWORD}));
  }
  
  return (
    <ModalComponent
      isLoading={state === PermissionStates.GROUP_PENDING}
      title={<><FontAwesomeIcon icon={faPlusCircle}/>{" " + t("update-password")}</>}
      isOpen={open}
      form={"change_password"}
      type="submit"
      size={'lg'}
      toggle={() => toggle()}>
      <div className="pb-3">
        <ChangePassword modal proposal email={data.email}/>
      </div>
    </ModalComponent>
  )
}

export default ChangePasswordModal;
