import {ProviderType} from "./ProviderType";

export class DataProvider {
  id!: number;
  query: any;
  value_field!: string;
  label_field!: string;
  parent_field: null | any;
  name!: string;
  options!: any[];
  domain_id!: number;
  default_query!: string;
  provider_type!: ProviderType;
}

export interface Option {
  value: string;
  label: string;
}

export interface OptionData extends Option {
  id: number;
  name: string;
  sort_index: number
}
