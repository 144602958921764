import {useSelector} from "react-redux";
import {ModalTypes} from "../../../store/Modal/Slice";
import React, {useEffect, useState} from "react";
import ConfirmationModal from "./ConfirmationModal";
import AddOrEditPermissionModal from "./AddOrEditPermissionModal";
import GroupModal from "./GroupModal";
import CreateDataProviderModal from "./CreateDataProviderModal";
import UpsertFormModal from "./FormModal/UpsertFormModal";
import RuleCreatorModal from "./RuleCreatorModal";
import TableEditModal from './TableEditModal/TableEditModal';
import UpdateModelModal from "./UpdateModelModal";
import UpsertTaskModal from "./UpsertTaskModal";
import UpsertSequenceModal from "./UpsertSequenceModal";
import UpsertEventModal from "./UpsertEventModal";
import UpsertGatewayModal from "./UpsertGatewayModal";
import FormPreviewModal from "./FormPreviewModal";
import GroupAddUserModal from './GroupAddUserModal';
import DynamicModal from './ConfigModal/DynamicModal';
import UserModal from './UserModal';
import ChangePasswordModal from './ChangePasswordModal';
import DynamicComponentModal from "./DynamicComponentModal";
import {AnyData} from "../../../types/AnyData";

const modals: { type: ModalTypes, component: React.ReactNode }[] = [
  {type: ModalTypes.CONFIRM, component: ConfirmationModal},
  {type: ModalTypes.UPSERT_FORM, component: UpsertFormModal},
  {type: ModalTypes.CREATE_DATA_PROVIDER, component: CreateDataProviderModal},
  {type: ModalTypes.UPSERT_PERMISSION, component: AddOrEditPermissionModal},
  {type: ModalTypes.CREATE_GROUP, component: GroupModal},
  {type: ModalTypes.CREATE_USER, component: UserModal},
  {type: ModalTypes.CHANGE_PASSWORD, component: ChangePasswordModal},
  {type: ModalTypes.UPDATE_GROUP, component: GroupModal},
  {type: ModalTypes.UPDATE_GROUP_USER, component: GroupAddUserModal},
  {type: ModalTypes.RULE_CREATOR, component: RuleCreatorModal},
  {type: ModalTypes.TABLE_EDIT_MODAL, component: TableEditModal},
  {type: ModalTypes.SBX_UPDATE_MODEL, component: UpdateModelModal},
  {type: ModalTypes.UPSERT_TASK_MODAL, component: UpsertTaskModal},
  {type: ModalTypes.UPSERT_SEQUENCE_MODAL, component: UpsertSequenceModal},
  {type: ModalTypes.UPSERT_EVENT_MODAL, component: UpsertEventModal},
  {type: ModalTypes.FORM_PREVIEW_MODAL, component: FormPreviewModal},
  {type: ModalTypes.UPSERT_GATEWAY_MODAL, component: UpsertGatewayModal},
  {type: ModalTypes.UPSERT_GATEWAY_MODAL, component: UpsertGatewayModal},
  {type: ModalTypes.UPDATE_DYNAMIC_CONFIG, component: DynamicModal},
  {type: ModalTypes.DYNAMIC_COMPONENT_MODAL, component: DynamicComponentModal}
];

const ModalWrapper = () => {
  const state = useSelector((state: any) => state.ModalReducer);
  const [recursiveModal, setRecursiveModal] = useState<AnyData>({});
  
  useEffect(() => {
    modals.forEach(modal => {
      const currentState = state[modal.type];
      if (currentState?.data && currentState.data.identifierKey) {
        setRecursiveModal(r => ({
          ...r,
          [currentState.data.identifierKey]: {
            type: modal.type,
            data: currentState,
            component: modal.component,
            open: Boolean(currentState.open)
          }
        }))
      }
    })
  }, [state]);
  
  return (
    <>
      {
        modals.reduce((modalsReduce: any[], modal: any, i) => {
          const currentState = state[modal.type];
          if (currentState && currentState.open && !currentState.data.identifierKey) {
            const Modal: any = modal.component;
            modalsReduce.push(<Modal key={i} {...currentState} />);
          }
          return modalsReduce;
        }, [])
          .concat(Object.keys(recursiveModal).reduce((modalsReduce: any[], key: string, i) => {
            const modal = recursiveModal[key] as { type: ModalTypes, open: boolean, data: AnyData, component: any };
            if (modal.open) {
              const Modal: any = modal.component;
              modalsReduce.push(<Modal key={i + key} {...modal.data} />)
            }
            return modalsReduce;
          }, []))}
    </>
  )
}

export default ModalWrapper;
