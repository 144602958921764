import React from "react";
import {IAceEditorProps} from "react-ace";

const Editor = (props: IAceEditorProps) => {
  if (typeof window !== "undefined") {
    const Ace = require("react-ace").default;
    require("brace/mode/javascript");
    require("brace/theme/github");

    return <Ace {...props} />;
  }

  return null;
};
const EditorComponent = (props: IAceEditorProps) => {

  return (
    <Editor
      {...props}
      // annotations={[{ row: 0, column: 2, type: "error", text: "Some error." }]}
      mode={"javascript"}
      theme={"github"}
      // height={"100%"}
      fontSize={13}
      setOptions={{
        tabSize: 2,
      }}
    />
  );
};

export default EditorComponent;
