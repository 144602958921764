import {useSelector} from "react-redux";
import {Permissions} from "../../types/Permissions";
import {authReducer} from "../../store/Selectors";

interface IProps {
  children: JSX.Element | JSX.Element[];
  userId?: number;
  permission: Permissions | Permissions[];
  denied?: JSX.Element | JSX.Element[]
}

const Permission = (props: IProps) => {
  const {user: {permissionsMapper, id, isAdmin}} = useSelector(authReducer);
  const {permission, children, userId = id} = props;
  const accessTo = (userId === id || isAdmin) && (Array.isArray(permission) ? permission.some(p => permissionsMapper[p]) : permissionsMapper[permission]);
  return <>{accessTo ? children : null}</>;
}

export default Permission;
