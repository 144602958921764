import React from "react";
import useRequired from "../../../hooks/useRequired";
import TextProps from "./Models/TextProps";

interface IProps extends TextProps {
  value: string;
  onChange?: (value: string) => void
}

const SmallTextComponent = (props: IProps) => {

  const {
    id,
    name,
    value,
    invalid,
    required,
    readonly,
    disabled,
    style,
    placeholder,
    className,
    onChange
  } = props;
  const error = useRequired(value, Boolean(required));

  return <input id={id}
                name={name}
                value={value}
                required={required}
                readOnly={readonly}
                disabled={disabled}
                style={style}
                type="text"
                placeholder={placeholder}
                className={`form-control ${className || ''} ${invalid || error ? 'invalid' : ''}`}
                onChange={e => onChange ? onChange(e.target.value) : null}/>;
};

export default SmallTextComponent;
