import React, {useReducer, useState} from 'react';
import {getSbxModelFields} from '../../../services/backend/SbxService';
import {Model, SbxModelField, SbxResponse} from '../../../types/Sbx';
import {Contact, SbxCrmDataColumn} from '../../../types/User';
import {
  convertDateToDDMMMYYYY,
  getAllDataByProvider,
  getColumnValueFromRules,
  getCompoundName,
  getObjValueInDeep,
  getUnderscoreLabel,
  toast,
  uuidV4
} from '../../../utils';
import IField from '../../../types/FormBuilder/IField';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Task} from '../../../types/Task';
import {useDispatch} from 'react-redux';
import {actionsModal, ModalTypes} from '../../../store/Modal/Slice';
import {State} from '../../../types/State';
import {FieldType, SubType} from '../../../types/FieldType';
import {ColumnsLabels} from '../../../types/Field';
import {UseFormGetValues} from 'react-hook-form';
import CustomTableComponent, {Action, CustomTableColumnType} from '../../Shared/CustomTableComponent';

type Props = {
  field: IField;
  task?: Task;
  formState: {
    [key: string]: any
  }
  getValue?: UseFormGetValues<any>
  defaultValue: () => any;
  dispatchTaskForm: ({name, value, type}: { name: string, value: any, type?: string }) => void;
}

export type TableFormColumn = {
  name: string,
  column?: string;
  label: string,
  parent?: string,
  hide?: boolean;
  sub_columns?: ColumnsLabels[] | null,
  read_only?: boolean;
  type?: 'list_provider' | 'constant' | "time",
  sub_type?: SbxModelField | string | SubType;
  constant?: string
  value?: string[]
  sort_index?: number;
  column_reference?: SbxCrmDataColumn
  calculated?: boolean; // Check if a field is calculated
  default?: string | number; // Could be ${now} ${currentUser}, calculate field like "${income_y1/kilos_y1}"
  format_rules?: { columns_labels: ColumnsLabels[], dependencies: string[], default_value?: string, default_value_conditions?: string[], condition_order_by?: string[] }
  list_provider?: string;
  group?: string;
  provider_type?: 'DATABASE'
  render_type?: 'radio_button' | 'field'
  extra_values?: string[] // An use case is when exist and calculate input and others inputs required the same value but the inputs are read only or hide. So set main input and set the extra inputs at the same time
  value_type?: "number"
  reference?: string;
  filter?: {
    default: '{first_value}',
    key_column_filter: string,
    main_column_filter: string,
  }
  table_column_type?: 'SEARCHEABLE'
  placeholder?: string
  table_type?: CustomTableColumnType
  search_by?: string[] // Ej. find by model search by ["code", "name"]
  skip?: boolean // No save a column in database
  visible_when?: string // Ej. visible_when="${is_admin} === 'true'"
  required?: boolean
}

enum Types {
  SET_STATE = 'SET_STATE',
  SET_MULTI_STATE = 'SET_MULTI_STATE'
}

export interface TableFormState {
  tableHeaders: TableFormColumn[];
  data: any[];
  filterProperties: string[];
  model: (Model | TableFormColumn)[];
  row_model: string;
  totalItems: number;
  max_row?: number;
  no_allow_new_rows_on_edit?: boolean;
  isNewTable: boolean;
  isLoading: State;
}

const initialState: TableFormState = {
  filterProperties: [],
  model: [],
  data: [],
  tableHeaders: [],
  totalItems: 0,
  row_model: '',
  isNewTable: false,
  isLoading: State.IDLE
};

function reducer(state: TableFormState, {
  type,
  payload
}: { type: Types, payload: { name: string, value: any } | { name: string, value: any }[] }) {
  switch (type) {
    case Types.SET_STATE:
      return {
        ...state,
        [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
      };
    case Types.SET_MULTI_STATE:
      (payload as { name: keyof TableFormState, value: any }[]).forEach(data => {
        state = {...state, [data.name]: data.value};
      });
      return {...state};
    default:
      throw new Error();
  }
}

const cacheProvider: { [key: string]: any } = {};

export const TableTaskComponent = ({field, dispatchTaskForm, defaultValue, formState, task, getValue}: Props) => {

  const [localState, localDispatch] = useReducer(reducer, initialState);
  const [taskProcessData, setTaskProcessData] = useState<{ [key: string]: string }>({});
  const dispatch = useDispatch();
  const dispatchForm = ({name, value}: { name: keyof TableFormState, value: any }) => {
    localDispatch({type: Types.SET_STATE, payload: {name, value}});
  };

  const dispatchMultiForm = (forms: { name: keyof TableFormState, value: any }[]) => {
    localDispatch({type: Types.SET_MULTI_STATE, payload: forms});
  };

  React.useEffect(() => {
    if (!!task?.process_data && Object.keys(task.process_data).length > 0) {
      const obj: { [key: string]: string } = {};
      Object.keys(task.process_data).forEach(key => {
        if ( task.process_data[key]?.value){
          obj[key] = task.process_data[key].value;
        }
      });

      setTaskProcessData(obj);
    }
  }, [task]);


  React.useEffect(() => {

    const getProviderData = async (provider_id: string) => {
      dispatchForm({name: 'isLoading', value: State.PENDING});
      const response = await getAllDataByProvider({provider_id, formState: {...formState, ...taskProcessData}, getFormValue: getValue});
      if (response && response.success) {
        if (response.items && response.items?.length > 0) {
          cacheProvider[provider_id] = response.items;
        }
        dispatchForm({name: 'isLoading', value: State.RESOLVED});
      } else {
        toast({message: 'Ocurrió un error', type: 'error'});
        dispatchForm({name: 'isLoading', value: State.REJECTED});
        return;
      }
    };


    if (field.format_rules_definition?.columns_labels) {
      for (const column of field.format_rules_definition?.columns_labels) {
        if (column.type === 'list_provider') {

          // getAllDataByProvider((column as TableFormColumn).list_provider as string);
          getProviderData((column as TableFormColumn).list_provider as string);

        }
      }
    }

  }, [field, formState, taskProcessData]);

  const getValueFromField = ({data, property}: { data: any, property?: string }) => {

    const type = localState?.model.find((model) => model.name === property)?.type;

    const header = localState?.tableHeaders?.find(header => header.name === property);
    if (!type) {
      return null;
    }

    switch (type) {
      case SbxModelField.REFERENCE: {
        if (header && header.sub_columns) {
          const column = header.sub_columns[0];
          return data[column.name] ?? '';
        } else {
          return null;
        }
      }
      case 'list_provider':
        if (header?.list_provider && cacheProvider[header.list_provider] && header?.format_rules?.columns_labels) {
          const column = getColumnValueFromRules({columns: header.format_rules.columns_labels, isProvider: true});
          const item = cacheProvider[header.list_provider].find((providerItem: any) => getObjValueInDeep(providerItem, column) === data);
          if (item) {
            return getCompoundName({columns: header.format_rules.columns_labels, item});
          }
        }else{
          if (data){
            return data
          }
        }

        if (header?.render_type === 'field') {
          return data;
        }

        return '';
      case SbxModelField.DATE:
        return convertDateToDDMMMYYYY(new Date(data)) ?? '';
      case SbxModelField.BOOLEAN:
        return data ? 'Si' : 'No';
      default:
        return data;
    }
  };

  const getFields = async ({page = 1, size = 10}) => {
    let headers: ColumnsLabels[] = [];
    if (field.list_provider) {
      const response: SbxResponse<Contact> = await getSbxModelFields({
        provider: {...field.list_provider, page, size}
      });

      if (response?.success && response.model) {
        headers = field?.format_rules_definition?.columns_labels ?? response.model.map(header => ({
          name: header.name,
          label: getUnderscoreLabel(header.name)
        }));

        dispatchMultiForm(
          [
            {name: 'model', value: response.model},
            {name: 'row_model', value: field.list_provider.name},
            {name: 'data', value: response.results},
            {name: 'totalItems', value: response.row_count}
          ]
        );
      }
    } else {

      headers = field?.format_rules_definition?.columns_labels;

      dispatchMultiForm(
        [
          {
            name: 'model',
            value: headers?.map((header) => ({...header, type: header.type ?? SbxModelField.STRING})) ?? []
          },
          {name: 'tableHeaders', value: headers},
          {name: 'isNewTable', value: true},
        ]
      );
    }




    if (field.field_type === FieldType.TABLE && field.format_rules_definition) {
      dispatchMultiForm(
        [
          {name: 'no_allow_new_rows_on_edit', value: field.format_rules_definition.no_allow_new_rows_on_edit},
          {name: 'max_row', value: field.format_rules_definition.max_row},
        ]
      );
    }

    if (headers?.length > 0){
      headers = headers.filter(header => header.label);

      dispatchMultiForm(
        [
          {name: 'tableHeaders', value: headers},
          {name: 'filterProperties', value: headers?.map((header: ColumnsLabels) => header.name) ?? []},
        ]
      );
    }


  };

  React.useEffect(() => {
    getFields({});
    return () => {

    };
  }, []);

  const paginationChange = (page: number) => {
    getFields({page});
  };

  React.useEffect(() => {
    if (defaultValue() && JSON.parse(defaultValue()).length > 0) {
      const arrItems = JSON.parse(defaultValue());
      dispatchForm({name: 'data', value: arrItems.map((item: any) => ({...item, id: uuidV4()}))});
    }
  }, []);

  const setTableItems = (items: any[]) => {
    dispatchForm({
      name: 'data',
      value: items
    });

    dispatchTaskForm({
      name: field.name, value: items
    });
  };

  const showAddButton = () => {

    const isAvailableLength = !!localState.max_row ? localState.data.length < localState.max_row : true;

    return localState.isNewTable && !field.read_only && isAvailableLength;
  };

  // React.useEffect(() => {
  //
  //   const tableData = localState.data.map(item => {
  //     let obj: any = {...item};
  //
  //     localState.tableHeaders.forEach(header => {
  //       obj[header.name] = getValueFromField({data: item[header.name], property: header.name});
  //
  //     });
  //     console.log("item", item);
  //     console.log("obj", obj);
  //     // obj = {...obj, ...item}
  //     // console.log("new obj", obj);
  //
  //     return obj;
  //
  //   });
  //
  //   if (tableData.length > 0) {
  //     dispatchForm({name: 'tableData', value: tableData});
  //   }
  //
  // }, [localState.data]);

  const actions: Action[] = [
    {
      label: <span><FontAwesomeIcon className="me-1" icon={faEdit}/>Editar</span>,
      type: 'primary',
      visible: (!field.read_only || localState.no_allow_new_rows_on_edit),
      onAction: row => {
        dispatch(actionsModal.openModal({
          configName: 'data',
          type: ModalTypes.TABLE_EDIT_MODAL,
          model: localState?.model,
          tableItems: localState.data,
          setTableItems,
          toggleHelper: () => {
            getFields({});
          },
          formState: {...formState, ...taskProcessData},
          getFormValue: getValue,
          item: row,
          headers: localState.tableHeaders,
          row_model: localState.row_model
        }));
      }
    },
    {
      label: <span><FontAwesomeIcon className="me-1" icon={faTrash}/>Eliminar</span>,
      type: 'danger',
      visibleRow: row => !!(localState.data || row.id),
      onAction: row => {
        setTableItems(localState.data.filter(item => item.id !== row.id))
        // dispatchForm({
        //   name: 'data',
        //   value: localState.data.filter(item => item.id !== row.id)
        // });
      }
    }
  ];


  return (
    <div className="d-flex flex-column grid-full-column">
      <label className="form-control-label" htmlFor={field.name + '_' + field.id}>
        {field.label}
      </label>

      <CustomTableComponent actions={actions}
                            pagination={field.list_provider ? field.list_provider && localState.data.length > 0 : false}
                            showSizeChanger={false} onChangePage={page => paginationChange(page)}
                            columns={localState.tableHeaders?.filter(header => !header.hide).map(header => ({
                              header: header.label,
                              name: header.name,
                              type: header.table_type ?? 'Custom',
                              customShowColumn: (value) => getValueFromField({data: value, property: header.name})
                            })) ?? []}
                            data={localState.data ?? []} loading={localState.isLoading === State.PENDING}/>

      {showAddButton() && !localState.no_allow_new_rows_on_edit &&
        <div className="my-2 text-right">
          <Button size="sm" onClick={() => {
            dispatch(actionsModal.openModal({
              configName: 'data',
              type: ModalTypes.TABLE_EDIT_MODAL,
              row_model: localState.row_model,
              tableItems: localState.data,
              setTableItems,
              getFormValue: getValue,
              toggleHelper: () => {
                getFields({});
              },
              formState: {...formState, ...taskProcessData},
              headers: localState.tableHeaders,
              model: localState.model
            }));
          }} color={'success'}>
            <FontAwesomeIcon icon={faPlus}/>
          </Button>
        </div>
      }


    </div>
  );
};
