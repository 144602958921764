import {get, post} from "../../network";
import {Response} from "../../types/Response";
import {ConfigData} from "../../types/ConfigData";

export function loadConfigService(): Promise<Response<ConfigData>> {
  return get(`/api/domain/v1/app/config`).then(res => {
    res.item = (res?.items ?? [])[0];
    if (res.item) {
      res.item = {config: JSON.parse(res.item.config), app: res.item.id}
    }
    return res;
  }).catch(() => ({success: false}));
}

export function saveConfigService(config: ConfigData): Promise<Response> {
  return post(`/api/domain/v1/app/config`, config).catch(() => ({success: false}));
}
