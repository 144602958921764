import es from "../locales/es";
import en from "../locales/en";

const {default: dd} = require("next/router");
const nextLocales = require("../../next-language.config");

export const languageData = () => ({
  es,
  en,
  nl: en
})

export const t = (field = "", module = "", language) => {
  const locale = (dd.router ? dd.router.locale : nextLocales.i18n.defaultLocale);
  try {
    if (locale) {
      if (field.includes(":")) {
        const modules = field.split(":");
        const f = modules.pop();
        const m = modules.shift();
        return language[locale][m][f] || field;
      }
      const val = language[locale][module][field];
      if (val) {
        return val
      }
      throw Error;
    } else {
      throw Error;
    }
  } catch (e) {
    try {
      return (field ?? "").replaceAll("_", " ").replaceAll("-", " ");
    }catch (e) {
      return ""
    }

  }
}