import {Field} from "../../../../types/Field";
import {FieldType, SubType} from "../../../../types/FieldType";
import {plainToClass} from "class-transformer";
import {uuidV4} from "../../../../utils";


export const fields: Field[] = [
  plainToClass(Field, {
    "label": "Name",
    "name": "name",
    "required": false,
    "single_value": true,
    "default_value": `label ${uuidV4().slice(0, 5)}`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
  }),
  plainToClass(Field, {
    "label": "Label",
    "name": `label`,
    "required": false,
    "single_value": true,
    "default_value": `label ${uuidV4().slice(0, 5)}`,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.SMALL_TEXT,
  }),
  plainToClass(Field, {
    "label": "From",
    "name": "from_item_id",
    placeholder: "Select...",
    "required": true,
    "single_value": true,
    "default_value": null,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "To",
    "name": "to_item_id",
    "required": true,
    "single_value": true,
    "default_value": null,
    "size": 1,
    "read_only": true,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "Action type",
    "name": "action_type",
    "required": true,
    "single_value": true,
    "default_value": null,
    placeholder: "Select...",
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.SELECT,
  }),
  plainToClass(Field, {
    "label": "Inline",
    "name": "inline",
    "required": false,
    "single_value": true,
    "default_value": false,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.TOGGLE,
  }),
  plainToClass(Field, {
    "label": "Skip required fields",
    "name": "skip_required_fields",
    "required": false,
    "single_value": true,
    "default_value": false,
    "size": 1,
    "read_only": false,
    "max_length": 128,
    "field_type": FieldType.OPTIONS,
    "sub_type": SubType.TOGGLE,
  }),
  plainToClass(Field, {
    "label": "Rule",
    "name": "rule",
    "required": false,
    "single_value": true,
    "default_value": ``,
    "size": 1,
    "disabled": false,
    "max_length": 128,
    "field_type": FieldType.LARGE_TEXT,
  })
]
