import React, {CSSProperties} from "react";

const hidden: CSSProperties = {
  opacity: 0,
  position: "absolute",
  top: "45%",
  left: 0,
  zIndex: -1
}

interface Props {
  children: React.ReactChild,
  value?: string | number | null,
  required?: boolean,
  disabled?: boolean
}

const ValidateFieldRequired = ({value, children, required, disabled}: Props) => {
  return (
    <div style={{position: "relative"}}>
      {children}
      <input value={value || ""}
             disabled={disabled}
             onChange={() => null}
             required={required}
             type="text"
             style={hidden}/>
    </div>
  )
}

export default ValidateFieldRequired;
